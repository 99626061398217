export class FullUsageReport {
  username!: string;
  startDate!: string;
  endDate!: string;
  duration!: string;
  className!: string;
  schoolName!: string;

  private convertMinToHours(min: number): string {
    var minutes = min % 60;
    var hours = (min - minutes) / 60;
    return hours + ':' + minutes;
  }
  constructor(data: any) {
    this.username = data.username || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.duration = this.convertMinToHours(data.duration) || '';
    this.className = data?.className || data?.collectionName || '';
    this.schoolName = data.schoolName || '';
  }
}
