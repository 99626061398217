import { Injectable } from '@angular/core';
import _ from 'underscore';
import { QueryRef } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractServerSideService {

    isLoadAllData!: boolean;
    elements!: Array<any>;
    pageTokenSearch!: string;
    pageTokenAll!: string;
    identity!: string;
    noDataText!: string;

    abstract getQuery(variables: object): QueryRef<any>;
    abstract unsuscribeQuery(): void;
}
