import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';

@Injectable({ providedIn: 'root' })
export class AuthCookieService {
  constructor() {}

  getAuth(keyName: string): string {
    return Cookie.get(keyName);
  }

  setAuth(keyName: string, value: string): void {
    Cookie.set(keyName, value, 3600000, '/', '', true);
  }

  deleteAuth(keyName: string): void {
    Cookie.delete(keyName);
  }
}
