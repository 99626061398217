// 'https://k12-api-service-test-3hzy2j3q2a-uc.a.run.app',
// 'https://k12-api-service-3hzy2j3q2a-uc.a.run.app'

export const environment = {
  imagotype:false,
  production: false,
  authWithPopup: false,
  apiURL: 'https://labs-api.dev.labs.itopia.app',
  userPortalURL: 'https://user-portal.dev.labs.itopia.app',
  e2eTestToken: `eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJjcmVhdGVkX2F0IjoiMjAyMS0wOS0yN1QxMzozNzowMC4yNzZaIiwiZXhwIjoxNjY0Mjg1ODIwLCJlbWFpbCI6ImUyZUBpdG9waWEuY29tIn0.Blv2Sf3s58XjZBFWqUvlgw5k-KAyGzdZOSLV-kenCyc`,
  bucket: '',
  tenantId: '550f3bf9-4bd6-4f37-8ec0-e565039f2790',
  defaultQuota: '16',
  firebaseConfig: {"apiKey":"AIzaSyA_IH-qfDJkjsbD3P2cboP3JwISAe6N1ng","appId":"1:579538783315:web:2613dbb3263437a8fecaa6","authDomain":"labs-auth.dev.labs.itopia.app","databaseURL":"https://labs-dev-infra-x7-default-rtdb.firebaseio.com","measurementId":"","messagingSenderId":"579538783315","projectId":"labs-dev-infra-x7","storageBucket":"labs-dev-infra-x7.appspot.com"},
  intercom: {
    appId:'aza77dj3'
    //appId: 'ycqzyevm',
  },
  zendeskKey: '',
  appCueKey: '211958',
  labsAppId: 'a701be8b-aca6-4bfb-894f-8a6e1701412a',
  classlinkClientId: 'c16638647530295fc0e96b3e3c8747bfb5a98dbc5398',
  classlinkAuthUrl: 'https://launchpad.classlink.com/oauth2/v2/auth',
  cleverAuthUrl: 'https://clever.com/oauth/authorize',
  adminPortalURL: "https://portal.dev.labs.itopia.app",
  redirectUri: 'https://portal.dev.labs.itopia.app',
  exludeUsersTestConDefault: [
    'adelgado@itopia.com',
    'afernandez@itopia.com',
    'egonzalez@itopia.com',
    'rdiaz@itopia.com',
    'ycompanioni@itopia.us',
    'mrosales@itopia.com'
  ],
  downloadCertificate:
    'https://storage.googleapis.com/labs-sso-certificate-dev/idp-public-crt/ssl.crt',
  appName: 'CloudApps Classroom',
  educatorDistrictId:"HfCLOeAtUvEf7AeCTEu4",
  sinceDateNewLayout : '2023-04-16T12:00:00Z',
  canvas: {
    canvasDocUrl:'https://community.canvaslms.com/t5/Admin-Guide/How-do-I-configure-an-LTI-key-for-an-account/ta-p/140',
    canvasAppUrl: 'https://community.canvaslms.com/t5/Admin-Guide/How-do-I-configure-an-external-app-for-an-account-using-a-client/ta-p/202'
  },
  cleverClientId: '2183d121433f1fd80932',
};
