import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import _ from 'underscore';
import { AppCueUserData } from '../interfaces/appCueUser';
declare var Appcues: any;
declare global {
  interface Window { Appcues: any; }
}
declare global {
  interface Window { AppcuesSettings: any; }
}

@Injectable({
  providedIn: 'root'
})
export class AppcuesService {
  script!: HTMLScriptElement | undefined;
  isLoad!: boolean; 
    
  constructor() {}

  loadScript(): void {    
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = 'window.AppcuesSettings = { enableURLDetection: true };';
    document.head.appendChild(script);

    const appCueKey = environment.appCueKey;
    this.script = document.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src = `//fast.appcues.com/${appCueKey}.js`;
    document.head.appendChild(this.script);
    this.isLoad = false;
    this.script.onload = () => {
      if (window.Appcues) {
        console.log('Appcues script loaded successfully');
        this.isLoad = true;
      } else {
        console.log('Failed to load Appcues script');
      }
    };
  }

  identifyUser(userId: string, userProperties: AppCueUserData): void {
    Appcues.identify(userId, userProperties);
  }

  trackEvent(eventName: string, eventProperties: any): void {
    Appcues.track(eventName, eventProperties);
  }
}