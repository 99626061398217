<div class="container-card">
    <div class="card-steps">
        <mat-card class="m-h-c">
            <div class="p-relative" fxLayout="column">
                <div fxLayout="row" class="m-t-img" fxLayoutAlign="center center"> <img class="img-card" src="{{resources[0]}}"/></div>
                <div fxLayout="row" class="m-t-10" fxLayoutAlign="center center">
                    <h2>{{ appName }}</h2>
                </div> 
                <div fxLayout="row" class="m-t-10" fxLayoutAlign="center center">
                    <h2 class="m-b-5">Your subscription is getting ready</h2>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="f-16">Please wait, in a few minutes everything will be ready...</span>
                </div>     
                 <div fxLayout="row" class="m-t-30" fxLayoutAlign="center center" *ngIf="loading" > 
                    <mat-spinner [mode]="'indeterminate'" [diameter]="35"></mat-spinner>
                </div>
            </div>
        </mat-card>
    </div>  
</div>  
