import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalConfig } from '../../models/modal-config';


@Injectable({
  providedIn: 'root'
})
export class ModalService extends MatDialog {

  dialogRef!: MatDialogRef<any,any>;

  public open(content: any, options?: ModalConfig<any> | undefined): MatDialogRef<any,any> {
    try {
      const defaultOptions: ModalConfig<any> = {
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        autoFocus: false
      };
      options = { ...defaultOptions, ...options };
      this.dialogRef = super.open(content, options);
      if(!options?.disableKeyClose){
          // close only key escape
          this.dialogRef.keydownEvents().subscribe(($event: KeyboardEvent)=> {
            if($event?.code === 'Escape') {
              sessionStorage.removeItem('adminConsent');
              this.dialogRef.close(); }
          });
      }
      return this.dialogRef;
     } catch (error: any) {
        return error;
     }
  }
}
