import { Roles } from "../enums";

// Action Format =>  action + suject + resource

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SECURITY_ACTIONS =
{
    /********************** DISTRICT LEVEL *******************************/
    // school
    'add-school-district': [Roles.Owner, Roles.Editor],
    'delete-school-district': [Roles.Owner, Roles.Editor],
    'setallocation-school-district': [Roles.Owner, Roles.Editor],
    'edit-domains-district': [Roles.Owner, Roles.Editor],

    // computer credits district
    'details-credits-district': [Roles.Owner, Roles.Editor, Roles.Viewer],
    'export-billing-district': [Roles.Owner, Roles.Editor, Roles.Viewer],
    'export-license-district': [Roles.Owner, Roles.Editor, Roles.Viewer],


    // admin
    'add-admin-district': [Roles.Owner, Roles.Editor],
    'delete-admin-district': [Roles.Owner, Roles.Editor],

    // integration
    'edit-integration-district': [Roles.Owner, Roles.Editor],

     // image license
    'add-image-license': [Roles.Owner, Roles.Editor],
    'delete-image-license': [Roles.Owner, Roles.Editor],
    'license-password': [Roles.Owner, Roles.Editor],
    'send-email-user-district': [Roles.Owner, Roles.Editor],

    /*********************** SCHOOL LEVEL ***********************************/
    // computer credits district
    'details-credits-school': [Roles.Owner, Roles.Editor, Roles.Viewer],
    'edit-domains-school': [Roles.Owner, Roles.Editor],

    // admin
    'add-admin-school': [Roles.Owner, Roles.Editor],
    'delete-admin-school': [Roles.Owner, Roles.Editor],

    // class
    'add-class-school': [Roles.Owner, Roles.Editor],
    'delete-class-school': [Roles.Owner, Roles.Editor],
    'show-class-school': ['*'],
    'show-stast-class': ['*'],

    /*************************** CLASS LEVEL***********************************************/
    // quotas
    'edit-quotas-class': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // session
    'endsession-class-school': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // indentity sync
    'sync-all-class': [Roles.Owner, Roles.Editor, Roles.Teacher],
    'edit-sync-class': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // applications
    'edit-app-class': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // scheduler
    'add-schedule-class': [Roles.Owner, Roles.Editor, Roles.Teacher],
    'delete-schedule-class': [Roles.Owner, Roles.Editor, Roles.Teacher],
    'start-sessions-class': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // students
    'add-student-class': [Roles.Owner, Roles.Editor, Roles.Teacher],
    'delete-student-class': [Roles.Owner, Roles.Editor, Roles.Teacher],

    //connect session
    'connect-student-session': [Roles.Owner, Roles.Editor, Roles.Teacher],

    // instructors
    'add-instructor-class': [Roles.Owner, Roles.Editor, Roles.Teacher],
    'delete-instructor-class': [Roles.Owner, Roles.Editor, Roles.Teacher],


    //image licenses
    'manage-image-license-class':  [Roles.Owner, Roles.Editor, Roles.Teacher],

    'drag-and-drop':[Roles.Owner],

    //integration edit classes
    'classes-int-edit-class':  [Roles.Owner, Roles.Editor, Roles.Teacher],
    /*******************************************************************/
    // All uses
    'refresh': ['*'],
    'usage-report': ['*']

} as any;
