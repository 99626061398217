import { Injectable } from '@angular/core';
import { ClassLinkInfo } from 'src/app/shared/dto/classlink/classlinkInfo';
import { AuthCookieService } from 'src/app/shared/services/cookies.service';
import { environment } from 'src/environments/environment';
import _ from 'underscore'
import { CleverInfo } from '../../dto/clever/cleverInfo';


@Injectable({
  providedIn: 'root'
})
export class CleverIntegrationAuthService {

 cleverInfo: CleverInfo | undefined;
 loading!: boolean;
 tkAuth!: string;

  constructor(private authCookieService: AuthCookieService) {
      this.cleverInfo = undefined;
  }

  getCleverLinkInfo(code: string, redirectUri: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers();
      this.tkAuth = this.authCookieService.getAuth("token");
      if(this.tkAuth){
        myHeaders.append("Authorization", `Bearer ${this.tkAuth }`);
      }
      const requestOptions = {
        headers: myHeaders,
        method: 'GET', // or 'PUT'
      };
      fetch(environment.apiURL+`/auth/clever/integration?code=${code}&redirectUri=${redirectUri}`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then((config) => {
          if(config){
            this.cleverInfo = JSON.parse(atob(config));
            sessionStorage.setItem('cleverInfo', JSON.stringify(this.cleverInfo) )
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch((error) => {
          console.log("Failed to fetch data from myInfo");
          resolve(false);
      });
    });
  }

  cleverRedirect(): boolean {
    let cleverLinkConfigure = false;
    if(this.cleverInfo) {
      cleverLinkConfigure = this.cleverInfo?.cleverConsent === true;
    }
    return cleverLinkConfigure;
  }

}
