import { Injectable } from '@angular/core';
import _ from 'underscore';

declare var zE: any;

@Injectable({
  providedIn: 'root'
})
export class IntercomChatService {
    private intercomAppId = 'APP_ID'; // Cambia esto por tu APP_ID
    private scriptLoaded = false;
    
  constructor() {}

  loadIntercom(appId: string) {

    if (this.scriptLoaded) {

      // Si el script ya fue cargado, solo actualizamos los settings
      this.initializeIntercom();
      return;
    }
    this.intercomAppId = appId;
    const script = document.createElement('script');
    script.src = `https://widget.intercom.io/widget/${this.intercomAppId}`;
    script.async = true;
    script.onload = () => {
      this.scriptLoaded = true;
      this.initializeIntercom();
    };

    document.head.appendChild(script);
  }

  private initializeIntercom() {
    const w = window as any;
    w.intercomSettings = {
      app_id: this.intercomAppId,
      vertical_padding: 40
    };

    if (typeof w.Intercom === 'function') {
      w.Intercom('reattach_activator');
      w.Intercom('update', w.intercomSettings);
    } else {
      w.Intercom = function () {
        w.Intercom.q.push(arguments);
      };
      w.Intercom.q = [];
    }
  }

  setData(data: any) {
    const w = window as any;
    
    if (data == null) {
      // Si data es null, apagamos y reiniciamos Intercom
      this.shutdownAndBootIntercom();
    } else {
      // Si hay data, actualizamos los ajustes de Intercom
      const updateObject = Object.assign(
        {},
        data.name && { name: data.name },
        data.email && { email: data.email },
        data.id && { user_id: data.id },
        data.hmac && { user_hash: data.hmac },
        data.company && { company: { id: `labs-district-${data.company.id}`, name: data.company.name } },
        data.layoutVersion && { layoutVersion: data.layoutVersion, viewV2Counter: data.viewV2Counter }
      );
      this.updateIntercomSettings(updateObject);
    }
  }

  shutdownIntercom() {
    const w = window as any;
    if (w.Intercom) {
      w.Intercom('shutdown');
    }
  }

  updateIntercomSettings(settings: any) {
    const w = window as any;
    if (w.Intercom) {
      w.Intercom('update', settings);
    }
  }

  shutdownAndBootIntercom() {
    const w = window as any;
    this.shutdownIntercom();
    w.Intercom('boot', { app_id: this.intercomAppId });
  }
 
}

 

