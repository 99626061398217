import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import { FirebaseProvider } from '../enums/firebaseProvider';
import { ClassLinkIntegrationAuthService } from './integrations/classlink-integration-auth.service';
import { CleverIntegrationAuthService } from './integrations/clever-integration-auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private classLinkIntService:  ClassLinkIntegrationAuthService,
    private cleverIntService: CleverIntegrationAuthService
  ){ }
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
   Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const params = (new URL(window.location.href)).searchParams;
    const tk = route.queryParams?.token ||  params.get("token");
    if(tk){
      sessionStorage.removeItem('current');
      sessionStorage.setItem("authCustomTk", "true");
      const provider = route.queryParams?.provider;
      return this.authService.authWithCustomTk(tk, provider as FirebaseProvider);
    } else {
      const adminConsent = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('adminConsent'))));
      if(adminConsent){
        const codeMicrosoft = route.queryParams?.admin_consent || params.get("admin_consent");
        if(codeMicrosoft){
          return this.router.navigate([adminConsent?.pathName]);
        }
      }

      const provider = params.get("provider") ?? "";
      const code = route.queryParams?.code || params.get("code");

      if (code && provider === ""){
        // classlink
        return this.classLinkIntService.getClasslinkInfo(code).then((value: boolean) => {
          if(value){
              return this.router.navigate([window.location.pathname]);
          } else {
            return this.router.navigate([`/login`]);
          }
        });
      }
      else if(code && provider === "clever") {
          //clever
          const redirect = `${environment.adminPortalURL}/dashboard?provider=clever`;
          return this.cleverIntService.getCleverLinkInfo(code, redirect).then((value: boolean) => {
            if(value){
                const path = sessionStorage.getItem('redirectUriClever');
                return this.router.navigate([`/dashboard${path}`]);
            } else {
              return this.router.navigate([`/login`]);
            }
          });
      }

      if (this.authService.isLoggedIn !== true) {
         this.router.navigate(['/login']);
         this.matDialog.closeAll();
      }
      return true;
    }
  }

}
