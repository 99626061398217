import { School, Student } from 'src/app/shared/graphql/generated/types';
export class ViewSchool implements School {
  id!: string | null | undefined;
  name!: string;
  students!: Student[] | null | undefined;
  admin!: string;
  credits!: number;
  isSaving!: boolean;
  fails!: boolean;
  constructor(data?: any) {
    if (data) {
    this.id = data.id || '0';
    this.name = data.name || '';
    this.admin = data.admin || '';
    this.students = data.students || 0;
    this.credits = data.credits || 0;
    this.isSaving = data.isSaving || false;
    this.fails = data.fails || false;
    }
  }
}
