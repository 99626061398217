import { MenuItem } from '../models';

export const MENU_ITEMS = [
  new MenuItem({
    id: 'dashboard',
    displayName: 'Dashboard',
    divider: false,
    path: '',
    icon: '',
    children: [
      new MenuItem({
        id: 'dashboard',
        path: 'dashboard/{districtId}/{role}',
        displayName: 'Dashboard',
        icon: 'dashboard',
        active: false,
        class: 'material-icons-outlined',
        children: []
      })
    ]
  }),
  new MenuItem({
    id: 'manage',
    displayName: 'Manage',
    path: '',
    icon: '',
    divider: true,
    children: [
      new MenuItem({
        id: 'district',
        path: 'manage/{districtId}',
        displayName: 'District',
        icon: 'account_tree',
        class: '',
        active: false,
        children: []
      }),
      new MenuItem({
        id: 'school',
        path: 'manage/{districtId}/school/{schoolId}',
        displayName: 'School',
        icon: 'corporate_fare',
        class: '',
        active: false,
        children: []
      }),
      new MenuItem({
        id: 'classes',
        path: 'manage/{districtId}/school/{schoolId}/classes',
        displayName: 'Classes',
        icon: 'class',
        class: 'material-icons-outlined',
        active: false,
        children: []
      })
    ]
  }),

];

export const RESOURCE_ITEM = new MenuItem({
  id: 'resources',
  displayName: 'Resources',
  path: '',
  icon: '',
  divider: true,
  children: [
  ]
});
