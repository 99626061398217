export const resourcesImages = {
  'google-image': '/favicons/google.svg',
  'only-cloud': '/images/only-cloud.png',
  'only-cloud-svg': '/images/only-cloud.svg',
  'only-cloud-svg-light': '/images/only-cloud-light.svg',
  'enroll-background': '/images/enroll/enroll-background.svg',
  'points-vector': '/images/enroll/points-vector.svg',
  'enroll-info-vector': '/images/enroll/enroll-info-vector.svg',
  'logo-itopia-labs-2': '/images/logo-itopia-labs-2.svg',
  'class-not-found': '/images/class-not-found.svg',
  'school-not-found': '/images/school-not-found.svg',
  'district-not-found': '/images/district-not-found.svg',
  'microsoft-image': '/favicons/microsoft.svg',
  'classlink-light': '/favicons/classlink-light.svg',
  'classlink-dark': '/favicons/classlink-dark.svg',
  'lti-light': '/favicons/lti.png',
  'cpu-ligth': '/favicons/cpu-ligth.svg',
  'ram-ligth': '/favicons/ram-ligth.svg',
  'gpu-ligth': '/favicons/gpu-ligth.svg',
  'cpu-dark': '/favicons/cpu-dark.svg',
  'ram-dark': '/favicons/ram-dark.svg',
  'gpu-dark': '/favicons/gpu-dark.svg',
  'imagotype-dark': '/images/imagotype-labsDark.svg',
  'imagotype-light': '/images/imagotype-labsLight.svg',
  'blank-profile': '/images/blank-profile-picture.webp',
  'imagotype-labs': '/images/imagotype-labs.svg',
  'cloud-vertical-fullcolor': '/images/imagotype-vertical-fullcolor.svg',
  'cloud-vertical-white': '/images/imagotype-vertical-white.svg',
  'cloudapps-horizontal-white': '/images/imagotype-horizontal-white.svg',
  'cloudapps-horizontal-fullcolor': '/images/logo/fullcolor.png',
  'cloudapps-horizontal-white-2': '/images/logo/white.png',
  'only-appcloud': '/images/isotype-cloud-white.svg',
  'dark-comming-soon': '/images/coming-soon/dark-coming-soon-v2.svg',
  'light-comming-soon': '/images/coming-soon/light-coming-soon-v2.svg',
  'no-icon': '/images/resources/no-icon.svg',
  'educator-slider': '/images/educator/educator-slider.jpeg',
  'adobe-white': '/images/resources/white/adobe-logo.png',
  'autodesk-white': '/images/resources/white/autodesk-logo.png',
  'gmetrix-white': '/images/resources/white/gmetrix-logo.png',
  'microsoft-white': '/images/resources/white/microsoft_logo.png',
  'pltw-white': '/images/resources/white/pltw-logo.png',
  'unity-white': '/images/resources/white/unity-logo.png',
  'coding-white': '/images/resources/white/coding-logo.png',
  'adobe-color': '/images/resources/color/adobe-color-logo.png',
  'autodesk-color': '/images/resources/color/autodesk-color-logo.png',
  'gmetrix-color': '/images/resources/color/gmetrix-color-logo.png',
  'microsoft-color': '/images/resources/color/microsoft-color-logo.png',
  'pltw-color': '/images/resources/color/pltw-color-logo.png',
  'unity-color': '/images/resources/color/unity-color-logo.png',
  'coding-color': '/images/resources/color/coding-color-logo.png',
  '404-ligth': '/images/404-ligth.svg',
  '404-dark':'/images/404-dark.svg',
  'dashed-light': '/images/drag-and-drop/dashed-light.svg',
  'dashed-dark': '/images/drag-and-drop/dashed-dark.svg',
  'clever': '/favicons/clever.svg'
};
