/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {split, ApolloClientOptions, ApolloLink, InMemoryCache, defaultDataIdFromObject} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import _ from 'underscore';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import { CookieService } from 'ng2-cookies';

import { environment } from '../environments/environment';
import { AlertService } from './shared/services/alert.service';
import { AuthService } from './shared/services/auth.service';
import { AuthCookieService } from './shared/services/cookies.service';

const uri = `${environment.apiURL}/graphql`;

const apiUrl = environment.apiURL;
const wssUrl = apiUrl.replace('https://','wss://') + '/graphql';
let token: string | null;

export function createApollo(httpLink: HttpLink, authService: AuthService, alertService: AlertService, cookieService: AuthCookieService): ApolloClientOptions<any> {
  const auth = setContext((operation, context) => {
  const token = cookieService.getAuth('token');

    if (!token || token === '' || token === null  ) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE',
          'Content-Type': 'application/json;charset=utf-8',
          responseType: 'blob' as 'json',
        }
      };
    }
  });

   // Create a WebSocket link:
   const ws = new WebSocketLink({
    uri: wssUrl,
    options: {
      timeout: 100000,
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        return {
          token: `Bearer ${token}`
        };
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        {
          // You don't have permission.
         if(!message.toLowerCase().includes('authentication is required') &&
           !message.toLowerCase().includes("you don't have permission") &&
           !message.toLowerCase().includes("you are not authorized to access this site")) {
            const msg =  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
           alertService.error(msg);
          }
        }
      );
    }
    if (networkError) {
      if (networkError instanceof HttpErrorResponse){
        if (networkError.status === 401) {
            authService.signOut();
        }
        const errorMsgs = _.pluck(networkError.error.errors, 'message');
        errorMsgs.forEach((msg: string) => {
          console.log(msg);
        });
      } else {
        console.log(`[Network error]: ${networkError}`);
      }
    }
  });

  const optionsCache = {
    typePolicies: {
      Query: {
        fields: {
          District: {
            merge(existing: any, incoming: any): [] {
              return { ...existing, ...incoming };
            },
          },
         Admin: {
            merge(existing: any, incoming: any): [] {
              return { ...existing, ...incoming };
            },
          },
          Classroom: {
            merge(existing: any, incoming: any): [] {
              console.log(
                { ...existing, ...incoming }
              )
              return { ...existing, ...incoming };
            },
          },
          School: {
            merge(existing: any, incoming: any): [] {
              return { ...existing, ...incoming };
            },
          }
        }
      },
      Admin: {
        keyFields: ['id', 'resourceType'],
        merge: true
      },
      Classroom: {
        keyFields: ['id'],
        merge: true
      },
      District: {
        keyFields: ['id'],
        merge: true
      },
      School: {
        keyFields: ['id'],
        merge: true
      }
    },
    // tslint:disable-next-line: typedef
    dataIdFromObject(responseObject: any) {
      switch (responseObject.__typename) {
        case 'Admin':
          return `Admin:${responseObject.id}${responseObject.resourceType}`;
        case 'Classroom':
          return `Classroom:${responseObject.id}`;
        case 'District':
          return `District:${responseObject.id}`;
        case 'School':
          return `School:${responseObject.id}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  };
  const cache = new InMemoryCache(optionsCache);

  // const link = ApolloLink.from([basic, auth, errorLink, httpLink.create({ uri })]);
  const http = ApolloLink.from([auth, auth, errorLink, httpLink.create({ uri, withCredentials: true })]);

  const link = split(
    // split based on operation type
    ({query}) => {
      const operationDefinitionNode = getMainDefinition(query);
      return (
        operationDefinitionNode.kind === 'OperationDefinition' && operationDefinitionNode.operation === 'subscription'
      );
    },
    ws,
    http
  );


  return {
    link,
    cache
  };
}
@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService, AlertService, AuthCookieService],
    },
    CookieService
  ],
})
export class GraphQLModule {}
