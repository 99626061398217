export const MY_CUSTOM_BREAKPOINTS = [
  {
    alias: 'xs.print',
    mediaQuery: 'print and (max-width: 576px)',
    priority: 1000,
},
{
    alias: 'sm.print',
    mediaQuery: 'print and (min-width: 576.1px) and (max-width: 768px)',
    priority: 900,
},
{
    alias: 'md.print',
    mediaQuery: 'print and (min-width: 768.1px) and (max-width: 992px)',
    priority: 800,
},
{
    alias: 'lg.print',
    mediaQuery: 'print and (min-width: 992.1px) and (max-width: 1200px)',
    priority: 700,
},
{
    alias: 'xl.print',
    mediaQuery: 'print and (min-width: 1201px) and (max-width: 1366px)',
    priority: 600,
},
{
    alias: 'lt-sm.print',
    overlapping: true,
    mediaQuery: 'print and (max-width: 599.99px)',
    priority: 950,
},
{
    alias: 'lt-md.print',
    overlapping: true,
    mediaQuery: 'print and (max-width: 959.99px)',
    priority: 850,
},
{
    alias: 'lt-lg.print',
    overlapping: true,
    mediaQuery: 'print and (max-width: 1279.99px)',
    priority: 750,
},
{
    alias: 'lt-xl.print',
    overlapping: true,
    priority: 650,
    mediaQuery: 'print and (max-width: 1919.99px)',
},
{
    alias: 'gt-xs.print',
    overlapping: true,
    mediaQuery: 'print and (min-width: 600px)',
    priority: -950,
},
{
    alias: 'gt-sm.print',
    overlapping: true,
    mediaQuery: 'print and (min-width: 960px)',
    priority: -850,
}, {
    alias: 'gt-md.print',
    overlapping: true,
    mediaQuery: 'print and (min-width: 1280px)',
    priority: -750,
},
{
    alias: 'gt-lg.print',
    overlapping: true,
    mediaQuery: 'print and (min-width: 1920px)',
    priority: -650,
}];
