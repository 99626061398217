<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="0.5em">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0.5em">
    <mat-icon id="alert-icon" class="success-color" *ngIf="alertType === 'Success'">check_circle</mat-icon>
    <mat-icon id="alert-icon" class="error-color" *ngIf="alertType === 'Error'">cancel</mat-icon>
    <mat-icon id="alert-icon" class="primary-color" *ngIf="alertType === 'Info'">info</mat-icon>
    <mat-icon id="alert-icon" class="warn-color" *ngIf="alertType === 'Warning'">warning</mat-icon>
    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0.3125em">
      <h4> {{title}} </h4>
      <span> {{description}} </span>
      <a *ngIf="link" class="link" href="{{link}}" target="_blank"> {{linkDescription}}.</a>
    </div>
  </div>
  <button mat-button color="primary" id="close-alert" (click)="close()">Close</button>
</div>
