import { environment } from 'src/environments/environment';

export const defaultUrlAssets = '../assets';
// urlAssets: 'https://storage.googleapis.com/k12-dev-assets/assets',
export const URL_ASSETS = environment.bucket
  ? 'https://storage.googleapis.com/' + environment.bucket + '/assets'
  : defaultUrlAssets;

export * from './custom-breakpoint';
export * from './security-actions-role';
export * from './chart-colors';
export * from './menu-items';
export * from './validate-integration-labels';
