<div fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center" *ngIf="resourceNoExist">
        <div fxLayout="column" fxLayoutGap="10px" fxFlexOffset="100px">
            <div fxLayout="row" fxLayoutAlign="center center">
              <h1 class="title-district">OOPS.</h1>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <h2 class="des-district">{{textDescription}}</h2>
            </div>      
           
            <div class="img" *ngIf="resourceNoExist"><img src="{{isLight?resources[0]:resources[1]}}"  height="400px"></div>
        </div>
        <a mat-button color="primary" (click)="reloadDashboard()" *ngIf="resourceNoExist">
            <mat-icon>arrow_back</mat-icon>
            <span class="m-l-5">{{'Back to Dashboard ' | uppercase}}</span>
        </a>
</div>
