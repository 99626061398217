import { Injectable } from '@angular/core';
import { ClassLinkInfo } from 'src/app/shared/dto/classlink/classlinkInfo';
import { AuthCookieService } from 'src/app/shared/services/cookies.service';
import { environment } from 'src/environments/environment';
import _ from 'underscore'


@Injectable({
  providedIn: 'root'
})
export class ClassLinkIntegrationAuthService {

 classLinkInfo: ClassLinkInfo | undefined;
 loading!: boolean;
 tkAuth!: string;

  constructor(private authCookieService: AuthCookieService) {
      this.classLinkInfo = undefined;
  }

  getClasslinkInfo(code: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers();
      this.tkAuth = this.authCookieService.getAuth("token");
      if(this.tkAuth){
        myHeaders.append("Authorization", `Bearer ${this.tkAuth }`);
      }
      const requestOptions = {
        headers: myHeaders,
        method: 'GET', // or 'PUT'
      };
      fetch(environment.apiURL+"/auth/integration?code=" + code, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then((config) => {
          if(config){
            this.classLinkInfo = JSON.parse(atob(config));
            sessionStorage.setItem('classLinkInfo', JSON.stringify(this.classLinkInfo) )
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch((error) => {
          console.log("Failed to fetch data from myInfo");
          resolve(false);
      });
    });
  }

  classLinkRediect(): boolean {
    let classLinkConfigure = false;
    if(this.classLinkInfo) {
      classLinkConfigure = this.classLinkInfo?.classLinkConsent === true;
    }
    return classLinkConfigure;
  }

}
