/* eslint-disable @typescript-eslint/no-unsafe-return */
import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class FullscreenFriendlyOverlayContainer extends OverlayContainer {
  protected _createContainer(): void {
    super._createContainer();

    this._addFullscreenChangeListener(() => this._adjustParentForFullscreenChange());
  }

  private _adjustParentForFullscreenChange(): void {
    if (!this._containerElement) {
      return;
    }

    const fullscreenElement = this._getFullscreenElement();
    const parent = fullscreenElement || document.body;
    parent.appendChild(this._containerElement);
  }

  private _addFullscreenChangeListener(fn: () => void) {
    if (document.fullscreenEnabled) {
      document.addEventListener('fullscreenchange', fn);
    } else if ((document as any).webkitFullscreenEnabled) {
      document.addEventListener('webkitfullscreenchange', fn);
    } else if ((document as any).mozFullScreenEnabled) {
      document.addEventListener('mozfullscreenchange', fn);
    } else if ((document as any).msFullscreenEnabled) {
      document.addEventListener('MSFullscreenChange', fn);
    }
  }

  private _getFullscreenElement(): Element {
    return document.fullscreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).msFullscreenElement ||
        null;
  }
}
