export const ValidateIntegrationLabels = {
  Microsoft: {
    title: 'Microsoft Azure',
    items: [
      {
        'Azure AD Groups': ''
      }
    ]
  },
  Google: {
    title: 'Google Workspace',
    items: [
      {
        'Google Classroom Courses': ''
      },
      {
        'G Suite Groups': ''
      }
    ]
  },
  Classlink: {
    title: 'ClassLink',
    items: [
      {
        'ClassLink Classes': ''
      }
    ]
  },
  Clever: {
    title: 'Clever',
    items: [
      {
        'Clever Sections': ''
      }
    ]
  }
};
