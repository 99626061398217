import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';
import { Exact, Maybe, RedirectResult } from '../../graphql/generated/types';



export const ChargifyRedirectDocument = gql`
    subscription chargifyRedirect {
  chargifyRedirect {
    externalId
    url
  }
}
    `;

@Injectable({
  providedIn: 'root',
})
export class ChargifySubscriptionService extends Apollo.Subscription<
ChargifyRedirectSubscription,
ChargifyRedirectSubscriptionVariables
> {
  document = ChargifyRedirectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export type ChargifyRedirectSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChargifyRedirectSubscription = (
  { __typename?: 'Subscription' }
  & { chargifyRedirect?: Maybe<(
    { __typename?: 'RedirectResult' }
    & Pick<RedirectResult, 'externalId' | 'url'>
  )> }
);