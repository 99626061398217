import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import { Resources } from '../enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ErrorsDto } from '../dto/error-dto';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProblemsInterceptorService {

  public resourceNotFoundSubject: BehaviorSubject<Resources>;
  public resourceNotFound: Observable<Resources>;
  public resourceNotExist !: Resources;

  constructor(private authService: AuthService, private alertService: AlertService,
              private matDialog: ModalService, private router: Router) {
    this.resourceNotFoundSubject = new BehaviorSubject<Resources>(Resources.Empty);
    this.resourceNotFound = this.resourceNotFoundSubject.asObservable();
  }

  intercept(problem: any): void {
    this.resourceNotExist = Resources.Empty;
    if (problem) {
        const typeName = problem.__typename;
        switch(typeName) {
          case 'AuthenticationRequiredProblem':
            this.authService.signOut();
            break;
          case 'DeploymentDoesNotExistProblem':
          case 'SchoolDoesNotExistProblem':
            this.resourceNotExist = Resources.School;
            this.matDialog.closeAll();
            this.showNotFoundComponent();
            break;
          case 'ClassDoesNotExistProblem':
          case 'CollectionDoesNotExistProblem':
            this.resourceNotExist = Resources.Classroom;
            this.matDialog.closeAll();
            this.showNotFoundComponent();
            break;
          case 'DistrictDoesNotExistProblem':
            this.resourceNotExist = Resources.District;
            this.matDialog.closeAll();
            this.showNotFoundComponent();
            break;
          case 'TokenDoesNotExistProblem':
            this.router.navigate(['/login']);
            break;
          case 'AdminDoesNotExistProblem':
            this.alertService.error('Your account is not registered with us. Please contact', 'Security error',{link: 'mailto:support@itopia.com', linkDescription: 'support'});
            this.authService.signOut();
            break;
          default:
            if(problem.message && problem.__typename !== 'IntegrationNotReadyYetProblem') {
              this.alertService.error(problem.message, 'Validation error');
            }
        }
    }
 }
 interceptError(error: ErrorsDto, notify?: boolean, resource?: Resources): void {
    if(error){
        const authRequired = error?.graphQLErrors?.find(x=>x.message.toLowerCase().includes('authentication is required'));
        const auth404 = error?.graphQLErrors?.find(x=>x.message.toLowerCase().includes("you don't have permission")) ||
                        error?.graphQLErrors?.find(x=>x.message.toLowerCase().includes("you are not authorized to access this site"));
       
        if(authRequired) {
          this.authService.signOut();
        } else {
          if(auth404){
            this.resourceNotExist = resource || Resources.District;
            this.matDialog.closeAll();
            this.showNotFoundComponent();
          } else {
            if(notify){
              const message = error?.graphQLErrors[0]?.message;
              if(message){
                this.alertService.error(message, 'Validation error');
              }       
            }
          }         
        }
    }
 }

 showNotFoundComponent(): void {
   if(!location.pathname?.includes('login')){
      const isLegacyVersion = sessionStorage.getItem('isLegacyVersion')=== 'true';
      sessionStorage.setItem('resourceNotExist', this.resourceNotExist);
      if(!isLegacyVersion){
        this.authService.setLastDistrict('');
        this.authService.setLastSchool('');
        sessionStorage.removeItem('current');
        sessionStorage.removeItem('districts');
        this.router.navigate(['resource/not-found']);
      } else {
        this.resourceNotFoundSubject.next(this.resourceNotExist);
      }
    }
  }
}
