import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class GetUrlParamsService {

  constructor(private router: Router) { }

  public getParams(): string[] {
    const parsed = this.router.parseUrl(this.router.url);
    let segments;
    segments = _.groupBy(parsed.root.children.primary.segments, (val, index) => index % 2);
    segments = segments[0];
    segments.splice(0, 1);
    segments = _.pluck(segments, 'path');
    return segments;
  }
}
