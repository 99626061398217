import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EnrollComponent } from './shared/components/enroll/enroll.component';
import { OopsComponent } from './shared/components/oops/oops.component';
import { AuthGuard } from './shared/services/auth.guard';
import { Token } from '@angular/compiler';
import { TokenGuard } from './shared/services/token.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, TokenGuard],
        loadChildren: () =>
        import('./layout/layout.module').then(m => m.LayoutModule)
      }
    ]
  },
  {
    path: 'enroll',
    component: EnrollComponent,
    data: {
      title: 'Enroll Chargify'
    },
    children: []
  },
  {
    path: 'signup/:token',
    loadChildren: () =>
      import('./signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'wizard/district/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./signup/signup-wizzard/signup-wizzard.module').then(
        m => m.SignupWizzardModule
      )
  },
  {
    path: 'wizard/district/:id/school/:schoolId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./signup/signup-wizzard/signup-wizzard.module').then(
        m => m.SignupWizzardModule
      )
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'legacy',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'resource/not-found',
    component: OopsComponent,
  },
  {
    path: 'session/:vm',
    canActivate: [AuthGuard, TokenGuard],
    loadChildren: () =>
      import('src/app/istreamer/istreamer.module').then(
        m => m.IstreamerModule
      )
  },
  {
    path: 'session/admin/:vm',
    canActivate: [AuthGuard,TokenGuard],
    loadChildren: () =>
      import('src/app/istreamer/istreamer.module').then(
        m => m.IstreamerModule
      )
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
