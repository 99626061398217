import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import _ from 'underscore';
import { ZendeskUserData } from '../interfaces/zendesk-user-data';

declare var zE: any;

@Injectable({
  providedIn: 'root'
})
export class ZendeskChatService {
  script!: HTMLScriptElement | undefined;
  isLoad!: boolean; 
    
  constructor() {}

  loadScript(): void {
    const zendeskKey = environment.zendeskKey;
    this.script = document.createElement('script');
    this.script.type = 'text/javascript';
    this.script.id = 'ze-snippet';
    this.script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;

    this.script.onload = () => {           
      this.isLoad = true;
    };    
    document.body.appendChild(this.script);
  }

  setDataUserZendesk(send: ZendeskUserData): void {
    if(send && this.isLoad) {
      zE('webWidget', 'identify', {
        name: send.name?? '',
        email: send.email??'',
        id: send.id??'',
        hmac: send.hmac??'',
        company: send.company??''
     });
   }  
  }

  clearZendeskData(): void {
    if(this.isLoad && this.script){    
      this.isLoad = false;
      zE('webWidget', 'logout');
    }
  } 

}