import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { AlertType } from '../enums';
import { Alert } from '../models';

const defaultDuration = 7000;
const defaultOptions = {duration: defaultDuration, verticalPosition: 'bottom', horizontalPosition: 'center'};
const minNumC = 40;


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    constructor(private snackBar: MatSnackBar){}


    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): any {
        return this.subject.asObservable().pipe(filter((x: any) => x && x.id === id));
    }

    openComponent(message: string, type: AlertType, title:string, link?:{link:string, linkDescription:string}, options?: any): void {
      this.snackBar.openFromComponent(SnackbarComponent,
        Object.assign(this.getDefaultOptions(message),
        Object.assign({data: {
          title: title,
          link:link,
          description: message,
          alertType: type
        }, options})));
    }

    // convenience methods
    success(message: string, title?:string, link?:{link:string, linkDescription:string}, options?: any): void {
      title = title ? title: 'Success';
      this.openComponent(message, AlertType.Success, title,link, options);
    }


    error(message: string, title?:string, link?:{link:string, linkDescription:string}, options?: any): void {
      title = title ? title: 'Error';
      this.openComponent(message, AlertType.Error, title, link, options);
    }

    info(message: string, title?:string, link?:{link:string, linkDescription:string}, options?: any): void {
      title = title ? title: 'Info';
      this.openComponent(message, AlertType.Info, title, link, options);
    }

    warn(message: string, title?:string, link?:{link:string, linkDescription:string}, options?: any): void {
      title = title ? title: 'Warning';
      this.openComponent(message, AlertType.Warning, title, link, options);
    }

    getDefaultOptions(message: string): any {
      let conf = defaultOptions;
        if(message && message.length < minNumC) {
          conf.duration = defaultDuration;
        } else {
            let sum = (message.length - minNumC)/15 * 1000;
            conf.duration = defaultDuration + sum;
        }
        return conf;
    }

    // main alert method
    alert(alert: Alert): void {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(): void {
      this.snackBar.dismiss();
    }
}
