import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {  URL_ASSETS } from 'src/app/shared/const';
import { resourcesImages } from 'src/app/shared/const/images-resources';
import { environment } from 'src/environments/environment';
import { SubscriptionStateGQL } from '../../graphql';
import { ChargifySubscriptionService } from './redirect-subscription';

const delay = 3000;
const delayInterval = 15000;

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {
  resources!: Array<string>;
  loading!: boolean;
  externalId!: string;
  url!: string;
  dataArrived!: boolean;
  appName = environment.appName;

  constructor(private activatedRoute: ActivatedRoute, private subs: ChargifySubscriptionService, private router: Router, private apollo: Apollo,
    private subscriptionGQL : SubscriptionStateGQL) {
    this.resources = [
      URL_ASSETS + resourcesImages['only-cloud'],
    ];
    const params = this.activatedRoute?.snapshot?.queryParams;
    this.externalId = params?.id;
    this.loading = true;
    setTimeout(()=>{
       this.subscribeHubs();
    }, delay);
    this.interval();
   }

  ngOnInit(): void {}

  subscribeHubs(): void {
    this.subs.subscribe().subscribe((data:any)=>{
      if(data && data?.data?.chargifyRedirect?.externalId === this.externalId && data?.data?.chargifyRedirect?.url) {
        this.url = data?.data?.chargifyRedirect?.url;
        this.dataArrived = true;
        this.redirect();
        this.loading = false;
       }
    });
  }

  redirect(): void {
     this.router.navigateByUrl(this.url);
  }

  interval(): void {
    timer(delayInterval).subscribe(()=>{
      const query = this.apollo.watchQuery<any>({
        query: this.subscriptionGQL.document,
        variables: { externalId :this.externalId  },
        fetchPolicy: 'network-only'
      });
       query.valueChanges
      .pipe(
        map(({ data }) => {
          if(!this.dataArrived) {
            if(data?.subscriptionState?.subscription?.url && data?.subscriptionState?.subscription?.ready) {
                  this.url = data?.subscriptionState?.subscription?.url;
                  this.dataArrived = true;
                  this.redirect();
            } else {
              this.interval();
            }
          }
        }),
        catchError((error) => {
          return error;
        })
      ).subscribe();


    });
  }

}
