
export const iconsSvg = [
    {
        icon: 'sort_alphabetical_asc',
        url: '/favicons/sort-alphabetical-asc.svg'
    },
    {
        icon: 'sort_alphabetical_desc',
        url: '/favicons/sort-alphabetical-desc.svg'
    },
    {
        icon: 'sort_alphabetical',
        url: '/favicons/sort-alphabetical.svg'
    },
    {
        icon: 'sort_up',
        url: '/favicons/sort-up.svg'
    },
    {
        icon: 'sort_down',
        url: '/favicons/sort-down.svg'
    },
    {
        icon: 'cpu-ligth',
        url: '/favicons/cpu-ligth.svg'
    },
    {
        icon: 'gpu-ligth',
        url: '/favicons/gpu-ligth.svg'
    },
    {
        icon: 'ram-ligth',
        url: '/favicons/ram-ligth.svg'
    },
    {
        icon: 'cpu-dark',
        url: '/favicons/cpu-dark.svg'
    },
    {
        icon: 'ram-dark',
        url: '/favicons/ram-dark.svg'
    },
    {
        icon: 'gpu-dark',
        url: '/favicons/gpu-dark.svg'
    },
    {
        icon: 'clock_dark',
        url: '/favicons/clock-dark.svg'
    },
    {
        icon: 'clock_light',
        url: '/favicons/clock-light.svg'
    },
    {
        icon: 'user_awesome_dark',
        url: '/favicons/user-awesome-dark.svg'
    },
    {
        icon: 'user_awesome_light',
        url: '/favicons/user-awesome-light.svg'
    },
    {
        icon: 'license_activated',
        url: '/images/licenses-status/activated.svg'
    },
    {
        icon: 'license_future',
        url: '/images/licenses-status/future.svg'
    },
    {
        icon: 'license_expired',
        url: '/images/licenses-status/expired.svg'
    },
    {
        icon: 'license_available',
        url: '/images/licenses-status/available.svg'
    },
  ];
