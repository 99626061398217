export enum TzFrinedlyNames {
  EST = 'Eastern Standard Time',
  EDT = 'Eastern Daylight Time',
  CST = 'Central Standard Time',
  CDT = 'Central Daylight Time',
  MST = 'Mountain Standard Time',
  MDT = 'Mountain Daylight Time',
  PST = 'Pacific Standard Time',
  PDT = 'Pacific Daylight Time',
  AST = "Atlantic Standard Time",
  AKST = "Alaska Standard Time",
  HAST = "Hawaii Standard Time",
  WST = "Samoa Standard Time",
  CHST = "Chamorro Standard Time",
}
