/* eslint-disable @typescript-eslint/ban-types */

export class MenuItem {
  id!: string;
  displayName!: string;
  path!: string;
  queryParams!: Object;
  icon!: string;
  class!: string;
  divider!: boolean;
  image!: string;
  description!: string;
  defautlImage!: string;
  children!: MenuItem[];
  hidden!: boolean;
  active!: boolean;

  constructor(init?: Partial<MenuItem>) {
    Object.assign(this, init);
  }
}
