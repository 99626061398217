
export enum Resources {
  District = 'District',
  School = 'School',
  Classroom = 'Classroom',
  Image = 'Image',
  Tier = 'Tier',
  Student = 'Student',
  Instructor = 'Instructor',
  Empty = ''  
}
