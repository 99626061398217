import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { BREAKPOINT, FlexLayoutModule } from '@angular/flex-layout';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth.service';
import { MY_CUSTOM_BREAKPOINTS } from './shared/const';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { ChunkhandlerService } from './shared/services/chunkhandler.service';
import { FullscreenFriendlyOverlayContainer } from './shared/services';
import { OverlayContainer } from '@angular/cdk/overlay';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    GraphQLModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCarouselModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.labsAppId,
          authority: 'https://login.microsoftonline.com/common',
          redirectUri: environment.redirectUri
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        }
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: [
            'user.read',
            'user.read.all',
            'group.read.all',
            'groupmember.read.all'
          ]
        }
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([])
      }
    ),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ModalModule
  ],
  providers: [
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { separatorKeyCodes: [COMMA, ENTER] }
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'check', color: 'primary' }
    },
    { provide: BREAKPOINT, useValue: MY_CUSTOM_BREAKPOINTS, multi: true },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {provide: ErrorHandler, useClass: ChunkhandlerService},
    { provide: OverlayContainer, useClass: FullscreenFriendlyOverlayContainer },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
