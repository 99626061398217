import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class OopsService {
  private disableSchoolNav$ = new ReplaySubject<boolean>();
  public disableSchoolNav = this.disableSchoolNav$.asObservable();

  constructor() { }

  setDisableSchoolNav(disabled: boolean): void {
    this.disableSchoolNav$.next(disabled);
  }
}
