import { ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { School } from '../shared/graphql';

@Injectable({
  providedIn: 'root'
})
export class DashboardActionService {

  private refreshSchoolName$ = new ReplaySubject<string>();
  public refreshSchoolName = this.refreshSchoolName$.asObservable();

  private routesParams$ = new ReplaySubject<any>();
  public routesParams = this.routesParams$.asObservable();

  private changeActiveTab$ = new ReplaySubject<string>();
  public changeActiveTab = this.changeActiveTab$.asObservable();
  
  private changeSchool$ = new Subject<void>();
  public changeSchool = this.changeSchool$.asObservable();

  private disableTabs$ = new ReplaySubject<boolean>();
  public disableTabs = this.disableTabs$.asObservable();

  private lastSchool$ = new ReplaySubject<School>();
  public lastSchool = this.lastSchool$.asObservable();

  public route = '';

  constructor() { }

  setSchoolName(name: string): void {
    this.refreshSchoolName$.next(name);
  }
  setLastSchool(school: School): void {
    this.lastSchool$.next(school);
  }
  setRoutesParams(routesParams: any): void {
    this.routesParams$.next(routesParams);
  }
  setChangeActiveTab(link: string): void {
    this.changeActiveTab$.next(link);
  }

  setSchoolClassList(): void {
    this.changeSchool$.next();
  }

  setDisableTabs(disabled: boolean): void {
    this.disableTabs$.next(disabled);
  }
  setRoute(route: string): void {
    this.route = route;
  }
  getRoute(): string {
      return this.route;
  }
}
