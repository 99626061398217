import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};



export type ActivatedLicense = {
  __typename?: 'ActivatedLicense';
  activatedAt?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licensePeriod?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export type ActivatedLicenseMembers = {
  __typename?: 'ActivatedLicenseMembers';
  activatedLicenses?: Maybe<Scalars['Int']>;
  inactiveUsers?: Maybe<Scalars['Int']>;
  neverLoggedIn?: Maybe<Scalars['Int']>;
};

export type ActivatedLicensesBoolean = {
  __typename?: 'ActivatedLicensesBoolean';
  activatedBool?: Maybe<Scalars['Boolean']>;
};

export type ActivatedLicensesGetPayload = {
  __typename?: 'ActivatedLicensesGetPayload';
  activatedLicenses?: Maybe<ActivatedLicenseMembers>;
  problem?: Maybe<ActivatedLicensesGetProblem>;
};

export type ActivatedLicensesGetProblem = ActivatedLicenseMembers | AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem;

export type ActivatedLicensesInput = {
  districtId: Scalars['ID'];
  lastDays?: Maybe<Scalars['Int']>;
  schoolId: Scalars['String'];
  userType?: Maybe<Scalars['String']>;
};

export type ActiveStudentsHistoryPayload = {
  __typename?: 'ActiveStudentsHistoryPayload';
  problem?: Maybe<ActiveStudentsHistoryProblem>;
  studentsHistory?: Maybe<Array<Maybe<StudentsHistory>>>;
};

export type ActiveStudentsHistoryProblem = AuthenticationRequiredProblem | Problem | StudentsHistory;

export type Admin = {
  __typename?: 'Admin';
  all?: Maybe<Scalars['Boolean']>;
  districts?: Maybe<Array<Maybe<DistrictShort>>>;
  email?: Maybe<Scalars['String']>;
  hmac?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  refererId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type AdminAlreadyExistProblem = {
  __typename?: 'AdminAlreadyExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type AdminCreateInput = {
  districtId: Scalars['String'];
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  isCreateDistrict?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<AdminPermissionInput>>;
  role: Scalars['String'];
};

export type AdminCreateInputV2 = {
  districtId: Scalars['String'];
  districtName: Scalars['String'];
  email: Scalars['String'];
  resourceType: Scalars['String'];
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};

export type AdminCreatePayload = {
  __typename?: 'AdminCreatePayload';
  admin?: Maybe<Admin>;
  problem?: Maybe<AdminCreateProblem>;
};

export type AdminCreateProblem = Admin | AuthenticationRequiredProblem | InvalidEmailProblem | Problem | SchoolDoesNotExistProblem;

export type AdminDeleteBoolean = {
  __typename?: 'AdminDeleteBoolean';
  adminBool?: Maybe<Scalars['Boolean']>;
};

export type AdminDeletePayload = {
  __typename?: 'AdminDeletePayload';
  adminBool?: Maybe<AdminDeleteBoolean>;
  problem?: Maybe<AdminDeleteProblem>;
};

export type AdminDeleteProblem = Admin | AuthenticationRequiredProblem | Problem;

export type AdminDoesNotExistProblem = {
  __typename?: 'AdminDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type AdminForSchoolCreatePayload = {
  __typename?: 'AdminForSchoolCreatePayload';
  admin?: Maybe<Admin>;
  problem?: Maybe<AdminForSchoolCreateProblem>;
};

export type AdminForSchoolCreateProblem = Admin | AuthenticationRequiredProblem | DistrictDoesNotExistProblem | InvalidEmailProblem | Problem;

export type AdminGetPayload = {
  __typename?: 'AdminGetPayload';
  admin?: Maybe<Admin>;
  problem?: Maybe<AdminGetProblem>;
};

export type AdminGetProblem = Admin | AdminDoesNotExistProblem | AuthenticationRequiredProblem | Problem;

export type AdminInvitation = {
  __typename?: 'AdminInvitation';
  adminId: Scalars['String'];
  invitationId: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  refererId?: Maybe<Scalars['Boolean']>;
  resources?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type AdminPermissionInput = {
  all?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type AdminTrialCreatePayload = {
  __typename?: 'AdminTrialCreatePayload';
  problem?: Maybe<AdminTrialCreateProblem>;
  token?: Maybe<Scalars['String']>;
};

export type AdminTrialCreateProblem = Admin | Problem;

export type AdminTrialInput = {
  userAgent: Scalars['String'];
  userId: Scalars['String'];
};

export type AdminUpdateInput = {
  all?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  refererId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type AdminUpdatePayload = {
  __typename?: 'AdminUpdatePayload';
  adminBool?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<AdminDeleteProblem>;
};

export type AdminsGetPayload = {
  __typename?: 'AdminsGetPayload';
  admin?: Maybe<Array<Maybe<Admin>>>;
  problem?: Maybe<AdminsGetProblem>;
};

export type AdminsGetProblem = Admin | AuthenticationRequiredProblem | Problem;

export type AgreementNotAcceptedProblem = {
  __typename?: 'AgreementNotAcceptedProblem';
  message?: Maybe<Scalars['String']>;
};

export type AllStudentsInput = {
  classIds: Array<Scalars['String']>;
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  teacherEmail: Scalars['String'];
};

export type AllStudentsPayload = {
  __typename?: 'AllStudentsPayload';
  list?: Maybe<Array<Maybe<StudentClass>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<AllStudentsProblem>;
};

export type AllStudentsProblem = AuthenticationRequiredProblem | Problem | StudentClass | UsersNotFoundProblem;

export type AllowedDomainFormatErrorProblem = {
  __typename?: 'AllowedDomainFormatErrorProblem';
  message?: Maybe<Scalars['String']>;
};

export type AllowedDomainInput = {
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AllowedDomainUpdateProblem = {
  __typename?: 'AllowedDomainUpdateProblem';
  message?: Maybe<Scalars['String']>;
};

export type AllowedDomainsForSchoolUpdatePayload = {
  __typename?: 'AllowedDomainsForSchoolUpdatePayload';
  problem?: Maybe<UpdateAllowedDomainsForSchoolProblem>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type AllowedDomainsGetPayload = {
  __typename?: 'AllowedDomainsGetPayload';
  domains?: Maybe<Domains>;
  problem?: Maybe<AllowedDomainsProblem>;
};

export type AllowedDomainsProblem = AuthenticationRequiredProblem | Domains | Problem;

export type AllowedDomainsUpdatePayload = {
  __typename?: 'AllowedDomainsUpdatePayload';
  problem?: Maybe<UpdateAllowedDomainsProblem>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type ApplicationGetProblem = Applications | AuthenticationRequiredProblem | Problem;

export type ApplicationInput = {
  name: Scalars['String'];
  path: Scalars['String'];
};

export type ApplicationShort = {
  __typename?: 'ApplicationShort';
  displayName: Scalars['String'];
  hasLicense?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  licenseKey?: Maybe<Scalars['String']>;
};

export type ApplicationShortInput = {
  displayName: Scalars['String'];
  hasLicense?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  licenseKey?: Maybe<Scalars['String']>;
};

export type ApplicationUsageReportProblem = AuthenticationRequiredProblem | Problem | SchoolDoesNotExistProblem;

export type Applications = {
  __typename?: 'Applications';
  displayName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  hasLicense?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  includedApplications?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBaseApplication?: Maybe<Scalars['Boolean']>;
  license?: Maybe<License>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  orderApp?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ApplicationsGetPayload = {
  __typename?: 'ApplicationsGetPayload';
  applications?: Maybe<Array<Maybe<Applications>>>;
  problem?: Maybe<ApplicationGetProblem>;
};

export type ApplicationsLicenceInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApplicationsUsageInput = {
  applications: Array<ApplicationInput>;
  classIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtId: Scalars['String'];
  orderBy?: Maybe<Scalars['String']>;
  report: Scalars['String'];
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
  startPeriod: Scalars['String'];
  startSubPeriod: Scalars['String'];
};

export type ApplicationsUsageReport = {
  __typename?: 'ApplicationsUsageReport';
  applicationName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  mostRecentLaunch?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
  totalDuration?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type ApplicationsUsageReportPayload = {
  __typename?: 'ApplicationsUsageReportPayload';
  applicationsUsage?: Maybe<Array<Maybe<ApplicationsUsageReport>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<ApplicationUsageReportProblem>;
};

export type AuthenticationRequiredProblem = {
  __typename?: 'AuthenticationRequiredProblem';
  message?: Maybe<Scalars['String']>;
};

export type BaseIntegration = {
  __typename?: 'BaseIntegration';
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type BillingHistory = {
  __typename?: 'BillingHistory';
  collectionName?: Maybe<Scalars['String']>;
  creditUsage?: Maybe<Scalars['Float']>;
  deploymentName?: Maybe<Scalars['String']>;
  imageName?: Maybe<Scalars['String']>;
  instanceName?: Maybe<Scalars['String']>;
  machineSizeName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  sessionEndAt?: Maybe<Scalars['String']>;
  sessionStartAt?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
  timeUsage?: Maybe<Scalars['Int']>;
  userEmail?: Maybe<Scalars['String']>;
};

export type BillingHistoryConnection = {
  __typename?: 'BillingHistoryConnection';
  billingHistory?: Maybe<BillingHistoryPayload>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<BillingHistoryGetsProblem>;
};

export type BillingHistoryGetsProblem = AuthenticationRequiredProblem | BillingHistory | DistrictDoesNotExistProblem | MonthsProblem | Problem;

export type BillingHistoryPayload = {
  __typename?: 'BillingHistoryPayload';
  billingHistory?: Maybe<Array<Maybe<BillingHistory>>>;
  totalCreditUsage?: Maybe<Scalars['Float']>;
  totalTimeUsage?: Maybe<Scalars['Int']>;
};

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  billingAddress?: Maybe<Scalars['String']>;
  billingPortal?: Maybe<Scalars['Boolean']>;
};

export type BlockTeacherSettingDistrictPayload = {
  __typename?: 'BlockTeacherSettingDistrictPayload';
  problem?: Maybe<DistrictUpdateProblem>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type BlockTeacherSettingShoolPayload = {
  __typename?: 'BlockTeacherSettingShoolPayload';
  problem?: Maybe<SchoolUpdateProblem>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type CancelSession = {
  __typename?: 'CancelSession';
  cancel?: Maybe<Scalars['Boolean']>;
};

export type CancelStartSessionPayload = {
  __typename?: 'CancelStartSessionPayload';
  cancel?: Maybe<CancelSession>;
  problem?: Maybe<CancelStartSessionProblem>;
};

export type CancelStartSessionProblem = AuthenticationRequiredProblem | CancelSession | Problem | SessionDoesNotExistProblem;

export type CannotGetGcpRegionsProblem = {
  __typename?: 'CannotGetGcpRegionsProblem';
  message?: Maybe<Scalars['String']>;
};

export type CannotGetRegionsProblem = {
  __typename?: 'CannotGetRegionsProblem';
  message?: Maybe<Scalars['String']>;
};

export type CannotGetStartSessionScheduleProblem = {
  __typename?: 'CannotGetStartSessionScheduleProblem';
  message?: Maybe<Scalars['String']>;
};

export type CannotObtainConsumeProblem = {
  __typename?: 'CannotObtainConsumeProblem';
  message?: Maybe<Scalars['String']>;
};

export type CheckPermissionInput = {
  action?: Maybe<Scalars['String']>;
  checkActions?: Maybe<Scalars['Boolean']>;
  resourcepath?: Maybe<Array<Maybe<Scalars['String']>>>;
  token: Scalars['String'];
};

export type ClIntegrationNotReadyYetProblem = {
  __typename?: 'ClIntegrationNotReadyYetProblem';
  message?: Maybe<Scalars['String']>;
};

export type ClassDailyUsage = {
  __typename?: 'ClassDailyUsage';
  date?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<StudentsHistory>>>;
};

export type ClassDailyUsageGetsPayload = {
  __typename?: 'ClassDailyUsageGetsPayload';
  dailyUsage?: Maybe<Array<Maybe<ClassDailyUsage>>>;
  problem?: Maybe<ClassDailyUsageProblem>;
};

export type ClassDailyUsageProblem = AuthenticationRequiredProblem | Problem | StudentsHistory;

export type ClassDoesNotExistProblem = {
  __typename?: 'ClassDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ClassLinkClass = {
  __typename?: 'ClassLinkClass';
  classType?: Maybe<Scalars['String']>;
  dateLastModified?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<ClassLinkMetadata>;
  periods?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourcedId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClassLinkClassesGetProblem = AuthenticationRequiredProblem | ClIntegrationNotReadyYetProblem | ClassLinkClass | Problem;

export type ClassLinkClassesGetsPayload = {
  __typename?: 'ClassLinkClassesGetsPayload';
  classes?: Maybe<Array<Maybe<ClassLinkClass>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<ClassLinkClassesGetProblem>;
};

export type ClassLinkMetadata = {
  __typename?: 'ClassLinkMetadata';
  courseName?: Maybe<Scalars['String']>;
  numberOfEnrollments?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  teacherName?: Maybe<Scalars['String']>;
};

export type ClassUpdateAppsInput = {
  agree?: Maybe<Scalars['Boolean']>;
  applications: Array<Maybe<ApplicationShortInput>>;
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  tierId?: Maybe<Scalars['String']>;
};

export type ClassUpdateAppsPayload = {
  __typename?: 'ClassUpdateAppsPayload';
  classroom?: Maybe<Classroom>;
  problem?: Maybe<ClassUpdateAppsProblem>;
};

export type ClassUpdateAppsProblem = AgreementNotAcceptedProblem | AuthenticationRequiredProblem | Classroom | CollectionDoesNotExistProblem | Problem;

export type ClassUsage = {
  __typename?: 'ClassUsage';
  creditUsage?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  timeUsage?: Maybe<Scalars['Int']>;
};

export type ClassUsageGetsPayload = {
  __typename?: 'ClassUsageGetsPayload';
  classUsage?: Maybe<Array<Maybe<ClassUsage>>>;
  problem?: Maybe<ClassUsageGetsProblem>;
};

export type ClassUsageGetsProblem = AuthenticationRequiredProblem | ClassDoesNotExistProblem | ClassUsage | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem;

export type Classroom = {
  __typename?: 'Classroom';
  applications?: Maybe<Array<Maybe<ApplicationShort>>>;
  azureAdGroupId?: Maybe<Scalars['String']>;
  azureAdGroupName?: Maybe<Scalars['String']>;
  azureAdInstructorGroupId?: Maybe<Scalars['String']>;
  azureAdInstructorGroupName?: Maybe<Scalars['String']>;
  cleverSchoolId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  directAssignments?: Maybe<Array<Maybe<DirectAssignment>>>;
  districtId?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['String']>;
  hasLicense?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']>;
  instructorGroup?: Maybe<Scalars['String']>;
  instructorGroupId?: Maybe<Scalars['String']>;
  instructorLastSync?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Maybe<Student>>>;
  lastSync?: Maybe<Scalars['String']>;
  licenses?: Maybe<Array<Maybe<LicenseShortOutput>>>;
  name?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  remoteApp?: Maybe<Scalars['Boolean']>;
  remoteMembers?: Maybe<Array<Maybe<RemoteMember>>>;
  school?: Maybe<School>;
  schoolId: Scalars['String'];
  startInstances?: Maybe<Scalars['Boolean']>;
  studentGroup?: Maybe<Scalars['String']>;
  studentGroupId?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']>;
};

export type ClassroomBooleanResponse = {
  __typename?: 'ClassroomBooleanResponse';
  classbool?: Maybe<Scalars['Boolean']>;
};

export type ClassroomCreatePayload = {
  __typename?: 'ClassroomCreatePayload';
  classroom?: Maybe<Classroom>;
  problem?: Maybe<ClassroomCreateProblem>;
};

export type ClassroomCreateProblem = AuthenticationRequiredProblem | Classroom | CollectionAlreadyExistProblem | CreateClassroomProblem | DeploymentDoesNotExistProblem | ImageDoesNotExistProblem | IntegrationNotReadyYetProblem | MachineSizeInvalidImageProblem | MachineSizeNotExistProblem | Problem | RequiredFieldProblem | RequiredLicenseNotFoundProblem | SyncCoursesProblem | SyncInstructorsProblem | SyncStudentsProblem;

export type ClassroomDeletePayload = {
  __typename?: 'ClassroomDeletePayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<ClassroomDeleteProblem>;
};

export type ClassroomDeleteProblem = AgreementNotAcceptedProblem | AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | Problem;

export type ClassroomDoesNotExistProblem = {
  __typename?: 'ClassroomDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ClassroomGetPayload = {
  __typename?: 'ClassroomGetPayload';
  classroom?: Maybe<Classroom>;
  problem?: Maybe<ClassroomGetProblem>;
};

export type ClassroomGetProblem = AuthenticationRequiredProblem | ClassDoesNotExistProblem | Classroom | CollectionDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem;

export type ClassroomInput = {
  applications?: Maybe<Array<Maybe<ApplicationShortInput>>>;
  applicationsLicence?: Maybe<Array<Maybe<ApplicationsLicenceInput>>>;
  azureAdGroupId?: Maybe<Scalars['String']>;
  azureAdGroupName?: Maybe<Scalars['String']>;
  azureAdInstructorGroupId?: Maybe<Scalars['String']>;
  azureAdInstructorGroupName?: Maybe<Scalars['String']>;
  cleverSchoolId?: Maybe<Scalars['String']>;
  course?: Maybe<CourseInput>;
  description?: Maybe<Scalars['String']>;
  directAssignments?: Maybe<Array<Maybe<DirectAssignmentInput>>>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  instructorGroup?: Maybe<Scalars['String']>;
  instructorGroupId?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Int']>;
  remoteMembers?: Maybe<Array<Maybe<RemoteMemberInput>>>;
  resourceHubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startInstances?: Maybe<Scalars['Boolean']>;
  studentGroupId?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<Scalars['String']>>>;
  studentsGroup?: Maybe<Scalars['String']>;
  tierId?: Maybe<Scalars['String']>;
};

export type ClassroomMembers = {
  __typename?: 'ClassroomMembers';
  instructors?: Maybe<Scalars['Int']>;
  students?: Maybe<Scalars['Int']>;
};

export type ClassroomMembersGetPayload = {
  __typename?: 'ClassroomMembersGetPayload';
  members?: Maybe<ClassroomMembers>;
  problem?: Maybe<ClassroomMembersGetProblem>;
};

export type ClassroomMembersGetProblem = AuthenticationRequiredProblem | ClassroomMembers | CollectionDoesNotExistProblem | Problem;

export type ClassroomPatchPayload = {
  __typename?: 'ClassroomPatchPayload';
  problem?: Maybe<PathClassroomProblem>;
  update?: Maybe<Scalars['Boolean']>;
};

export type ClassroomResource = {
  __typename?: 'ClassroomResource';
  applications?: Maybe<Array<Maybe<ApplicationShort>>>;
  azureAdGroupId?: Maybe<Scalars['String']>;
  azureAdGroupName?: Maybe<Scalars['String']>;
  azureAdInstructorGroupId?: Maybe<Scalars['String']>;
  azureAdInstructorGroupName?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  directAssignments?: Maybe<Array<Maybe<DirectAssignment>>>;
  districtId?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  favorite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['String']>;
  instructorGroup?: Maybe<Scalars['String']>;
  instructorGroupId?: Maybe<Scalars['String']>;
  instructorLastSync?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Maybe<Student>>>;
  lastSync?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  remoteMembers?: Maybe<Array<Maybe<RemoteMember>>>;
  resourceHubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  school?: Maybe<School>;
  schoolId: Scalars['String'];
  startInstances?: Maybe<Scalars['Boolean']>;
  studentGroup?: Maybe<Scalars['String']>;
  studentGroupId?: Maybe<Scalars['String']>;
  tier?: Maybe<Tier>;
  tierId?: Maybe<Scalars['String']>;
};

export type ClassroomShort = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ClassroomSyncPayload = {
  __typename?: 'ClassroomSyncPayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  lastSync?: Maybe<Scalars['String']>;
  problem?: Maybe<ClassroomSyncProblem>;
};

export type ClassroomSyncProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | IntegrationNotReadyYetProblem | Problem | SchoolDoesNotExistProblem;

export type ClassroomTrialInput = {
  applications?: Maybe<Array<Maybe<ApplicationShortInput>>>;
  applicationsLicence?: Maybe<Array<Maybe<ApplicationsLicenceInput>>>;
  course?: Maybe<CourseInput>;
  description?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quota?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startInstances?: Maybe<Scalars['Boolean']>;
  students?: Maybe<Array<Maybe<Scalars['String']>>>;
  tierId?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

export type ClassroomUpdateInput = {
  applicationsLicence?: Maybe<Array<Maybe<ApplicationsLicenceInput>>>;
  description?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  exceptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  startInstances?: Maybe<Scalars['Boolean']>;
  students?: Maybe<Array<Maybe<Scalars['String']>>>;
  tierId?: Maybe<Scalars['String']>;
};

export type ClassroomUpdatePayload = {
  __typename?: 'ClassroomUpdatePayload';
  classroom?: Maybe<Classroom>;
  problem?: Maybe<ClassroomUpdateProblem>;
};

export type ClassroomUpdateProblem = AuthenticationRequiredProblem | Classroom | CollectionDoesNotExistProblem | Problem | QuotaCanNotBeNegativeProblem | RepeatStudentsExcludedProblem;

export type ClassroomsBooleanResponse = {
  __typename?: 'ClassroomsBooleanResponse';
  classesbool?: Maybe<Scalars['Boolean']>;
};

export type ClassroomsCount = {
  __typename?: 'ClassroomsCount';
  activatedLicenses?: Maybe<Scalars['Int']>;
  classroomsCount?: Maybe<Scalars['Int']>;
};

export type ClassroomsCountInput = {
  adminId: Scalars['String'];
  districtId: Scalars['ID'];
  lastDays?: Maybe<Scalars['Int']>;
  schoolId: Scalars['String'];
  user: Scalars['String'];
  userType?: Maybe<Scalars['String']>;
};

export type ClassroomsCountPayload = {
  __typename?: 'ClassroomsCountPayload';
  count?: Maybe<Scalars['Int']>;
  problem?: Maybe<ClassroomsGetProblem>;
};

export type ClassroomsDoNotExistProblem = {
  __typename?: 'ClassroomsDoNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ClassroomsGetPayload = {
  __typename?: 'ClassroomsGetPayload';
  classroom?: Maybe<Array<Maybe<Classroom>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<ClassroomsGetProblem>;
};

export type ClassroomsGetProblem = AuthenticationRequiredProblem | Classroom | ClassroomsDoNotExistProblem | CollectionSchoolDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem;

export type ClassroomsInput = {
  adminId: Scalars['String'];
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  user: Scalars['String'];
};

export type ClassroomsLicensesCountPayload = {
  __typename?: 'ClassroomsLicensesCountPayload';
  classroomsCount?: Maybe<ClassroomsCount>;
  problem?: Maybe<ClassroomsGetProblem>;
};

export type ClassroomsOutputGetPayload = {
  __typename?: 'ClassroomsOutputGetPayload';
  classroom?: Maybe<Array<Maybe<ClassroomResource>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<ClassroomsGetProblem>;
};

export type CleverIntegrationNotReadyYetProblem = {
  __typename?: 'CleverIntegrationNotReadyYetProblem';
  message?: Maybe<Scalars['String']>;
};

export type CleverSchool = {
  __typename?: 'CleverSchool';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CleverSchoolsGetProblem = AuthenticationRequiredProblem | CleverIntegrationNotReadyYetProblem | CleverSchool | Problem;

export type CleverSchoolsGetsPayload = {
  __typename?: 'CleverSchoolsGetsPayload';
  problem?: Maybe<CleverSchoolsGetProblem>;
  schools?: Maybe<Array<Maybe<CleverSchool>>>;
};

export type CleverSection = {
  __typename?: 'CleverSection';
  created?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  sectionNumber?: Maybe<Scalars['String']>;
  sisId?: Maybe<Scalars['String']>;
  studentsCount?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  teachersCount?: Maybe<Scalars['Int']>;
};

export type CleverSectionsGetProblem = AuthenticationRequiredProblem | CleverIntegrationNotReadyYetProblem | CleverSection | Problem;

export type CleverSectionsGetsPayload = {
  __typename?: 'CleverSectionsGetsPayload';
  problem?: Maybe<CleverSectionsGetProblem>;
  sections?: Maybe<Array<Maybe<CleverSection>>>;
};

export type CollectionAlreadyExistProblem = {
  __typename?: 'CollectionAlreadyExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type CollectionByDeploymentFailedProblem = {
  __typename?: 'CollectionByDeploymentFailedProblem';
  message?: Maybe<Scalars['String']>;
};

export type CollectionDoesNotExistProblem = {
  __typename?: 'CollectionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type CollectionLicenseInput = {
  key: Scalars['String'];
  preConfigLicense: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type CollectionSchoolDoesNotExistProblem = {
  __typename?: 'CollectionSchoolDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type CollectionStatus = {
  __typename?: 'CollectionStatus';
  classId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
};

export type CollectionsDoNotExistProblem = {
  __typename?: 'CollectionsDoNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ConfigurationGetProblem = AuthenticationRequiredProblem | Problem;

export type ConfigurationsGetPayload = {
  __typename?: 'ConfigurationsGetPayload';
  configurations?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  problem?: Maybe<ConfigurationGetProblem>;
};

export type ConnectActiveSessionPayload = {
  __typename?: 'ConnectActiveSessionPayload';
  problem?: Maybe<ConnectActiveSessionProblem>;
  sessionShared?: Maybe<SessionShared>;
};

export type ConnectActiveSessionProblem = AuthenticationRequiredProblem | Problem | Session | SessionAlreadyHasAnAdminProblem | SessionDoesNotExistProblem | SessionNotReadyYetProblem | UserAlreadyHasASessionProblem;

export type ConnectSession = {
  __typename?: 'ConnectSession';
  gatewayUrl?: Maybe<Scalars['String']>;
  instanceName?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
  serverToken?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};

export type ConnectSessionGetPayload = {
  __typename?: 'ConnectSessionGetPayload';
  problem?: Maybe<ConnectSessionProblem>;
  session?: Maybe<ConnectSession>;
};

export type ConnectSessionInput = {
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  studentEmail: Scalars['String'];
  userEmail: Scalars['String'];
  userFullName: Scalars['String'];
  userType: Scalars['String'];
};

export type ConnectSessionProblem = AuthenticationRequiredProblem | ConnectSession | PermissionDoesNotExistProblem | Problem | SessionDoesNotExistProblem | SessionPeerIdDoesNotExistProblem;

export type ConnectionInfoInput = {
  bandwidthDown?: Maybe<Scalars['String']>;
  bandwidthUp?: Maybe<Scalars['String']>;
  browser: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  jitter: Scalars['String'];
  latency: Scalars['String'];
  operatingSystem: Scalars['String'];
  packetLoss?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  screenResolution?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  trickleIce: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type ConnectionInfoPayload = {
  __typename?: 'ConnectionInfoPayload';
  problem?: Maybe<ConnectionInfoProblem>;
  studentbool?: Maybe<StudentBooleanResponse>;
};

export type ConnectionInfoProblem = AuthenticationRequiredProblem | IpConfigNotFoundProblem | Problem | Student;

export type CouldNotCreateSessionProblem = {
  __typename?: 'CouldNotCreateSessionProblem';
  message?: Maybe<Scalars['String']>;
};

export type Course = {
  __typename?: 'Course';
  courseState?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
};

export type CourseInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CoursesGetPayload = {
  __typename?: 'CoursesGetPayload';
  courses?: Maybe<Array<Maybe<Course>>>;
  pageToken?: Maybe<Scalars['String']>;
  problem?: Maybe<CoursesGetProblem>;
};

export type CoursesGetProblem = AuthenticationRequiredProblem | Course | DistrictDoesNotExistProblem | IntegrationNotReadyYetProblem | Problem;

export type CoursesSyncPayload = {
  __typename?: 'CoursesSyncPayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  lastSync?: Maybe<Scalars['String']>;
  problem?: Maybe<CoursesSyncProblem>;
};

export type CoursesSyncProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | SyncCoursesProblem;

export type CreateClassroomProblem = {
  __typename?: 'CreateClassroomProblem';
  message?: Maybe<Scalars['String']>;
};

export type CreateSchoolProblem = {
  __typename?: 'CreateSchoolProblem';
  message?: Maybe<Scalars['String']>;
};

export type CreateTrialSessionPayload = {
  __typename?: 'CreateTrialSessionPayload';
  problem?: Maybe<CreateTrialSessionProblem>;
  session?: Maybe<TrialSession>;
};

export type CreateTrialSessionProblem = AuthenticationRequiredProblem | CollectionDoesNotExistProblem | CouldNotCreateSessionProblem | DailyUserTrialSessionProblem | DistrictDoesNotExistProblem | NotMoreGlobalTrialSessionProblem | Problem | SchoolDoesNotExistProblem | Session | UserAlreadyHasASessionProblem;

export type Credit = {
  __typename?: 'Credit';
  credits?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type CreditCreatePayload = {
  __typename?: 'CreditCreatePayload';
  credit?: Maybe<Credit>;
  problem?: Maybe<CreditCreateProblem>;
};

export type CreditCreateProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem | Student;

export type CreditGetPayload = {
  __typename?: 'CreditGetPayload';
  credits?: Maybe<Array<Maybe<Credit>>>;
  problem?: Maybe<CreditGetProblem>;
};

export type CreditGetProblem = AuthenticationRequiredProblem | Credit | DistrictDoesNotExistProblem | Problem;

export type CreditInput = {
  credit?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type CurrentSessionInput = {
  classIds: Array<Maybe<Scalars['String']>>;
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type CurrentSessionsGetsPayload = {
  __typename?: 'CurrentSessionsGetsPayload';
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<CurrentSessionsGetsProblem>;
  sessions?: Maybe<Array<Maybe<Session>>>;
};

export type CurrentSessionsGetsProblem = AuthenticationRequiredProblem | ClassDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | Session;

export type DailyUserTrialSessionProblem = {
  __typename?: 'DailyUserTrialSessionProblem';
  limit?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type DeleteMultipleUsersInput = {
  classId: Scalars['String'];
  deleteAll?: Maybe<Scalars['Boolean']>;
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  userType: Scalars['String'];
  users: Array<Maybe<StudentInput>>;
};

export type DeleteResourcedLicensesInput = {
  deleteAll?: Maybe<Scalars['Boolean']>;
  districtId?: Maybe<Scalars['String']>;
  ids: Array<Maybe<Scalars['String']>>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};

export type DeleteSchoolProblem = {
  __typename?: 'DeleteSchoolProblem';
  message?: Maybe<Scalars['String']>;
};

export type DeleteUserInput = {
  classId: Scalars['String'];
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type DemoRequestInput = {
  customerInfo: Scalars['String'];
  firstName: Scalars['String'];
  hearAboutUs: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  purchasePlanDate: Scalars['String'];
  schoolDistrict: Scalars['String'];
  sendMoreInfo: Scalars['Boolean'];
  sessionId?: Maybe<Scalars['String']>;
  workEmail: Scalars['String'];
};

export type DemoRequestRegisterPayload = {
  __typename?: 'DemoRequestRegisterPayload';
  problem?: Maybe<DemoRequestRegisterProblem>;
  requestBool?: Maybe<Scalars['Boolean']>;
};

export type DemoRequestRegisterProblem = Admin | AuthenticationRequiredProblem | Problem;

export type DemographicInfo = {
  address?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type DeploymentDoesNotExistProblem = {
  __typename?: 'DeploymentDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type DirectAssignment = {
  __typename?: 'DirectAssignment';
  identifier?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DirectAssignmentInput = {
  identifier?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type District = {
  __typename?: 'District';
  admins?: Maybe<Array<Maybe<Admin>>>;
  billingAddress?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  blockTeacherAccess?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  hasIntegration?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  integrationProvider?: Maybe<Scalars['String']>;
  internalDistrict: Scalars['Boolean'];
  isSchool?: Maybe<Scalars['Boolean']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  istrialcheck?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<SchoolShort>>>;
  status?: Maybe<Scalars['String']>;
  studentsNotification?: Maybe<Scalars['Boolean']>;
  teachersNotification?: Maybe<Scalars['Boolean']>;
  totalCredits?: Maybe<Scalars['Int']>;
};

export type DistrictActivatedLicensesGetPayload = {
  __typename?: 'DistrictActivatedLicensesGetPayload';
  activatedBool?: Maybe<ActivatedLicensesBoolean>;
  problem?: Maybe<DistrictLicensesGetProblem>;
};

export type DistrictAlreadyExistProblem = {
  __typename?: 'DistrictAlreadyExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type DistrictCreateInput = {
  billingAddress: Scalars['String'];
  billingEmail: Scalars['String'];
  contactEmail: Scalars['String'];
  contactLastName: Scalars['String'];
  contactName: Scalars['String'];
  internalDistrict: Scalars['Boolean'];
  isSchool?: Maybe<Scalars['Boolean']>;
  istrialcheck: Scalars['Boolean'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type DistrictCreatePayload = {
  __typename?: 'DistrictCreatePayload';
  district?: Maybe<District>;
  problem?: Maybe<DistrictCreateProblem>;
};

export type DistrictCreateProblem = AuthenticationRequiredProblem | District | DistrictAlreadyExistProblem | Problem;

export type DistrictDeleteBoolean = {
  __typename?: 'DistrictDeleteBoolean';
  districtBool?: Maybe<Scalars['Boolean']>;
};

export type DistrictDeletePayload = {
  __typename?: 'DistrictDeletePayload';
  districtBool?: Maybe<DistrictDeleteBoolean>;
  problem?: Maybe<DistrictDeleteProblem>;
};

export type DistrictDeleteProblem = AuthenticationRequiredProblem | District | DistrictDoesNotExistProblem | Problem;

export type DistrictDoesNotExistProblem = {
  __typename?: 'DistrictDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type DistrictDoesNotHaveCredits = {
  __typename?: 'DistrictDoesNotHaveCredits';
  message?: Maybe<Scalars['String']>;
};

export type DistrictGetInput = {
  districtId: Scalars['ID'];
};

export type DistrictGetPayload = {
  __typename?: 'DistrictGetPayload';
  district?: Maybe<District>;
  problem?: Maybe<DistrictGetProblem>;
};

export type DistrictGetProblem = AuthenticationRequiredProblem | District | DistrictDoesNotExistProblem | Problem;

export type DistrictLicense = {
  __typename?: 'DistrictLicense';
  activatedLicenses?: Maybe<Array<Maybe<ActivatedLicense>>>;
  buyDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['ID']>;
  districtName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  licenceType?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['String']>;
};

export type DistrictLicenseCreatePayload = {
  __typename?: 'DistrictLicenseCreatePayload';
  districtLicense?: Maybe<DistrictLicense>;
  problem?: Maybe<DistrictLicenseGetProblem>;
};

export type DistrictLicenseGetProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | DistrictLicense | Problem;

export type DistrictLicenseGetsPayload = {
  __typename?: 'DistrictLicenseGetsPayload';
  licenses?: Maybe<DistrictLicensesGetPayload>;
  problem?: Maybe<DistrictLicensesGetProblem>;
};

export type DistrictLicenseInput = {
  buyDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  districtId: Scalars['ID'];
  districtName: Scalars['String'];
  endDate: Scalars['String'];
  license: Scalars['Int'];
  startDate: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type DistrictLicensesGetPayload = {
  __typename?: 'DistrictLicensesGetPayload';
  licenses?: Maybe<Array<Maybe<LicenseDto>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
};

export type DistrictLicensesGetProblem = AuthenticationRequiredProblem | LicenseDto | LicensesDoesNotExistProblem | Problem;

export type DistrictPatchedPayload = {
  __typename?: 'DistrictPatchedPayload';
  patched?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<DistrictPatchedProblem>;
};

export type DistrictPatchedProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem;

export type DistrictShort = {
  __typename?: 'DistrictShort';
  districtId: Scalars['String'];
  name: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<SchoolShort>>>;
};

export type DistrictUpdateInput = {
  billingEmail: Scalars['String'];
  contactLastName: Scalars['String'];
  contactName: Scalars['String'];
  id: Scalars['ID'];
  isSchool?: Maybe<Scalars['Boolean']>;
  istrialcheck?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DistrictUpdatePayload = {
  __typename?: 'DistrictUpdatePayload';
  district?: Maybe<District>;
  problem?: Maybe<DistrictUpdateProblem>;
};

export type DistrictUpdateProblem = AuthenticationRequiredProblem | District | DistrictDoesNotExistProblem | Problem;

export type DistrictsGetPayload = {
  __typename?: 'DistrictsGetPayload';
  districts?: Maybe<Array<Maybe<District>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
};

export type DistrictsGetsPayload = {
  __typename?: 'DistrictsGetsPayload';
  districts?: Maybe<DistrictsGetPayload>;
  problem?: Maybe<DistrictsGetsProblem>;
};

export type DistrictsGetsProblem = AuthenticationRequiredProblem | DistrictsGetsPayload | Problem;

export type Domains = {
  __typename?: 'Domains';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EarlyAccess = {
  __typename?: 'EarlyAccess';
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export type EarlyAccessGetPayload = {
  __typename?: 'EarlyAccessGetPayload';
  earlyAccess?: Maybe<Array<Maybe<EarlyAccess>>>;
  problem?: Maybe<EarlyAccessGetProblem>;
};

export type EarlyAccessGetProblem = AuthenticationRequiredProblem | EarlyAccessGetPayload | Problem;

export type EducatorTrialInput = {
  classId: Scalars['String'];
  className: Scalars['String'];
  districtId: Scalars['String'];
  fullName: Scalars['String'];
  imageId: Scalars['String'];
  imageName: Scalars['String'];
  notificationEmail: Scalars['String'];
  schoolId: Scalars['String'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type EducatorTrialRegisterPayload = {
  __typename?: 'EducatorTrialRegisterPayload';
  educatorBool?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<EducatorTrialRegisterProblem>;
};

export type EducatorTrialRegisterProblem = Admin | AuthenticationRequiredProblem | Problem;

export type EnableSchedulerSessionByClassPayload = {
  __typename?: 'EnableSchedulerSessionByClassPayload';
  problem?: Maybe<GenericProblem>;
  studentsWithProblem?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum EventsEnum {
  ChargifySubscriptionReady = 'CHARGIFY_SUBSCRIPTION_READY',
  ExceededQuota = 'EXCEEDED_QUOTA',
  InstanceCreated = 'INSTANCE_CREATED',
  InstanceGpuChanged = 'INSTANCE_GPU_CHANGED',
  InstancePersisted = 'INSTANCE_PERSISTED',
  InstanceRegionChanged = 'INSTANCE_REGION_CHANGED',
  InstanceStarted = 'INSTANCE_STARTED',
  InstanceZoneChanged = 'INSTANCE_ZONE_CHANGED',
  InsufficientCredits = 'INSUFFICIENT_CREDITS',
  OperationFailed = 'OPERATION_FAILED',
  SessionReady = 'SESSION_READY',
  UserSessionNotStart = 'USER_SESSION_NOT_START'
}

export type FullStudentUsageReport = {
  __typename?: 'FullStudentUsageReport';
  collectionName?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type FullStudentUsageReportPayload = {
  __typename?: 'FullStudentUsageReportPayload';
  problem?: Maybe<StudentUsageReportProblem>;
  studentUsage?: Maybe<Array<Maybe<FullStudentUsageReport>>>;
};

export type GGroup = {
  __typename?: 'GGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GGroupGetProblem = AuthenticationRequiredProblem | GGroup | IntegrationNotReadyYetProblem | Problem;

export type GGroupGetsPayload = {
  __typename?: 'GGroupGetsPayload';
  gGroup?: Maybe<Array<Maybe<GGroup>>>;
  pageToken?: Maybe<Scalars['String']>;
  problem?: Maybe<GGroupGetProblem>;
};

export type GcpRegion = {
  __typename?: 'GcpRegion';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GcpRegionGetProblem = AuthenticationRequiredProblem | CannotGetGcpRegionsProblem | GcpRegion | Problem;

export type GcpRegionInput = {
  latency?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type GcpRegionsGetsPayload = {
  __typename?: 'GcpRegionsGetsPayload';
  gcpRegions?: Maybe<Array<Maybe<GcpRegion>>>;
  problem?: Maybe<GcpRegionGetProblem>;
};

export type GenericProblem = AuthenticationRequiredProblem | Problem;

export type GetLicenseCategorieListPayload = {
  __typename?: 'GetLicenseCategorieListPayload';
  licenseCategorieList?: Maybe<Array<Maybe<LicenseCategorie>>>;
  problem?: Maybe<GetLicenseCategorieListProblem>;
};

export type GetLicenseCategorieListProblem = AuthenticationRequiredProblem | Problem;

export type GoogleIntegrationInput = {
  districtId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type HaveSchedulerSessionDisabledPayload = {
  __typename?: 'HaveSchedulerSessionDisabledPayload';
  have?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<GenericProblem>;
};

export type Image = {
  __typename?: 'Image';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includedApplications?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  latestBuild?: Maybe<Scalars['String']>;
  licenses?: Maybe<Array<Maybe<License>>>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
  tier?: Maybe<Array<Maybe<Tier>>>;
  trialDescription?: Maybe<Scalars['String']>;
};

export type ImageDoesNotExistProblem = {
  __typename?: 'ImageDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ImageGetPayload = {
  __typename?: 'ImageGetPayload';
  image?: Maybe<Image>;
  problem?: Maybe<ImageGetProblem>;
};

export type ImageGetProblem = AuthenticationRequiredProblem | Classroom | ImageDoesNotExistProblem | Problem;

export type ImagesGetPayload = {
  __typename?: 'ImagesGetPayload';
  images?: Maybe<Array<Maybe<Image>>>;
  problem?: Maybe<ImageGetProblem>;
};

export type InstructorDeletePayload = {
  __typename?: 'InstructorDeletePayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<InstructorsDeleteProblem>;
};

export type InstructorDeleteProblem = {
  __typename?: 'InstructorDeleteProblem';
  message?: Maybe<Scalars['String']>;
};

export type InstructorsDeleteProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | InstructorDeleteProblem | Problem | UserNotFoundProblem;

export type InstructorsSyncPayload = {
  __typename?: 'InstructorsSyncPayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<InstructorsSyncProblem>;
};

export type InstructorsSyncProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | SyncInstructorsProblem;

export type Integration = {
  __typename?: 'Integration';
  certificateUrl?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  networkMask?: Maybe<Scalars['String']>;
  signInPage?: Maybe<Scalars['String']>;
  signOutPage?: Maybe<Scalars['String']>;
  sso?: Maybe<Scalars['Boolean']>;
};

export type IntegrationClientIdExistProblem = {
  __typename?: 'IntegrationClientIdExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type IntegrationCouldNotGetProblem = {
  __typename?: 'IntegrationCouldNotGetProblem';
  message?: Maybe<Scalars['String']>;
};

export type IntegrationCreatePayload = {
  __typename?: 'IntegrationCreatePayload';
  integration?: Maybe<BaseIntegration>;
  problem?: Maybe<IntegrationCreateProblem>;
};

export type IntegrationCreateProblem = AuthenticationRequiredProblem | IntegrationClientIdExistProblem | Problem;

export type IntegrationDoesNotExistProblem = {
  __typename?: 'IntegrationDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type IntegrationGetPayload = {
  __typename?: 'IntegrationGetPayload';
  integration?: Maybe<Integration>;
  problem?: Maybe<IntegrationGetProblem>;
};

export type IntegrationGetProblem = AuthenticationRequiredProblem | Integration | IntegrationCouldNotGetProblem | Problem;

export type IntegrationNotFoundProblem = {
  __typename?: 'IntegrationNotFoundProblem';
  message?: Maybe<Scalars['String']>;
};

export type IntegrationNotReadyYetProblem = {
  __typename?: 'IntegrationNotReadyYetProblem';
  message?: Maybe<Scalars['String']>;
};

export type IntegrationsGetPayload = {
  __typename?: 'IntegrationsGetPayload';
  integrations?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  problem?: Maybe<IntegrationsGetProblem>;
};

export type IntegrationsGetProblem = AuthenticationRequiredProblem | Problem;

export type InvalidEmailProblem = {
  __typename?: 'InvalidEmailProblem';
  message?: Maybe<Scalars['String']>;
};

export type InvitationDoesNotExistProblem = {
  __typename?: 'InvitationDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type InvitationGetPayload = {
  __typename?: 'InvitationGetPayload';
  adminInvitation?: Maybe<AdminInvitation>;
  problem?: Maybe<InvitationGetProblem>;
};

export type InvitationGetProblem = AdminInvitation | AuthenticationRequiredProblem | Problem | TokenDoesNotExistProblem;

export type IpConfigNotFoundProblem = {
  __typename?: 'IpConfigNotFoundProblem';
  message?: Maybe<Scalars['String']>;
};


export type JsonPatchDocumentInput = {
  operation?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type LatencyInfoInput = {
  districtId: Scalars['String'];
  districtName: Scalars['String'];
  regions?: Maybe<Array<GcpRegionInput>>;
  userAgent: Scalars['String'];
  userName: Scalars['String'];
};

export type LatencyInfoPayload = {
  __typename?: 'LatencyInfoPayload';
  problem?: Maybe<LatencyInfoProblem>;
  studentbool?: Maybe<StudentBooleanResponse>;
};

export type LatencyInfoProblem = AuthenticationRequiredProblem | Problem | Student;

export type LicenceBooleanResponse = {
  __typename?: 'LicenceBooleanResponse';
  licensebool?: Maybe<Scalars['Boolean']>;
};

export type License = {
  __typename?: 'License';
  applications?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  infoUrl?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  regularExpression?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LicenseCategorie = {
  __typename?: 'LicenseCategorie';
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<LicenseCategorieType>>>;
};

export type LicenseCategorieType = {
  __typename?: 'LicenseCategorieType';
  customImageAccess?: Maybe<Scalars['Boolean']>;
  hierarchy?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  withGpu?: Maybe<Scalars['Boolean']>;
};

export type LicenseDetails = {
  __typename?: 'LicenseDetails';
  activated?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  deficit?: Maybe<Scalars['Int']>;
  endCurrentTerm?: Maybe<Scalars['String']>;
  purchases?: Maybe<Scalars['Int']>;
  startCurrentTerm?: Maybe<Scalars['String']>;
  totalPurchased?: Maybe<Scalars['Int']>;
};

export type LicenseDetailsGetPayload = {
  __typename?: 'LicenseDetailsGetPayload';
  licenseDetails?: Maybe<LicenseDetails>;
  problem?: Maybe<LicenseDetailsGetProblem>;
};

export type LicenseDetailsGetProblem = AuthenticationRequiredProblem | LicenseDetails | LicensesDoesNotExistProblem | Problem;

export type LicenseDto = {
  __typename?: 'LicenseDto';
  activatedLicenses?: Maybe<Scalars['Int']>;
  availableLicenses?: Maybe<Scalars['Int']>;
  buyDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
};

export type LicensePeriod = {
  __typename?: 'LicensePeriod';
  districtId?: Maybe<Scalars['ID']>;
  endPeriod?: Maybe<Scalars['String']>;
  startPeriod?: Maybe<Scalars['String']>;
};

export type LicenseShort = {
  __typename?: 'LicenseShort';
  displayName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LicenseShortInput = {
  displayName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LicenseShortOutput = {
  __typename?: 'LicenseShortOutput';
  from: Scalars['String'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type LicensedPeriodsGetProblem = AuthenticationRequiredProblem | LicensePeriod | Problem;

export type LicensedPeriodsGetsPayload = {
  __typename?: 'LicensedPeriodsGetsPayload';
  licensedPeriods?: Maybe<Array<Maybe<LicensePeriod>>>;
  problem?: Maybe<LicensedPeriodsGetProblem>;
};

export type LicensesDeleteProblem = {
  __typename?: 'LicensesDeleteProblem';
  message?: Maybe<Scalars['String']>;
};

export type LicensesDoesNotExistProblem = {
  __typename?: 'LicensesDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type LicensesDto = {
  __typename?: 'LicensesDto';
  activatedLicenses?: Maybe<Array<Maybe<ActivatedLicense>>>;
  availableLicenses?: Maybe<Scalars['Int']>;
  buyDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
};

export type LtiIntegrationInput = {
  clientId?: Maybe<Scalars['String']>;
  deploymentId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};

export type MGroup = {
  __typename?: 'MGroup';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MGroupGetProblem = AuthenticationRequiredProblem | MGroup | MIntegrationNotReadyYetProblem | Problem;

export type MGroupGetsPayload = {
  __typename?: 'MGroupGetsPayload';
  mGroup?: Maybe<Array<Maybe<MGroup>>>;
  pageToken?: Maybe<Scalars['String']>;
  problem?: Maybe<MGroupGetProblem>;
};

export type MIntegrationNotReadyYetProblem = {
  __typename?: 'MIntegrationNotReadyYetProblem';
  message?: Maybe<Scalars['String']>;
};

export type MachineSizeInvalidImageProblem = {
  __typename?: 'MachineSizeInvalidImageProblem';
  message?: Maybe<Scalars['String']>;
};

export type MachineSizeNotExistProblem = {
  __typename?: 'MachineSizeNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type ManageLicenseInput = {
  classId: Scalars['String'];
  districtId: Scalars['String'];
  licences: Array<Maybe<CollectionLicenseInput>>;
  schoolId: Scalars['String'];
};

export type ManageLicensePayload = {
  __typename?: 'ManageLicensePayload';
  classroom?: Maybe<Classroom>;
  problem?: Maybe<ManageLicenseProblem>;
};

export type ManageLicenseProblem = AuthenticationRequiredProblem | Classroom | CollectionDoesNotExistProblem | Problem;

export type ManageResourceOrderInput = {
  districtId: Scalars['String'];
  resourceIds: Array<Maybe<Scalars['String']>>;
  resourceType: Scalars['String'];
  schoolId: Scalars['String'];
};

export type ManageResourceOrderPayload = {
  __typename?: 'ManageResourceOrderPayload';
  problem?: Maybe<ManageResourceOrderProblem>;
  resourceOrder?: Maybe<ResourceOrder>;
};

export type ManageResourceOrderProblem = AuthenticationRequiredProblem | Problem | RepeatResourcesProblem;

export type MonthsProblem = {
  __typename?: 'MonthsProblem';
  message?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addClassCourses?: Maybe<CoursesSyncPayload>;
  addClassStudents?: Maybe<StudentsSyncPayload>;
  addClassTeachers?: Maybe<InstructorsSyncPayload>;
  addCreditToDistrict?: Maybe<CreditCreatePayload>;
  addDistrictLicense?: Maybe<DistrictLicenseCreatePayload>;
  addResourceLicense?: Maybe<ResourceLicenseCreatePayload>;
  addTrialUserToClasses?: Maybe<TrialUserSyncPayload>;
  blockTeacherSettingDistrict?: Maybe<BlockTeacherSettingDistrictPayload>;
  blockTeacherSettingSchool?: Maybe<BlockTeacherSettingShoolPayload>;
  cancelStartSession?: Maybe<CancelStartSessionPayload>;
  connectActiveSession?: Maybe<ConnectActiveSessionPayload>;
  connectionInfo?: Maybe<ConnectionInfoPayload>;
  createAdmin?: Maybe<AdminCreatePayload>;
  createAdminForSchool?: Maybe<AdminForSchoolCreatePayload>;
  createAdminTrial?: Maybe<AdminTrialCreatePayload>;
  createAllocationForSchool?: Maybe<SchoolCreatePayload>;
  createClassroom?: Maybe<ClassroomCreatePayload>;
  createDistrict?: Maybe<DistrictCreatePayload>;
  createGoogleIntegration?: Maybe<IntegrationCreatePayload>;
  createLtiIntegration?: Maybe<IntegrationCreatePayload>;
  createResourceSession?: Maybe<StudentSessionPayload>;
  createSchool?: Maybe<SchoolCreatePayload>;
  createStudentSession?: Maybe<StudentSessionPayload>;
  createTenantIntegration?: Maybe<IntegrationCreatePayload>;
  createTestDistrict?: Maybe<DistrictCreatePayload>;
  createTrialClassroom?: Maybe<ClassroomCreatePayload>;
  createTrialSession?: Maybe<CreateTrialSessionPayload>;
  deleteAdmin?: Maybe<AdminDeletePayload>;
  deleteAdminFromSchool?: Maybe<SchoolDeletePayload>;
  deleteClassroom?: Maybe<ClassroomDeletePayload>;
  deleteDistrict?: Maybe<DistrictDeletePayload>;
  deleteMultipleUsers?: Maybe<UsersDeletePayload>;
  deleteResourceLicenses?: Maybe<ResourceLicensesDeletePayload>;
  deleteScheduleStartSession?: Maybe<StartSessionScheduleDeletePayload>;
  deleteSchool?: Maybe<SchoolDeletePayload>;
  deleteStudent?: Maybe<StudentDeletePayload>;
  deleteTeacher?: Maybe<InstructorDeletePayload>;
  enableSchedulerSessionByClass?: Maybe<EnableSchedulerSessionByClassPayload>;
  fullStudentUsageReport?: Maybe<FullStudentUsageReportPayload>;
  latencyInfo?: Maybe<LatencyInfoPayload>;
  manageLicense?: Maybe<ManageLicensePayload>;
  manageResourceOrder?: Maybe<ManageResourceOrderPayload>;
  patchClassroom?: Maybe<ClassroomPatchPayload>;
  patchDistrict?: Maybe<DistrictPatchedPayload>;
  registerDemoRequest?: Maybe<DemoRequestRegisterPayload>;
  registerEducator?: Maybe<RegisterEducatorPayload>;
  registerEducatorTrial?: Maybe<EducatorTrialRegisterPayload>;
  removeSharedSession?: Maybe<RemoveSharedSessionPayload>;
  restartIStreamer?: Maybe<RestartIStreamerPayload>;
  saveSessionRating?: Maybe<SaveSessionPayload>;
  saveTrialAdmin?: Maybe<SaveAdminPayload>;
  scheduleStartSessions?: Maybe<ScheduleSessionsPayload>;
  setBookmark?: Maybe<SetBookmarkPayload>;
  shutdownMultipleSessions?: Maybe<ShutDownSessionPayload>;
  shutdownSession?: Maybe<ShutDownSessionPayload>;
  startStudentSessions?: Maybe<StudentSessionPayload>;
  studentUsageReport?: Maybe<StudentUsageReportPayload>;
  syncClass?: Maybe<ClassroomSyncPayload>;
  syncClassInstructors?: Maybe<ClassroomSyncPayload>;
  syncClassStudents?: Maybe<ClassroomSyncPayload>;
  updateAdmin?: Maybe<AdminUpdatePayload>;
  updateAllowedDomains?: Maybe<AllowedDomainsUpdatePayload>;
  updateAllowedDomainsForSchool?: Maybe<AllowedDomainsForSchoolUpdatePayload>;
  updateClassApps?: Maybe<ClassUpdateAppsPayload>;
  updateClassroom?: Maybe<ClassroomUpdatePayload>;
  updateDistrict?: Maybe<DistrictUpdatePayload>;
  updateIpSession?: Maybe<UpdateIpSessionPayload>;
  updateSession?: Maybe<UpdateSessionPayload>;
  updateSso?: Maybe<SsoCreatePayload>;
  updateUserInfo?: Maybe<UserInfoPayload>;
};


export type MutationAddClassCoursesArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  input?: Maybe<CourseInput>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
};


export type MutationAddClassStudentsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<Scalars['String']>>>;
  studentsNotification?: Maybe<Scalars['Boolean']>;
};


export type MutationAddClassTeachersArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
};


export type MutationAddCreditToDistrictArgs = {
  input?: Maybe<CreditInput>;
};


export type MutationAddDistrictLicenseArgs = {
  input: DistrictLicenseInput;
};


export type MutationAddResourceLicenseArgs = {
  input: ResourceLicenseInput;
};


export type MutationAddTrialUserToClassesArgs = {
  classrooms?: Maybe<Array<Maybe<ClassroomShort>>>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};


export type MutationBlockTeacherSettingDistrictArgs = {
  block: Scalars['Boolean'];
  districtId?: Maybe<Scalars['String']>;
};


export type MutationBlockTeacherSettingSchoolArgs = {
  block: Scalars['Boolean'];
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type MutationCancelStartSessionArgs = {
  classId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationConnectActiveSessionArgs = {
  input: ConnectSessionInput;
};


export type MutationConnectionInfoArgs = {
  input: ConnectionInfoInput;
};


export type MutationCreateAdminArgs = {
  input: AdminCreateInputV2;
};


export type MutationCreateAdminForSchoolArgs = {
  input: AdminCreateInputV2;
};


export type MutationCreateAdminTrialArgs = {
  input: AdminTrialInput;
};


export type MutationCreateAllocationForSchoolArgs = {
  schoolAllocation?: Maybe<SchoolAllocationInput>;
};


export type MutationCreateClassroomArgs = {
  input?: Maybe<ClassroomInput>;
};


export type MutationCreateDistrictArgs = {
  input: DistrictCreateInput;
};


export type MutationCreateGoogleIntegrationArgs = {
  input?: Maybe<GoogleIntegrationInput>;
};


export type MutationCreateLtiIntegrationArgs = {
  input?: Maybe<LtiIntegrationInput>;
};


export type MutationCreateResourceSessionArgs = {
  input: ResourceSessionInput;
};


export type MutationCreateSchoolArgs = {
  input?: Maybe<SchoolInput>;
};


export type MutationCreateStudentSessionArgs = {
  input: StudentSessionInput;
};


export type MutationCreateTenantIntegrationArgs = {
  input?: Maybe<TenantIntegrationInput>;
};


export type MutationCreateTestDistrictArgs = {
  input: TestDistrictCreateInput;
};


export type MutationCreateTrialClassroomArgs = {
  input?: Maybe<ClassroomTrialInput>;
};


export type MutationCreateTrialSessionArgs = {
  imageShortName: Scalars['String'];
  testDriveEmail?: Maybe<Scalars['String']>;
  viewStartedAt: Scalars['String'];
};


export type MutationDeleteAdminArgs = {
  adminId: Scalars['ID'];
  districtId: Scalars['ID'];
};


export type MutationDeleteAdminFromSchoolArgs = {
  adminId: Scalars['ID'];
  districtId: Scalars['ID'];
  schoolId: Scalars['ID'];
};


export type MutationDeleteClassroomArgs = {
  agree?: Maybe<Scalars['Boolean']>;
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type MutationDeleteDistrictArgs = {
  districtId: Scalars['String'];
};


export type MutationDeleteMultipleUsersArgs = {
  input?: Maybe<DeleteMultipleUsersInput>;
};


export type MutationDeleteResourceLicensesArgs = {
  input?: Maybe<DeleteResourcedLicensesInput>;
};


export type MutationDeleteScheduleStartSessionArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  scheduleId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type MutationDeleteSchoolArgs = {
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type MutationDeleteStudentArgs = {
  input?: Maybe<DeleteUserInput>;
};


export type MutationDeleteTeacherArgs = {
  input?: Maybe<DeleteUserInput>;
};


export type MutationEnableSchedulerSessionByClassArgs = {
  classId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationFullStudentUsageReportArgs = {
  input?: Maybe<StudentUsageInput>;
};


export type MutationLatencyInfoArgs = {
  input: LatencyInfoInput;
};


export type MutationManageLicenseArgs = {
  input?: Maybe<ManageLicenseInput>;
};


export type MutationManageResourceOrderArgs = {
  input?: Maybe<ManageResourceOrderInput>;
};


export type MutationPatchClassroomArgs = {
  input?: Maybe<PatchClassroomInput>;
};


export type MutationPatchDistrictArgs = {
  input: PatchDistrictInput;
};


export type MutationRegisterDemoRequestArgs = {
  input: DemoRequestInput;
};


export type MutationRegisterEducatorArgs = {
  input: RegisterEducatorInput;
};


export type MutationRegisterEducatorTrialArgs = {
  input: EducatorTrialInput;
};


export type MutationRemoveSharedSessionArgs = {
  classId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationRestartIStreamerArgs = {
  input: RestartIStreamerInput;
};


export type MutationSaveSessionRatingArgs = {
  input: RateSessionInput;
};


export type MutationSaveTrialAdminArgs = {
  input: SaveAdminInput;
};


export type MutationScheduleStartSessionsArgs = {
  input?: Maybe<ScheduleStartSessionsInput>;
};


export type MutationSetBookmarkArgs = {
  input: SetBookmarkInput;
};


export type MutationShutdownMultipleSessionsArgs = {
  input?: Maybe<ShutdownMultipleSessionsInput>;
};


export type MutationShutdownSessionArgs = {
  input?: Maybe<ShutdownSessionInput>;
};


export type MutationStartStudentSessionsArgs = {
  input?: Maybe<StartSessionsInput>;
};


export type MutationStudentUsageReportArgs = {
  input?: Maybe<StudentUsageInput>;
};


export type MutationSyncClassArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type MutationSyncClassInstructorsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
};


export type MutationSyncClassStudentsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
};


export type MutationUpdateAllowedDomainsArgs = {
  districtId: Scalars['String'];
  domains?: Maybe<AllowedDomainInput>;
};


export type MutationUpdateAllowedDomainsForSchoolArgs = {
  districtId: Scalars['String'];
  domains?: Maybe<AllowedDomainInput>;
  schoolId: Scalars['String'];
};


export type MutationUpdateClassAppsArgs = {
  input?: Maybe<ClassUpdateAppsInput>;
};


export type MutationUpdateClassroomArgs = {
  classId?: Maybe<Scalars['String']>;
  input?: Maybe<ClassroomUpdateInput>;
};


export type MutationUpdateDistrictArgs = {
  input: DistrictUpdateInput;
};


export type MutationUpdateIpSessionArgs = {
  sessionId: Scalars['String'];
};


export type MutationUpdateSessionArgs = {
  input: PatchSessionInput;
};


export type MutationUpdateSsoArgs = {
  input?: Maybe<SsoInput>;
};


export type MutationUpdateUserInfoArgs = {
  input: UserInfoInput;
};

export type NetworkStatisticsPayload = {
  __typename?: 'NetworkStatisticsPayload';
  problem?: Maybe<NetworkStatisticsProblem>;
  statistics?: Maybe<Statistics>;
};

export type NetworkStatisticsProblem = AuthenticationRequiredProblem | Problem | Statistics;

export type NotMoreGlobalTrialSessionProblem = {
  __typename?: 'NotMoreGlobalTrialSessionProblem';
  message?: Maybe<Scalars['String']>;
};

export type PatchClassroomInput = {
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  patch?: Maybe<Array<JsonPatchDocumentInput>>;
  schoolId: Scalars['String'];
};

export type PatchDistrictInput = {
  districtId: Scalars['String'];
  patch?: Maybe<Array<JsonPatchDocumentInput>>;
};

export type PatchSessionInput = {
  patch?: Maybe<Array<JsonPatchDocumentInput>>;
  sessionId: Scalars['String'];
};

export type PathClassroomProblem = AuthenticationRequiredProblem | Problem;

export type Permission = {
  __typename?: 'Permission';
  all?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type PermissionDoesNotExistProblem = {
  __typename?: 'PermissionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type PermissionsByResourceGetProblem = AuthenticationRequiredProblem | Permission | PermissionDoesNotExistProblem | Problem;

export type PermissionsGetPayload = {
  __typename?: 'PermissionsGetPayload';
  permissions?: Maybe<Permission>;
  problem?: Maybe<PermissionsByResourceGetProblem>;
};

export type PopularClasses = {
  __typename?: 'PopularClasses';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Int']>;
};

export type PopularClassesGetsPayload = {
  __typename?: 'PopularClassesGetsPayload';
  popularClasses?: Maybe<Array<Maybe<PopularClasses>>>;
  problem?: Maybe<PopularClassesProblem>;
};

export type PopularClassesInput = {
  days?: Maybe<Scalars['Int']>;
  districtId: Scalars['String'];
  schools: Array<Scalars['String']>;
};

export type PopularClassesProblem = AuthenticationRequiredProblem | PopularClasses | Problem;

export type Problem = {
  __typename?: 'Problem';
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activatedLicenses?: Maybe<ActivatedLicensesGetPayload>;
  activeStudents?: Maybe<StudentsGetPayload>;
  activeStudentsHistory?: Maybe<ActiveStudentsHistoryPayload>;
  admin?: Maybe<AdminGetPayload>;
  admins?: Maybe<AdminsGetPayload>;
  allStudents?: Maybe<AllStudentsPayload>;
  allowedDomainsByDistrict?: Maybe<AllowedDomainsGetPayload>;
  allowedDomainsBySchool?: Maybe<AllowedDomainsGetPayload>;
  applications?: Maybe<ApplicationsGetPayload>;
  applicationsUsageReport?: Maybe<ApplicationsUsageReportPayload>;
  billingHistory?: Maybe<BillingHistoryConnection>;
  classDailyUsage?: Maybe<ClassDailyUsageGetsPayload>;
  classInstructors?: Maybe<StudentsGetsPayload>;
  classLinkClasses?: Maybe<ClassLinkClassesGetsPayload>;
  classStudents?: Maybe<StudentListPayload>;
  classUsage?: Maybe<ClassUsageGetsPayload>;
  classroom?: Maybe<ClassroomGetPayload>;
  classroomMembers?: Maybe<ClassroomMembersGetPayload>;
  classrooms?: Maybe<ClassroomsGetPayload>;
  classroomsCount?: Maybe<ClassroomsCountPayload>;
  classroomsLicensesCount?: Maybe<ClassroomsLicensesCountPayload>;
  cleverSchools?: Maybe<CleverSchoolsGetsPayload>;
  cleverSections?: Maybe<CleverSectionsGetsPayload>;
  collectionsByResourceHub?: Maybe<ClassroomsOutputGetPayload>;
  configurations?: Maybe<ConfigurationsGetPayload>;
  connectSession?: Maybe<ConnectSessionGetPayload>;
  courses?: Maybe<CoursesGetPayload>;
  credits?: Maybe<CreditGetPayload>;
  current?: Maybe<AdminGetPayload>;
  currentClassroomSessions?: Maybe<CurrentSessionsGetsPayload>;
  currentClassroomsSessions?: Maybe<CurrentSessionsGetsPayload>;
  currentSchoolSessions?: Maybe<CurrentSessionsGetsPayload>;
  district?: Maybe<DistrictGetPayload>;
  districtActivatedLicenses?: Maybe<DistrictActivatedLicensesGetPayload>;
  districtLicenses?: Maybe<DistrictLicenseGetsPayload>;
  districtSchools?: Maybe<SchoolsGetPayload>;
  districts?: Maybe<DistrictsGetsPayload>;
  earlyAccess?: Maybe<EarlyAccessGetPayload>;
  gcpRegions?: Maybe<GcpRegionsGetsPayload>;
  getBillingSubscription?: Maybe<SubscriptionGetPayload>;
  getLicenseCategorieList?: Maybe<GetLicenseCategorieListPayload>;
  groups?: Maybe<GGroupGetsPayload>;
  haveSchedulerSessionDisabled?: Maybe<HaveSchedulerSessionDisabledPayload>;
  image?: Maybe<ImageGetPayload>;
  images?: Maybe<ImagesGetPayload>;
  integration?: Maybe<IntegrationGetPayload>;
  integrations?: Maybe<IntegrationsGetPayload>;
  invitation?: Maybe<InvitationGetPayload>;
  licenseDetails?: Maybe<LicenseDetailsGetPayload>;
  licensedPeriods?: Maybe<LicensedPeriodsGetsPayload>;
  mGroups?: Maybe<MGroupGetsPayload>;
  mostActiveStudents?: Maybe<StudentUsageGetsPayload>;
  nearestRegion?: Maybe<RegionsGetsPayload>;
  networkStatistics?: Maybe<NetworkStatisticsPayload>;
  noRecentActivityByDays?: Maybe<AllStudentsPayload>;
  permissionsByResourcePath?: Maybe<PermissionsGetPayload>;
  popularClasses?: Maybe<PopularClassesGetsPayload>;
  recentResourceHubs?: Maybe<ClassroomsOutputGetPayload>;
  recentUserActivity?: Maybe<RecentUserActivityPayload>;
  resourceHubs?: Maybe<ResourceHubsGetPayload>;
  resourceLicenseValue?: Maybe<ResourceLicenseValueGetPayload>;
  resourceLicenses?: Maybe<ResourceLicensesGetsPayload>;
  resourceOrders?: Maybe<ResourceOrdersPayload>;
  school?: Maybe<SchoolGetPayload>;
  schools?: Maybe<SchoolsGetPayload>;
  schoolsLicenses?: Maybe<SchoolsLicensesPayload>;
  secureIdentity?: Maybe<SecureIdentityGetPayload>;
  sessionOrigin?: Maybe<SessionOriginPayload>;
  sso?: Maybe<SsoGetPayload>;
  startSessionsSchedule?: Maybe<StartSessionSchedulePayload>;
  statisticsDetails?: Maybe<StatisticsDetailsGetsPayload>;
  studentClassroomGateway?: Maybe<StudentClassroomGatewayGetPayload>;
  studentClassroomStatus?: Maybe<StudentClassroomStatusGetPayload>;
  studentClassrooms?: Maybe<StudentClassroomsGetPayload>;
  studentDistricts?: Maybe<StudentDistrictShortsGetsPayload>;
  studentHistoryReport?: Maybe<StudentHistoryReportGetsPayload>;
  studentSchools?: Maybe<StudentSchoolsGetPayload>;
  studentsNeverLogin?: Maybe<StudentsGetsPayload>;
  studentsNeverLoginByCollections?: Maybe<AllStudentsPayload>;
  subscriptionState?: Maybe<SubscriptionStatePayload>;
  tier?: Maybe<TierPayload>;
  tiers?: Maybe<TierGetPayload>;
  topCollections?: Maybe<UsageGetsPayload>;
  topImages?: Maybe<UsageGetsPayload>;
};


export type QueryActivatedLicensesArgs = {
  input: ActivatedLicensesInput;
};


export type QueryActiveStudentsArgs = {
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryActiveStudentsHistoryArgs = {
  input?: Maybe<StudentsCountInput>;
};


export type QueryAdminArgs = {
  districtId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
};


export type QueryAdminsArgs = {
  classId?: Maybe<Scalars['ID']>;
  districtId: Scalars['ID'];
  schoolId?: Maybe<Scalars['ID']>;
};


export type QueryAllStudentsArgs = {
  input: AllStudentsInput;
};


export type QueryAllowedDomainsByDistrictArgs = {
  districtId: Scalars['String'];
};


export type QueryAllowedDomainsBySchoolArgs = {
  districtId?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};


export type QueryApplicationsArgs = {
  districtId?: Maybe<Scalars['String']>;
  excludedApp?: Maybe<Scalars['Boolean']>;
  imageId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryApplicationsUsageReportArgs = {
  input?: Maybe<ApplicationsUsageInput>;
};


export type QueryBillingHistoryArgs = {
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  month: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  requestEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryClassDailyUsageArgs = {
  input: StudentsCountInput;
};


export type QueryClassInstructorsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryClassLinkClassesArgs = {
  districtId: Scalars['ID'];
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
};


export type QueryClassStudentsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryClassUsageArgs = {
  classId: Scalars['String'];
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryClassroomArgs = {
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryClassroomMembersArgs = {
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryClassroomsArgs = {
  input: ClassroomsInput;
};


export type QueryClassroomsCountArgs = {
  input: ClassroomsInput;
};


export type QueryClassroomsLicensesCountArgs = {
  input: ClassroomsCountInput;
};


export type QueryCleverSchoolsArgs = {
  districtId: Scalars['String'];
};


export type QueryCleverSectionsArgs = {
  cleverSchoolId: Scalars['String'];
  districtId: Scalars['String'];
};


export type QueryCollectionsByResourceHubArgs = {
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  resourceHubId: Scalars['String'];
};


export type QueryConfigurationsArgs = {
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryConnectSessionArgs = {
  classId: Scalars['String'];
  peerId?: Maybe<Scalars['String']>;
  studentEmail: Scalars['String'];
};


export type QueryCoursesArgs = {
  byTeacher?: Maybe<Scalars['Boolean']>;
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
};


export type QueryCreditsArgs = {
  districtId: Scalars['ID'];
};


export type QueryCurrentClassroomSessionsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryCurrentClassroomsSessionsArgs = {
  input?: Maybe<CurrentSessionInput>;
};


export type QueryCurrentSchoolSessionsArgs = {
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryDistrictArgs = {
  input: DistrictGetInput;
};


export type QueryDistrictActivatedLicensesArgs = {
  districtId: Scalars['ID'];
  districtName: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  requestEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate: Scalars['String'];
};


export type QueryDistrictLicensesArgs = {
  districtId: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDistrictSchoolsArgs = {
  districtId?: Maybe<Scalars['String']>;
};


export type QueryDistrictsArgs = {
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
};


export type QueryEarlyAccessArgs = {
  entityId?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
};


export type QueryGcpRegionsArgs = {
  districtId?: Maybe<Scalars['String']>;
};


export type QueryGetBillingSubscriptionArgs = {
  districtId: Scalars['String'];
  externalId: Scalars['Int'];
};


export type QueryGroupsArgs = {
  districtId: Scalars['ID'];
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type QueryHaveSchedulerSessionDisabledArgs = {
  classId?: Maybe<Scalars['String']>;
};


export type QueryImageArgs = {
  imageId?: Maybe<Scalars['String']>;
};


export type QueryImagesArgs = {
  districtId?: Maybe<Scalars['String']>;
  hasLicense?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryIntegrationArgs = {
  districtId?: Maybe<Scalars['String']>;
};


export type QueryIntegrationsArgs = {
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};


export type QueryInvitationArgs = {
  token?: Maybe<Scalars['String']>;
};


export type QueryLicenseDetailsArgs = {
  districtId: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryLicensedPeriodsArgs = {
  districtId: Scalars['ID'];
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
};


export type QueryMGroupsArgs = {
  districtId: Scalars['ID'];
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type QueryMostActiveStudentsArgs = {
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryNearestRegionArgs = {
  districtId?: Maybe<Scalars['String']>;
};


export type QueryNetworkStatisticsArgs = {
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryNoRecentActivityByDaysArgs = {
  input: NoRecentActivityInput;
};


export type QueryPermissionsByResourcePathArgs = {
  resourcePath?: Maybe<Array<Maybe<Scalars['String']>>>;
  user: Scalars['String'];
};


export type QueryPopularClassesArgs = {
  input: PopularClassesInput;
};


export type QueryRecentResourceHubsArgs = {
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
};


export type QueryRecentUserActivityArgs = {
  input: RecentUserActivityInput;
};


export type QueryResourceLicenseValueArgs = {
  districtId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryResourceLicensesArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryResourceOrdersArgs = {
  input: ResourceOrdersInput;
};


export type QuerySchoolArgs = {
  districtId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QuerySchoolsArgs = {
  input: SchoolsInput;
};


export type QuerySchoolsLicensesArgs = {
  districtId: Scalars['String'];
};


export type QuerySessionOriginArgs = {
  input?: Maybe<SessionOriginInput>;
};


export type QuerySsoArgs = {
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryStartSessionsScheduleArgs = {
  classId: Scalars['String'];
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
};


export type QueryStatisticsDetailsArgs = {
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  period: Scalars['Int'];
  schoolId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryStudentClassroomGatewayArgs = {
  classId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryStudentClassroomStatusArgs = {
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type QueryStudentClassroomsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};


export type QueryStudentDistrictsArgs = {
  userName: Scalars['String'];
};


export type QueryStudentHistoryReportArgs = {
  input?: Maybe<StudentHistoryInput>;
};


export type QueryStudentSchoolsArgs = {
  districtId: Scalars['String'];
  userName: Scalars['String'];
};


export type QueryStudentsNeverLoginArgs = {
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryStudentsNeverLoginByCollectionsArgs = {
  input: StudentsNeverLoginByCollectionsInput;
};


export type QuerySubscriptionStateArgs = {
  externalId?: Maybe<Scalars['String']>;
};


export type QueryTierArgs = {
  tierId: Scalars['String'];
};


export type QueryTiersArgs = {
  imageId?: Maybe<Scalars['String']>;
};


export type QueryTopCollectionsArgs = {
  classIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};


export type QueryTopImagesArgs = {
  input?: Maybe<TopImagesInput>;
};

export type QuotaCanNotBeNegativeProblem = {
  __typename?: 'QuotaCanNotBeNegativeProblem';
  message?: Maybe<Scalars['String']>;
};

export type RateInput = {
  Opinion?: Maybe<Scalars['String']>;
  Rating: Scalars['String'];
  UserEmail: Scalars['String'];
  UserId?: Maybe<Scalars['String']>;
};

export type RateSessionInput = {
  rate: RateInput;
  sessionId: Scalars['String'];
};

export type RecentUserActivityInput = {
  days?: Maybe<Scalars['Int']>;
  districtId: Scalars['String'];
  schools: Array<Maybe<Scalars['String']>>;
};

export type RecentUserActivityPayload = {
  __typename?: 'RecentUserActivityPayload';
  problem?: Maybe<RecentUserActivityProblem>;
  recentUsers?: Maybe<Array<Maybe<UserActivityType>>>;
};

export type RecentUserActivityProblem = AuthenticationRequiredProblem | District | Problem;

export type RedirectResult = {
  __typename?: 'RedirectResult';
  externalId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  coordenates?: Maybe<Scalars['String']>;
  gatewayUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  networkDiagnosticUrl?: Maybe<Scalars['String']>;
  turnWebUrl?: Maybe<Scalars['String']>;
};

export type RegionDoesNotExistProblem = {
  __typename?: 'RegionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type RegionGetProblem = AuthenticationRequiredProblem | CannotGetRegionsProblem | Problem | Region;

export type RegionsGetsPayload = {
  __typename?: 'RegionsGetsPayload';
  problem?: Maybe<RegionGetProblem>;
  regions?: Maybe<Array<Maybe<Region>>>;
};

export type RegisterEducatorInput = {
  certification?: Maybe<Scalars['Boolean']>;
  curriculums?: Maybe<Array<Maybe<Scalars['String']>>>;
  demographicInfo: DemographicInfo;
  districtName: Scalars['String'];
  sessionId?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  teachLevels: Array<Maybe<Scalars['String']>>;
};

export type RegisterEducatorPayload = {
  __typename?: 'RegisterEducatorPayload';
  problem?: Maybe<RegisterEducatorProblem>;
  registerBool?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type RegisterEducatorProblem = Admin | AuthenticationRequiredProblem | Problem;

export type RemoteMember = {
  __typename?: 'RemoteMember';
  identifier?: Maybe<Scalars['String']>;
  membersType?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type RemoteMemberInput = {
  identifier?: Maybe<Scalars['String']>;
  membersType?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type RemoveSharedSession = {
  __typename?: 'RemoveSharedSession';
  remove?: Maybe<Scalars['Boolean']>;
};

export type RemoveSharedSessionPayload = {
  __typename?: 'RemoveSharedSessionPayload';
  problem?: Maybe<RemoveSharedSessionProblem>;
  remove?: Maybe<RemoveSharedSession>;
};

export type RemoveSharedSessionProblem = AuthenticationRequiredProblem | Problem | RemoveSharedSession | SessionDoesNotExistProblem;

export type RepeatResourcesProblem = {
  __typename?: 'RepeatResourcesProblem';
  message?: Maybe<Scalars['String']>;
};

export type RepeatStudentsExcludedProblem = {
  __typename?: 'RepeatStudentsExcludedProblem';
  message?: Maybe<Scalars['String']>;
};

export type RequiredFieldProblem = {
  __typename?: 'RequiredFieldProblem';
  message?: Maybe<Scalars['String']>;
};

export type RequiredLicenseNotFoundProblem = {
  __typename?: 'RequiredLicenseNotFoundProblem';
  message?: Maybe<Scalars['String']>;
};

export type ResourceHub = {
  __typename?: 'ResourceHub';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type ResourceHubsGetPayload = {
  __typename?: 'ResourceHubsGetPayload';
  problem?: Maybe<ResourceHubsGetProblem>;
  resourceHubs?: Maybe<Array<Maybe<ResourceHub>>>;
};

export type ResourceHubsGetProblem = AuthenticationRequiredProblem | Problem | ResourceHub;

export type ResourceLicense = {
  __typename?: 'ResourceLicense';
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageName?: Maybe<Scalars['String']>;
  license?: Maybe<LicenseShort>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

export type ResourceLicenseCreatePayload = {
  __typename?: 'ResourceLicenseCreatePayload';
  problem?: Maybe<ResourceLicensesGetProblem>;
  resourceLicense?: Maybe<ResourceLicense>;
};

export type ResourceLicenseInput = {
  applyAllClasses?: Maybe<Scalars['Boolean']>;
  districtId?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageName?: Maybe<Scalars['String']>;
  licenses?: Maybe<Array<Maybe<LicenseShortInput>>>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};

export type ResourceLicenseValueGetPayload = {
  __typename?: 'ResourceLicenseValueGetPayload';
  problem?: Maybe<ResourceLicensesGetProblem>;
  value?: Maybe<Scalars['String']>;
};

export type ResourceLicensesDeletePayload = {
  __typename?: 'ResourceLicensesDeletePayload';
  licensebool?: Maybe<LicenceBooleanResponse>;
  problem?: Maybe<ResourceLicensesDeleteProblem>;
};

export type ResourceLicensesDeleteProblem = AuthenticationRequiredProblem | LicenceBooleanResponse | LicensesDeleteProblem | Problem;

export type ResourceLicensesGetProblem = AuthenticationRequiredProblem | Problem | ResourceLicense;

export type ResourceLicensesGetsPayload = {
  __typename?: 'ResourceLicensesGetsPayload';
  licenses?: Maybe<Array<Maybe<ResourceLicense>>>;
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<ResourceLicensesGetProblem>;
};

export type ResourceOrder = {
  __typename?: 'ResourceOrder';
  deploymentId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  resourceIds: Array<Scalars['String']>;
  resourceType: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ResourceOrdersInput = {
  districtId?: Maybe<Scalars['String']>;
  resourceType: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
};

export type ResourceOrdersPayload = {
  __typename?: 'ResourceOrdersPayload';
  order?: Maybe<Array<Maybe<ResourceOrder>>>;
  problem?: Maybe<ResourceOrdersProblem>;
};

export type ResourceOrdersProblem = AuthenticationRequiredProblem | Problem;

export type ResourceSessionInput = {
  classId: Scalars['String'];
  resourceHubId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type RestartIStreamerInput = {
  classId: Scalars['String'];
  email: Scalars['String'];
};

export type RestartIStreamerPayload = {
  __typename?: 'RestartIStreamerPayload';
  problem?: Maybe<RestartIStreamerProblem>;
  restart?: Maybe<Scalars['Boolean']>;
};

export type RestartIStreamerProblem = AuthenticationRequiredProblem | Problem;

export type SaveAdminInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  districtName: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  isSchool: Scalars['Boolean'];
  role: Scalars['String'];
  sessionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type SaveAdminPayload = {
  __typename?: 'SaveAdminPayload';
  problem?: Maybe<SaveAdminProblem>;
  saved?: Maybe<Scalars['Boolean']>;
};

export type SaveAdminProblem = Admin | AuthenticationRequiredProblem | Problem;

export type SaveSessionPayload = {
  __typename?: 'SaveSessionPayload';
  problem?: Maybe<SaveSessionProblem>;
  save?: Maybe<Scalars['Boolean']>;
};

export type SaveSessionProblem = AuthenticationRequiredProblem | Problem;

export type ScheduleSessionsPayload = {
  __typename?: 'ScheduleSessionsPayload';
  problem?: Maybe<StartSessionsProblem>;
  schedulerId?: Maybe<Scalars['String']>;
};

export type ScheduleStartSessionErrorProblem = {
  __typename?: 'ScheduleStartSessionErrorProblem';
  message?: Maybe<Scalars['String']>;
};

export type ScheduleStartSessionsInput = {
  allStudents?: Maybe<Scalars['Boolean']>;
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  frequencies?: Maybe<Array<Maybe<StartSessionFrequencyInput>>>;
  name?: Maybe<Scalars['String']>;
  schedulerId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  signInProvider?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<StartSessionUserInput>>>;
};

export type School = {
  __typename?: 'School';
  admins?: Maybe<Array<Maybe<Admin>>>;
  blockTeacherAccess?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  createAt?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Int']>;
  district?: Maybe<District>;
  districtId?: Maybe<Scalars['String']>;
  enableAllocation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddressList?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<Student>>>;
};

export type SchoolAllocationInput = {
  allocatedCredits?: Maybe<Scalars['Int']>;
  districtId: Scalars['String'];
  enableAllocation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type SchoolAlreadyExistProblem = {
  __typename?: 'SchoolAlreadyExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SchoolCreatePayload = {
  __typename?: 'SchoolCreatePayload';
  problem?: Maybe<SchoolCreateProblem>;
  school?: Maybe<School>;
};

export type SchoolCreateProblem = AuthenticationRequiredProblem | CreateSchoolProblem | DistrictDoesNotExistProblem | Problem | RegionDoesNotExistProblem | School | SchoolAlreadyExistProblem;

export type SchoolDeleteBoolean = {
  __typename?: 'SchoolDeleteBoolean';
  schoolBool?: Maybe<Scalars['Boolean']>;
};

export type SchoolDeletePayload = {
  __typename?: 'SchoolDeletePayload';
  problem?: Maybe<SchoolDeleteProblem>;
  schoolBool?: Maybe<SchoolDeleteBoolean>;
};

export type SchoolDeleteProblem = AuthenticationRequiredProblem | CollectionByDeploymentFailedProblem | DeleteSchoolProblem | PermissionDoesNotExistProblem | Problem | School;

export type SchoolDoesNotExistProblem = {
  __typename?: 'SchoolDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SchoolDoesNotHaveEnoughCreditsProblem = {
  __typename?: 'SchoolDoesNotHaveEnoughCreditsProblem';
  message?: Maybe<Scalars['String']>;
};

export type SchoolGetPayload = {
  __typename?: 'SchoolGetPayload';
  problem?: Maybe<SchoolGetProblem>;
  school?: Maybe<School>;
};

export type SchoolGetProblem = AuthenticationRequiredProblem | DeploymentDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | School | SchoolsDoNotExistProblem;

export type SchoolInput = {
  adminInput?: Maybe<AdminCreateInput>;
  districtId: Scalars['String'];
  name: Scalars['String'];
};

export type SchoolLicense = {
  __typename?: 'SchoolLicense';
  activatedLicenses?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
};

export type SchoolShort = {
  __typename?: 'SchoolShort';
  blockTeacherAccess?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type SchoolUpdateProblem = AuthenticationRequiredProblem | Problem | School | SchoolDoesNotExistProblem;

export type SchoolsDoNotExistProblem = {
  __typename?: 'SchoolsDoNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SchoolsGetPayload = {
  __typename?: 'SchoolsGetPayload';
  problem?: Maybe<SchoolGetProblem>;
  school?: Maybe<Array<Maybe<School>>>;
};

export type SchoolsInput = {
  adminId?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  permissionId?: Maybe<Scalars['String']>;
  user: Scalars['String'];
};

export type SchoolsLicenses = {
  __typename?: 'SchoolsLicenses';
  endCurrentTerm?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<SchoolLicense>>>;
  startCurrentTerm?: Maybe<Scalars['String']>;
  totalActivated?: Maybe<Scalars['Int']>;
  totalAvailable?: Maybe<Scalars['Int']>;
  totalPurchased?: Maybe<Scalars['Int']>;
};

export type SchoolsLicensesPayload = {
  __typename?: 'SchoolsLicensesPayload';
  licenses?: Maybe<SchoolsLicenses>;
  problem?: Maybe<SchoolsLicensesProblem>;
};

export type SchoolsLicensesProblem = AuthenticationRequiredProblem | LicensesDoesNotExistProblem | Problem | SchoolsLicenses;

export type SecureIdentityGetPayload = {
  __typename?: 'SecureIdentityGetPayload';
  problem?: Maybe<SecureIdentityGetProblem>;
  token?: Maybe<Scalars['String']>;
};

export type SecureIdentityGetProblem = AuthenticationRequiredProblem | Problem | SecureIdentityGetPayload;

export type Session = {
  __typename?: 'Session';
  collectionId?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  deploymentId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gatewayUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  imageName?: Maybe<Scalars['String']>;
  instanceName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sessionStartAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  trialSessionTime?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type SessionAlreadyHasAnAdminProblem = {
  __typename?: 'SessionAlreadyHasAnAdminProblem';
  message?: Maybe<Scalars['String']>;
};

export type SessionDoesNotExistProblem = {
  __typename?: 'SessionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SessionNotReadyYetProblem = {
  __typename?: 'SessionNotReadyYetProblem';
  message?: Maybe<Scalars['String']>;
};

export type SessionNotStartResult = {
  __typename?: 'SessionNotStartResult';
  collectionId?: Maybe<Scalars['String']>;
};

export type SessionOrigin = {
  __typename?: 'SessionOrigin';
  offCampus?: Maybe<Scalars['Int']>;
  onCampus?: Maybe<Scalars['Int']>;
};

export type SessionOriginInput = {
  days?: Maybe<Scalars['Int']>;
  districtId: Scalars['String'];
  schools: Array<Scalars['String']>;
};

export type SessionOriginPayload = {
  __typename?: 'SessionOriginPayload';
  problem?: Maybe<SessionOriginProblem>;
  sessionOrigin?: Maybe<SessionOrigin>;
};

export type SessionOriginProblem = AuthenticationRequiredProblem | Problem | SessionOrigin;

export type SessionPeerIdDoesNotExistProblem = {
  __typename?: 'SessionPeerIdDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SessionResult = {
  __typename?: 'SessionResult';
  collectionId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gateway?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type SessionShared = {
  __typename?: 'SessionShared';
  customToken?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
};

export type SessionShutdownInstanceProblem = {
  __typename?: 'SessionShutdownInstanceProblem';
  message?: Maybe<Scalars['String']>;
};

export type SetBookmarkInput = {
  classId: Scalars['String'];
  favorite: Scalars['Boolean'];
  resourceHubId: Scalars['String'];
};

export type SetBookmarkPayload = {
  __typename?: 'SetBookmarkPayload';
  problem?: Maybe<SetBookmarkProblem>;
  setBookmark?: Maybe<Scalars['Boolean']>;
};

export type SetBookmarkProblem = AuthenticationRequiredProblem | Problem;

export type ShutDownSession = {
  __typename?: 'ShutDownSession';
  shutDown?: Maybe<Scalars['Boolean']>;
};

export type ShutDownSessionPayload = {
  __typename?: 'ShutDownSessionPayload';
  problem?: Maybe<ShutDownSessionProblem>;
  shutDown?: Maybe<ShutDownSession>;
};

export type ShutDownSessionProblem = AuthenticationRequiredProblem | Problem | SessionDoesNotExistProblem | SessionShutdownInstanceProblem | ShutDownSession;

export type ShutdownMultipleSessionsInput = {
  allStudents: Scalars['Boolean'];
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  users: Array<Maybe<Scalars['String']>>;
};

export type ShutdownSessionInput = {
  classId: Array<Maybe<Scalars['String']>>;
  email: Scalars['String'];
  patch?: Maybe<Array<Maybe<JsonPatchDocumentInput>>>;
  userId: Scalars['String'];
};

export type SsoCreatePayload = {
  __typename?: 'SsoCreatePayload';
  problem?: Maybe<SsoCreateProblem>;
  updateSso?: Maybe<Scalars['Boolean']>;
};

export type SsoCreateProblem = AuthenticationRequiredProblem | Problem;

export type SsoGetPayload = {
  __typename?: 'SsoGetPayload';
  problem?: Maybe<SsoGetProblem>;
  sso?: Maybe<Scalars['Boolean']>;
};

export type SsoGetProblem = AuthenticationRequiredProblem | Problem;

export type SsoInput = {
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  sso: Scalars['Boolean'];
};

export type StartSessionFrequency = {
  __typename?: 'StartSessionFrequency';
  description?: Maybe<Scalars['String']>;
  endDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  startDates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StartSessionFrequencyInput = {
  description?: Maybe<Scalars['String']>;
  endDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  startDates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StartSessionScheduleDeleteBoolean = {
  __typename?: 'StartSessionScheduleDeleteBoolean';
  scheduleBool?: Maybe<Scalars['Boolean']>;
};

export type StartSessionScheduleDeletePayload = {
  __typename?: 'StartSessionScheduleDeletePayload';
  problem?: Maybe<StartSessionSchedulesDeleteProblem>;
  scheduleBool?: Maybe<StartSessionScheduleDeleteBoolean>;
};

export type StartSessionScheduleDeleteProblem = {
  __typename?: 'StartSessionScheduleDeleteProblem';
  message?: Maybe<Scalars['String']>;
};

export type StartSessionSchedulePayload = {
  __typename?: 'StartSessionSchedulePayload';
  problem?: Maybe<StartSessionsScheduleProblem>;
  schedules?: Maybe<Array<Maybe<StartSessionsSchedule>>>;
};

export type StartSessionSchedulesDeleteProblem = AuthenticationRequiredProblem | Problem | StartSessionScheduleDeleteProblem | StartSessionsSchedule;

export type StartSessionUser = {
  __typename?: 'StartSessionUser';
  email?: Maybe<Scalars['String']>;
  haveSessionDisabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type StartSessionUserInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type StartSessionsInput = {
  allStudents?: Maybe<Scalars['Boolean']>;
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  signInProvider?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StartSessionsProblem = AuthenticationRequiredProblem | CannotObtainConsumeProblem | ClassDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | ScheduleStartSessionErrorProblem | SchoolDoesNotExistProblem | SchoolDoesNotHaveEnoughCreditsProblem | StartSessionsSchedule | UserDoesNotExitsProblem | UserExceededQuotaPerWeekProblem;

export type StartSessionsSchedule = {
  __typename?: 'StartSessionsSchedule';
  allStudents?: Maybe<Scalars['Boolean']>;
  frequencies?: Maybe<Array<Maybe<StartSessionFrequency>>>;
  haveSessionDisabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<StartSessionUser>>>;
};

export type StartSessionsScheduleProblem = AuthenticationRequiredProblem | CannotGetStartSessionScheduleProblem | ClassDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | StartSessionsSchedule | UserDoesNotExitsProblem;

export type Statistics = {
  __typename?: 'Statistics';
  failed?: Maybe<Scalars['Int']>;
  healthy?: Maybe<Scalars['Int']>;
  poor?: Maybe<Scalars['Int']>;
};

export type StatisticsDetails = {
  __typename?: 'StatisticsDetails';
  bandwidth?: Maybe<Scalars['String']>;
  bandwidthUp?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  latency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trickleIce?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type StatisticsDetailsGetsPayload = {
  __typename?: 'StatisticsDetailsGetsPayload';
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<StatisticsDetailsProblem>;
  statisticsDetails?: Maybe<Array<Maybe<StatisticsDetails>>>;
};

export type StatisticsDetailsProblem = AuthenticationRequiredProblem | Problem | StatisticsDetails;

export type Student = {
  __typename?: 'Student';
  collections?: Maybe<Array<Maybe<StudentCollection>>>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastLogon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type StudentCollectionsArgs = {
  classId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
};

export type StudentBooleanResponse = {
  __typename?: 'StudentBooleanResponse';
  studentbool?: Maybe<Scalars['Boolean']>;
};

export type StudentClass = {
  __typename?: 'StudentClass';
  className?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type StudentClassroomGatewayGetPayload = {
  __typename?: 'StudentClassroomGatewayGetPayload';
  gatewayUrl?: Maybe<Scalars['String']>;
  problem?: Maybe<StudentClassroomGatewayGetProblem>;
};

export type StudentClassroomGatewayGetProblem = AuthenticationRequiredProblem | Problem | SessionDoesNotExistProblem | Student;

export type StudentClassroomStatusGetPayload = {
  __typename?: 'StudentClassroomStatusGetPayload';
  collectionStatus?: Maybe<Array<Maybe<CollectionStatus>>>;
  problem?: Maybe<StudentClassroomStatusGetProblem>;
};

export type StudentClassroomStatusGetProblem = AuthenticationRequiredProblem | Problem | SessionDoesNotExistProblem | Student;

export type StudentClassroomsGetPayload = {
  __typename?: 'StudentClassroomsGetPayload';
  collections?: Maybe<Array<Maybe<StudentCollection>>>;
  problem?: Maybe<StudentClassroomsGetProblem>;
};

export type StudentClassroomsGetProblem = AuthenticationRequiredProblem | CollectionsDoNotExistProblem | Problem | StudentCollection;

export type StudentCollection = {
  __typename?: 'StudentCollection';
  classId?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  classroom?: Maybe<Classroom>;
  districtId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  lastSession?: Maybe<Scalars['String']>;
  lastSync?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type StudentCollectionProblem = {
  __typename?: 'StudentCollectionProblem';
  message?: Maybe<Scalars['String']>;
};

export type StudentCreatePayload = {
  __typename?: 'StudentCreatePayload';
  problem?: Maybe<StudentCreateProblem>;
  student?: Maybe<Student>;
};

export type StudentCreateProblem = AuthenticationRequiredProblem | ClassDoesNotExistProblem | CouldNotCreateSessionProblem | PermissionDoesNotExistProblem | Problem | SchoolDoesNotHaveEnoughCreditsProblem | Student | UserAlreadyHasASessionProblem | UserExceededQuotaPerWeekProblem | UserInCollectionDoesNotExistProblem;

export type StudentDeletePayload = {
  __typename?: 'StudentDeletePayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<StudentsDeleteProblem>;
};

export type StudentDeleteProblem = {
  __typename?: 'StudentDeleteProblem';
  message?: Maybe<Scalars['String']>;
};

export type StudentDeployment = {
  __typename?: 'StudentDeployment';
  districtId?: Maybe<Scalars['String']>;
  school?: Maybe<SchoolShort>;
  schoolId?: Maybe<Scalars['String']>;
};

export type StudentDistrictShortsGetsPayload = {
  __typename?: 'StudentDistrictShortsGetsPayload';
  districts?: Maybe<Array<Maybe<DistrictShort>>>;
  problem?: Maybe<StudentDistrictsGetProblem>;
};

export type StudentDistrictsGetProblem = AuthenticationRequiredProblem | District | DistrictDoesNotExistProblem | Problem | UserDoesNotExitsProblem;

export type StudentGetProblem = AuthenticationRequiredProblem | ClassDoesNotExistProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | Student | StudentCollectionProblem | UserDoesNotExitsProblem;

export type StudentHistoryInput = {
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  report: Scalars['String'];
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate: Scalars['String'];
  startSubPeriod: Scalars['String'];
};

export type StudentHistoryReport = {
  __typename?: 'StudentHistoryReport';
  className?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  totalDuration?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type StudentHistoryReportGetsPayload = {
  __typename?: 'StudentHistoryReportGetsPayload';
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<StudentHistoryReportProblem>;
  studentUsage?: Maybe<Array<Maybe<StudentHistoryReport>>>;
};

export type StudentHistoryReportProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem | StudentHistoryReport;

export type StudentInput = {
  email: Scalars['String'];
  id: Scalars['String'];
};

export type StudentListPayload = {
  __typename?: 'StudentListPayload';
  isRosterSync?: Maybe<Scalars['Boolean']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageIndex?: Maybe<Scalars['String']>;
  problem?: Maybe<StudentGetProblem>;
  students?: Maybe<Array<Maybe<Student>>>;
};

export type StudentSchoolsGetPayload = {
  __typename?: 'StudentSchoolsGetPayload';
  problem?: Maybe<StudentSchoolsGetProblem>;
  schools?: Maybe<Array<Maybe<StudentDeployment>>>;
};

export type StudentSessionInput = {
  classId: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
};

export type StudentSessionPayload = {
  __typename?: 'StudentSessionPayload';
  problem?: Maybe<StudentCreateProblem>;
  start?: Maybe<Scalars['Boolean']>;
};

export type StudentUsage = {
  __typename?: 'StudentUsage';
  creditUsage?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timeUsage?: Maybe<Scalars['Int']>;
};

export type StudentUsageGetsPayload = {
  __typename?: 'StudentUsageGetsPayload';
  problem?: Maybe<StudentUsageGetsProblem>;
  studentUsage?: Maybe<Array<Maybe<StudentUsage>>>;
};

export type StudentUsageGetsProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | StudentUsage;

export type StudentUsageInput = {
  districtId: Scalars['String'];
  lessons: Array<Maybe<Scalars['String']>>;
  orderBy?: Maybe<Scalars['String']>;
  report: Scalars['String'];
  schoolId: Scalars['String'];
  startPeriod: Scalars['String'];
  startSubPeriod: Scalars['String'];
};

export type StudentUsageReport = {
  __typename?: 'StudentUsageReport';
  className?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  totalDuration?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type StudentUsageReportPayload = {
  __typename?: 'StudentUsageReportPayload';
  nextPage?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<StudentUsageReportProblem>;
  studentUsage?: Maybe<Array<Maybe<StudentUsageReport>>>;
};

export type StudentUsageReportProblem = AuthenticationRequiredProblem | Problem | SchoolDoesNotExistProblem | StudentUsageReport;

export type StudentsCountInput = {
  classes?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
};

export type StudentsDeleteProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | Problem | StudentDeleteProblem;

export type StudentsGetPayload = {
  __typename?: 'StudentsGetPayload';
  problem?: Maybe<SchoolGetProblem>;
  students?: Maybe<Array<Maybe<Student>>>;
};

export type StudentsGetsPayload = {
  __typename?: 'StudentsGetsPayload';
  problem?: Maybe<StudentGetProblem>;
  students?: Maybe<Array<Maybe<Student>>>;
};

export type StudentsHistory = {
  __typename?: 'StudentsHistory';
  collectionId?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  studentCount?: Maybe<Scalars['Int']>;
};

export type StudentsSyncPayload = {
  __typename?: 'StudentsSyncPayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<StudentsSyncProblem>;
};

export type StudentsSyncProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | CollectionDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | SyncStudentsProblem;

export type Subscription = {
  __typename?: 'Subscription';
  chargifyRedirect?: Maybe<RedirectResult>;
  sessionCreated?: Maybe<SessionResult>;
  sessionFinished?: Maybe<SessionResult>;
  sessionNotStart?: Maybe<SessionNotStartResult>;
};


export type SubscriptionSessionCreatedArgs = {
  email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type SubscriptionSessionFinishedArgs = {
  email?: Maybe<Scalars['String']>;
};


export type SubscriptionSessionNotStartArgs = {
  email?: Maybe<Scalars['String']>;
};

export type SubscriptionDoesNotExistProblem = {
  __typename?: 'SubscriptionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type SubscriptionGetPayload = {
  __typename?: 'SubscriptionGetPayload';
  problem?: Maybe<SubscriptionGetProblem>;
  subscription?: Maybe<BillingSubscription>;
};

export type SubscriptionGetProblem = AuthenticationRequiredProblem | BillingSubscription | Problem | SubscriptionDoesNotExistProblem;

export type SubscriptionState = {
  __typename?: 'SubscriptionState';
  ready?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type SubscriptionStatePayload = {
  __typename?: 'SubscriptionStatePayload';
  problem?: Maybe<SubscriptionStateProblem>;
  subscription?: Maybe<SubscriptionState>;
};

export type SubscriptionStateProblem = AuthenticationRequiredProblem | Problem | SubscriptionState;

export type SyncCoursesProblem = {
  __typename?: 'SyncCoursesProblem';
  message?: Maybe<Scalars['String']>;
};

export type SyncInstructorsProblem = {
  __typename?: 'SyncInstructorsProblem';
  message?: Maybe<Scalars['String']>;
};

export type SyncStudentsProblem = {
  __typename?: 'SyncStudentsProblem';
  message?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type TenantIntegrationInput = {
  districtId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type TestDistrictCreateInput = {
  billingAddress?: Maybe<Scalars['String']>;
  billingEmail: Scalars['String'];
  city: Scalars['String'];
  contactEmail: Scalars['String'];
  contactLastName: Scalars['String'];
  contactName: Scalars['String'];
  country: Scalars['String'];
  imageName: Scalars['String'];
  isSchool: Scalars['Boolean'];
  name: Scalars['String'];
  role: Scalars['String'];
  sessionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type Tier = {
  __typename?: 'Tier';
  active?: Maybe<Scalars['Boolean']>;
  allowed?: Maybe<Scalars['Boolean']>;
  cpu?: Maybe<Scalars['Int']>;
  gpu?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  machineType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ram?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type TierDoesNotExistProblem = {
  __typename?: 'TierDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type TierGetPayload = {
  __typename?: 'TierGetPayload';
  problem?: Maybe<ImageGetProblem>;
  tiers?: Maybe<Array<Maybe<Tier>>>;
};

export type TierGetProblem = AuthenticationRequiredProblem | Problem | Tier | TierDoesNotExistProblem;

export type TierPayload = {
  __typename?: 'TierPayload';
  problem?: Maybe<TierGetProblem>;
  tier?: Maybe<Tier>;
};

export type TokenDoesNotExistProblem = {
  __typename?: 'TokenDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type TopImagesInput = {
  applications?: Maybe<Array<ApplicationInput>>;
  classIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtId?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['String']>;
};

export type TrialSession = {
  __typename?: 'TrialSession';
  session?: Maybe<Session>;
  trialStatus?: Maybe<Scalars['String']>;
};

export type TrialUserSyncPayload = {
  __typename?: 'TrialUserSyncPayload';
  classesbool?: Maybe<ClassroomsBooleanResponse>;
  problem?: Maybe<TrialUserSyncProblem>;
};

export type TrialUserSyncProblem = AuthenticationRequiredProblem | ClassroomsBooleanResponse | Problem | SyncStudentsProblem;

export type UpdateAllowedDomainsForSchoolProblem = AllowedDomainFormatErrorProblem | AllowedDomainUpdateProblem | AuthenticationRequiredProblem | Domains | Problem | SchoolDoesNotExistProblem;

export type UpdateAllowedDomainsProblem = AllowedDomainFormatErrorProblem | AllowedDomainUpdateProblem | AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Domains | Problem;

export type UpdateIp = {
  __typename?: 'UpdateIp';
  updateIp?: Maybe<Scalars['Boolean']>;
};

export type UpdateIpSessionPayload = {
  __typename?: 'UpdateIpSessionPayload';
  problem?: Maybe<UpdateIpSessionProblem>;
  update?: Maybe<UpdateIp>;
};

export type UpdateIpSessionProblem = AuthenticationRequiredProblem | Problem | UpdateIp;

export type UpdateSessionPayload = {
  __typename?: 'UpdateSessionPayload';
  problem?: Maybe<UpdateSessionProblem>;
  update?: Maybe<Scalars['Boolean']>;
};

export type UpdateSessionProblem = AuthenticationRequiredProblem | Problem;

export type Usage = {
  __typename?: 'Usage';
  creditUsage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timeUsage?: Maybe<Scalars['Int']>;
};

export type UsageGetsPayload = {
  __typename?: 'UsageGetsPayload';
  problem?: Maybe<UsageGetsProblem>;
  usage?: Maybe<Array<Maybe<Usage>>>;
};

export type UsageGetsProblem = AuthenticationRequiredProblem | DistrictDoesNotExistProblem | Problem | SchoolDoesNotExistProblem | Usage;

export type UserActivityType = {
  __typename?: 'UserActivityType';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type UserAlreadyHasASessionProblem = {
  __typename?: 'UserAlreadyHasASessionProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserAlreadyHaveSessionProblem = {
  __typename?: 'UserAlreadyHaveSessionProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserDeleteProblem = {
  __typename?: 'UserDeleteProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserDoesNotExitsProblem = {
  __typename?: 'UserDoesNotExitsProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserExceededQuotaPerWeekProblem = {
  __typename?: 'UserExceededQuotaPerWeekProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserInCollectionDoesNotExistProblem = {
  __typename?: 'UserInCollectionDoesNotExistProblem';
  message?: Maybe<Scalars['String']>;
};

export type UserInfoInput = {
  fullName: Scalars['String'];
  userName: Scalars['String'];
};

export type UserInfoPayload = {
  __typename?: 'UserInfoPayload';
  problem?: Maybe<UserInfoProblem>;
  studentbool?: Maybe<StudentBooleanResponse>;
};

export type UserInfoProblem = AuthenticationRequiredProblem | Problem | Student;

export type UserNotFoundProblem = {
  __typename?: 'UserNotFoundProblem';
  message?: Maybe<Scalars['String']>;
};

export type UsersDeletePayload = {
  __typename?: 'UsersDeletePayload';
  classbool?: Maybe<ClassroomBooleanResponse>;
  problem?: Maybe<UsersDeleteProblem>;
};

export type UsersDeleteProblem = AuthenticationRequiredProblem | ClassroomBooleanResponse | Problem | UserDeleteProblem;

export type UsersNotFoundProblem = {
  __typename?: 'UsersNotFoundProblem';
  message?: Maybe<Scalars['String']>;
};

export type NoRecentActivityInput = {
  classIds: Array<Scalars['String']>;
  districtId: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  lastDays?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type StudentSchoolsGetProblem = AuthenticationRequiredProblem | Problem | SchoolsDoNotExistProblem | StudentDeployment;

export type StudentsNeverLoginByCollectionsInput = {
  classIds: Array<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  schoolId: Scalars['String'];
};

export type GetAdminsQueryVariables = Exact<{
  districtId: Scalars['ID'];
  schoolId?: Maybe<Scalars['ID']>;
  classId?: Maybe<Scalars['ID']>;
}>;


export type GetAdminsQuery = (
  { __typename?: 'Query' }
  & { admins?: Maybe<(
    { __typename?: 'AdminsGetPayload' }
    & { admin?: Maybe<Array<Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'name' | 'role' | 'email' | 'resourceType'>
    )>>>, problem?: Maybe<{ __typename?: 'Admin' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type PermissionsByResourcePathQueryVariables = Exact<{
  user: Scalars['String'];
  resourcePath?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type PermissionsByResourcePathQuery = (
  { __typename?: 'Query' }
  & { permissionsByResourcePath?: Maybe<(
    { __typename?: 'PermissionsGetPayload' }
    & { permissions?: Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'all' | 'name' | 'role' | 'resourceType' | 'resourceId'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Permission' } | { __typename?: 'PermissionDoesNotExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type GetEarlyAccessQueryVariables = Exact<{
  userEmail: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
}>;


export type GetEarlyAccessQuery = (
  { __typename?: 'Query' }
  & { earlyAccess?: Maybe<(
    { __typename?: 'EarlyAccessGetPayload' }
    & { earlyAccess?: Maybe<Array<Maybe<(
      { __typename?: 'EarlyAccess' }
      & Pick<EarlyAccess, 'name' | 'scope' | 'style'>
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'EarlyAccessGetPayload' } | { __typename?: 'Problem' }> }
  )> }
);

export type GetCurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentQuery = (
  { __typename?: 'Query' }
  & { current?: Maybe<(
    { __typename?: 'AdminGetPayload' }
    & { admin?: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'name' | 'email' | 'resourceType' | 'role' | 'hmac' | 'refererId'>
      & { districts?: Maybe<Array<Maybe<(
        { __typename?: 'DistrictShort' }
        & Pick<DistrictShort, 'districtId' | 'name' | 'role'>
        & { schools?: Maybe<Array<Maybe<(
          { __typename?: 'SchoolShort' }
          & Pick<SchoolShort, 'schoolId' | 'name' | 'resourceType' | 'role'>
        )>>> }
      )>>> }
    )>, problem?: Maybe<{ __typename?: 'Admin' } | (
      { __typename?: 'AdminDoesNotExistProblem' }
      & Pick<AdminDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type CreateAdminMutationVariables = Exact<{
  input: AdminCreateInputV2;
}>;


export type CreateAdminMutation = (
  { __typename?: 'Mutation' }
  & { createAdmin?: Maybe<(
    { __typename?: 'AdminCreatePayload' }
    & { admin?: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'name' | 'email' | 'role' | 'resourceType'>
    )>, problem?: Maybe<{ __typename?: 'Admin' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'InvalidEmailProblem' }
      & Pick<InvalidEmailProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type DeleteAdminMutationVariables = Exact<{
  districtId: Scalars['ID'];
  adminId: Scalars['ID'];
}>;


export type DeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdmin?: Maybe<(
    { __typename?: 'AdminDeletePayload' }
    & { adminBool?: Maybe<(
      { __typename?: 'AdminDeleteBoolean' }
      & Pick<AdminDeleteBoolean, 'adminBool'>
    )>, problem?: Maybe<{ __typename?: 'Admin' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type UpdateAdminMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateAdmin?: Maybe<(
    { __typename?: 'AdminUpdatePayload' }
    & Pick<AdminUpdatePayload, 'adminBool'>
    & { problem?: Maybe<{ __typename?: 'Admin' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type DeleteAdminFromSchoolMutationVariables = Exact<{
  districtId: Scalars['ID'];
  schoolId: Scalars['ID'];
  adminId: Scalars['ID'];
}>;


export type DeleteAdminFromSchoolMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdminFromSchool?: Maybe<(
    { __typename?: 'SchoolDeletePayload' }
    & { schoolBool?: Maybe<(
      { __typename?: 'SchoolDeleteBoolean' }
      & Pick<SchoolDeleteBoolean, 'schoolBool'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'CollectionByDeploymentFailedProblem' } | { __typename?: 'DeleteSchoolProblem' } | { __typename?: 'PermissionDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'School' }> }
  )> }
);

export type GetApplicationsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  excludedApp?: Maybe<Scalars['Boolean']>;
}>;


export type GetApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<(
    { __typename?: 'ApplicationsGetPayload' }
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Applications' }
      & Pick<Applications, 'id' | 'name' | 'hasLicense' | 'displayName' | 'type' | 'includedApplications' | 'isBaseApplication' | 'friendlyName' | 'icon' | 'orderApp' | 'images' | 'order' | 'path'>
      & { license?: Maybe<(
        { __typename?: 'License' }
        & Pick<License, 'key' | 'required' | 'type' | 'mask' | 'regularExpression' | 'name' | 'description' | 'placeholder' | 'infoUrl' | 'title' | 'label'>
      )> }
    )>>>, problem?: Maybe<{ __typename?: 'Applications' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ApplicationsUsageReportQueryVariables = Exact<{
  input?: Maybe<ApplicationsUsageInput>;
}>;


export type ApplicationsUsageReportQuery = (
  { __typename?: 'Query' }
  & { applicationsUsageReport?: Maybe<(
    { __typename?: 'ApplicationsUsageReportPayload' }
    & { applicationsUsage?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationsUsageReport' }
      & Pick<ApplicationsUsageReport, 'username' | 'applicationName' | 'duration' | 'totalDuration' | 'startDate' | 'endDate' | 'studentName' | 'mostRecentLaunch'>
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotExistProblem' }> }
  )> }
);

export type UpdateClassAppsMutationVariables = Exact<{
  input?: Maybe<ClassUpdateAppsInput>;
}>;


export type UpdateClassAppsMutation = (
  { __typename?: 'Mutation' }
  & { updateClassApps?: Maybe<(
    { __typename?: 'ClassUpdateAppsPayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id'>
      & { applications?: Maybe<Array<Maybe<(
        { __typename?: 'ApplicationShort' }
        & Pick<ApplicationShort, 'id' | 'displayName'>
      )>>> }
    )>, problem?: Maybe<{ __typename?: 'AgreementNotAcceptedProblem' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetAzureGroupsQueryVariables = Exact<{
  districtId: Scalars['ID'];
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;


export type GetAzureGroupsQuery = (
  { __typename?: 'Query' }
  & { mGroups?: Maybe<(
    { __typename?: 'MGroupGetsPayload' }
    & Pick<MGroupGetsPayload, 'pageToken'>
    & { mGroup?: Maybe<Array<Maybe<(
      { __typename?: 'MGroup' }
      & Pick<MGroup, 'name' | 'description' | 'id'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'MGroup' } | (
      { __typename?: 'MIntegrationNotReadyYetProblem' }
      & Pick<MIntegrationNotReadyYetProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ClassLinkClassesQueryVariables = Exact<{
  districtId: Scalars['ID'];
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type ClassLinkClassesQuery = (
  { __typename?: 'Query' }
  & { classLinkClasses?: Maybe<(
    { __typename: 'ClassLinkClassesGetsPayload' }
    & Pick<ClassLinkClassesGetsPayload, 'nextPage'>
    & { classes?: Maybe<Array<Maybe<(
      { __typename: 'ClassLinkClass' }
      & Pick<ClassLinkClass, 'sourcedId' | 'status' | 'dateLastModified' | 'title' | 'classType' | 'location' | 'grades' | 'periods'>
      & { metadata?: Maybe<(
        { __typename?: 'ClassLinkMetadata' }
        & Pick<ClassLinkMetadata, 'courseName' | 'schoolName' | 'teacherName' | 'numberOfEnrollments'>
      )> }
    )>>>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'ClIntegrationNotReadyYetProblem' }
      & Pick<ClIntegrationNotReadyYetProblem, 'message'>
    ) | { __typename: 'ClassLinkClass' } | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetClassRoomsNameQueryVariables = Exact<{
  input: ClassroomsInput;
}>;


export type GetClassRoomsNameQuery = (
  { __typename?: 'Query' }
  & { classrooms?: Maybe<(
    { __typename?: 'ClassroomsGetPayload' }
    & { classroom?: Maybe<Array<Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id' | 'name' | 'from'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | { __typename?: 'ClassroomsDoNotExistProblem' } | (
      { __typename?: 'CollectionSchoolDoesNotExistProblem' }
      & Pick<CollectionSchoolDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetClassRoomsQueryVariables = Exact<{
  input: ClassroomsInput;
}>;


export type GetClassRoomsQuery = (
  { __typename?: 'Query' }
  & { classrooms?: Maybe<(
    { __typename?: 'ClassroomsGetPayload' }
    & Pick<ClassroomsGetPayload, 'nextPage'>
    & { classroom?: Maybe<Array<Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id' | 'districtId' | 'name' | 'description' | 'schoolId' | 'quota' | 'tierId' | 'imageId' | 'remoteApp' | 'from' | 'hasLicense'>
      & { licenses?: Maybe<Array<Maybe<(
        { __typename?: 'LicenseShortOutput' }
        & Pick<LicenseShortOutput, 'from' | 'key' | 'value'>
      )>>> }
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | { __typename?: 'ClassroomsDoNotExistProblem' } | (
      { __typename?: 'CollectionSchoolDoesNotExistProblem' }
      & Pick<CollectionSchoolDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetClassRoomAssigmentQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  classId: Scalars['String'];
}>;


export type GetClassRoomAssigmentQuery = (
  { __typename?: 'Query' }
  & { classroom?: Maybe<(
    { __typename?: 'ClassroomGetPayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'districtId' | 'schoolId' | 'id' | 'studentGroup' | 'studentGroupId' | 'instructorGroup' | 'instructorGroupId' | 'courseId' | 'courseName' | 'azureAdInstructorGroupId' | 'azureAdInstructorGroupName' | 'azureAdGroupId' | 'azureAdGroupName' | 'syncStatus'>
      & { directAssignments?: Maybe<Array<Maybe<(
        { __typename?: 'DirectAssignment' }
        & Pick<DirectAssignment, 'identifier' | 'type'>
      )>>>, remoteMembers?: Maybe<Array<Maybe<(
        { __typename?: 'RemoteMember' }
        & Pick<RemoteMember, 'identifier' | 'name' | 'source' | 'membersType'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetClassroomStatusQueryVariables = Exact<{
  input: ClassroomsInput;
}>;


export type GetClassroomStatusQuery = (
  { __typename?: 'Query' }
  & { classrooms?: Maybe<(
    { __typename?: 'ClassroomsGetPayload' }
    & { classroom?: Maybe<Array<Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id' | 'startInstances'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | { __typename?: 'ClassroomsDoNotExistProblem' } | (
      { __typename?: 'CollectionSchoolDoesNotExistProblem' }
      & Pick<CollectionSchoolDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetClassRoomDataQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  classId: Scalars['String'];
}>;


export type GetClassRoomDataQuery = (
  { __typename?: 'Query' }
  & { classroom?: Maybe<(
    { __typename?: 'ClassroomGetPayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'districtId' | 'schoolId' | 'id' | 'name' | 'description' | 'lastSync' | 'imageId' | 'remoteApp' | 'from' | 'hasLicense' | 'studentGroup' | 'studentGroupId' | 'instructorGroup' | 'instructorGroupId' | 'azureAdInstructorGroupId' | 'azureAdInstructorGroupName' | 'azureAdGroupId' | 'azureAdGroupName' | 'courseId' | 'courseName' | 'syncStatus' | 'cleverSchoolId'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'icon' | 'description' | 'latestBuild' | 'includedApplications'>
        & { tag?: Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'color' | 'label'>
        )>, licenses?: Maybe<Array<Maybe<(
          { __typename?: 'License' }
          & Pick<License, 'key' | 'required' | 'type' | 'mask' | 'regularExpression' | 'name' | 'description' | 'placeholder' | 'infoUrl' | 'title' | 'label'>
        )>>> }
      )>, licenses?: Maybe<Array<Maybe<(
        { __typename?: 'LicenseShortOutput' }
        & Pick<LicenseShortOutput, 'from' | 'key' | 'value'>
      )>>>, applications?: Maybe<Array<Maybe<(
        { __typename?: 'ApplicationShort' }
        & Pick<ApplicationShort, 'id' | 'displayName' | 'hasLicense' | 'licenseKey'>
      )>>>, instructors?: Maybe<Array<Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name' | 'email'>
      )>>>, directAssignments?: Maybe<Array<Maybe<(
        { __typename?: 'DirectAssignment' }
        & Pick<DirectAssignment, 'identifier' | 'type'>
      )>>>, remoteMembers?: Maybe<Array<Maybe<(
        { __typename?: 'RemoteMember' }
        & Pick<RemoteMember, 'identifier' | 'name' | 'source' | 'membersType'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetClassRoomQuotaDataQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  classId: Scalars['String'];
}>;


export type GetClassRoomQuotaDataQuery = (
  { __typename?: 'Query' }
  & { classroom?: Maybe<(
    { __typename?: 'ClassroomGetPayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'districtId' | 'schoolId' | 'id' | 'quota' | 'exceptions'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type CreateClassroomMutationVariables = Exact<{
  input?: Maybe<ClassroomInput>;
}>;


export type CreateClassroomMutation = (
  { __typename?: 'Mutation' }
  & { createClassroom?: Maybe<(
    { __typename?: 'ClassroomCreatePayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id' | 'name'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'CollectionAlreadyExistProblem' }
      & Pick<CollectionAlreadyExistProblem, 'message'>
    ) | { __typename?: 'CreateClassroomProblem' } | (
      { __typename?: 'DeploymentDoesNotExistProblem' }
      & Pick<DeploymentDoesNotExistProblem, 'message'>
    ) | { __typename?: 'ImageDoesNotExistProblem' } | (
      { __typename?: 'IntegrationNotReadyYetProblem' }
      & Pick<IntegrationNotReadyYetProblem, 'message'>
    ) | { __typename?: 'MachineSizeInvalidImageProblem' } | { __typename?: 'MachineSizeNotExistProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'RequiredFieldProblem' } | { __typename?: 'RequiredLicenseNotFoundProblem' } | { __typename?: 'SyncCoursesProblem' } | { __typename?: 'SyncInstructorsProblem' } | { __typename?: 'SyncStudentsProblem' }> }
  )> }
);

export type UpdateClassroomMutationVariables = Exact<{
  classId?: Maybe<Scalars['String']>;
  input?: Maybe<ClassroomUpdateInput>;
}>;


export type UpdateClassroomMutation = (
  { __typename?: 'Mutation' }
  & { updateClassroom?: Maybe<(
    { __typename?: 'ClassroomUpdatePayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'id' | 'name' | 'quota'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Classroom' } | { __typename?: 'CollectionDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'QuotaCanNotBeNegativeProblem' } | { __typename?: 'RepeatStudentsExcludedProblem' }> }
  )> }
);

export type DeleteClassroomMutationVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  agree?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteClassroomMutation = (
  { __typename?: 'Mutation' }
  & { deleteClassroom?: Maybe<(
    { __typename?: 'ClassroomDeletePayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<{ __typename?: 'AgreementNotAcceptedProblem' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ClassUsageQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  classId: Scalars['String'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
}>;


export type ClassUsageQuery = (
  { __typename?: 'Query' }
  & { classUsage?: Maybe<(
    { __typename?: 'ClassUsageGetsPayload' }
    & { classUsage?: Maybe<Array<Maybe<(
      { __typename?: 'ClassUsage' }
      & Pick<ClassUsage, 'name' | 'timeUsage' | 'creditUsage' | 'startDate' | 'endDate'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | { __typename?: 'ClassUsage' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type SyncClassMutationVariables = Exact<{
  classId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
}>;


export type SyncClassMutation = (
  { __typename?: 'Mutation' }
  & { syncClass?: Maybe<(
    { __typename: 'ClassroomSyncPayload' }
    & Pick<ClassroomSyncPayload, 'lastSync'>
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<{ __typename: 'AuthenticationRequiredProblem' } | { __typename: 'ClassroomBooleanResponse' } | { __typename: 'CollectionDoesNotExistProblem' } | { __typename: 'IntegrationNotReadyYetProblem' } | { __typename: 'Problem' } | { __typename: 'SchoolDoesNotExistProblem' }> }
  )> }
);

export type GetClassroomMembersQueryVariables = Exact<{
  classId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type GetClassroomMembersQuery = (
  { __typename?: 'Query' }
  & { classroomMembers?: Maybe<(
    { __typename: 'ClassroomMembersGetPayload' }
    & { members?: Maybe<(
      { __typename: 'ClassroomMembers' }
      & Pick<ClassroomMembers, 'instructors' | 'students'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'ClassroomMembers' } | (
      { __typename: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ClassroomsLicensesCountQueryVariables = Exact<{
  input: ClassroomsCountInput;
}>;


export type ClassroomsLicensesCountQuery = (
  { __typename?: 'Query' }
  & { classroomsLicensesCount?: Maybe<(
    { __typename: 'ClassroomsLicensesCountPayload' }
    & { classroomsCount?: Maybe<(
      { __typename: 'ClassroomsCount' }
      & Pick<ClassroomsCount, 'activatedLicenses' | 'classroomsCount'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'Classroom' } | { __typename: 'ClassroomsDoNotExistProblem' } | { __typename: 'CollectionSchoolDoesNotExistProblem' } | (
      { __typename: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename: 'SchoolDoesNotExistProblem' }> }
  )> }
);

export type ConnectActiveSessionMutationVariables = Exact<{
  input: ConnectSessionInput;
}>;


export type ConnectActiveSessionMutation = (
  { __typename?: 'Mutation' }
  & { connectActiveSession?: Maybe<(
    { __typename?: 'ConnectActiveSessionPayload' }
    & { sessionShared?: Maybe<(
      { __typename?: 'SessionShared' }
      & Pick<SessionShared, 'peerId' | 'customToken'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'Session' } | (
      { __typename: 'SessionAlreadyHasAnAdminProblem' }
      & Pick<SessionAlreadyHasAnAdminProblem, 'message'>
    ) | (
      { __typename: 'SessionDoesNotExistProblem' }
      & Pick<SessionDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'SessionNotReadyYetProblem' }
      & Pick<SessionNotReadyYetProblem, 'message'>
    ) | { __typename?: 'UserAlreadyHasASessionProblem' }> }
  )> }
);

export type CurrentClassroomsSessionsQueryVariables = Exact<{
  input?: Maybe<CurrentSessionInput>;
}>;


export type CurrentClassroomsSessionsQuery = (
  { __typename?: 'Query' }
  & { currentClassroomsSessions?: Maybe<(
    { __typename?: 'CurrentSessionsGetsPayload' }
    & Pick<CurrentSessionsGetsPayload, 'nextPage'>
    & { sessions?: Maybe<Array<Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id' | 'userId' | 'email' | 'name' | 'instanceName' | 'sessionStartAt' | 'deploymentId' | 'districtId' | 'collectionId' | 'collectionName' | 'status' | 'trialSessionTime'>
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotExistProblem' } | { __typename?: 'Session' }> }
  )> }
);

export type PathClassMutationVariables = Exact<{
  input?: Maybe<PatchClassroomInput>;
}>;


export type PathClassMutation = (
  { __typename?: 'Mutation' }
  & { patchClassroom?: Maybe<(
    { __typename?: 'ClassroomPatchPayload' }
    & Pick<ClassroomPatchPayload, 'update'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type ConnectSessionQueryVariables = Exact<{
  peerId?: Maybe<Scalars['String']>;
  classId: Scalars['String'];
  studentEmail: Scalars['String'];
}>;


export type ConnectSessionQuery = (
  { __typename?: 'Query' }
  & { connectSession?: Maybe<(
    { __typename: 'ConnectSessionGetPayload' }
    & { session?: Maybe<(
      { __typename?: 'ConnectSession' }
      & Pick<ConnectSession, 'gatewayUrl' | 'peerId' | 'serverToken'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'ConnectSession' } | (
      { __typename: 'PermissionDoesNotExistProblem' }
      & Pick<PermissionDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename: 'SessionDoesNotExistProblem' }
      & Pick<SessionDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'SessionPeerIdDoesNotExistProblem' }
      & Pick<SessionPeerIdDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type CreateResourceOrderMutationVariables = Exact<{
  input?: Maybe<ManageResourceOrderInput>;
}>;


export type CreateResourceOrderMutation = (
  { __typename?: 'Mutation' }
  & { manageResourceOrder?: Maybe<(
    { __typename?: 'ManageResourceOrderPayload' }
    & { resourceOrder?: Maybe<(
      { __typename?: 'ResourceOrder' }
      & Pick<ResourceOrder, 'resourceIds' | 'resourceType' | 'userEmail' | 'deploymentId' | 'organizationId'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' } | { __typename?: 'RepeatResourcesProblem' }> }
  )> }
);

export type ResourceOrdersQueryVariables = Exact<{
  input: ResourceOrdersInput;
}>;


export type ResourceOrdersQuery = (
  { __typename?: 'Query' }
  & { resourceOrders?: Maybe<(
    { __typename?: 'ResourceOrdersPayload' }
    & { order?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceOrder' }
      & Pick<ResourceOrder, 'resourceIds' | 'resourceType' | 'userEmail' | 'deploymentId' | 'organizationId'>
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type CleverSectionsQueryVariables = Exact<{
  districtId: Scalars['String'];
  cleverSchoolId: Scalars['String'];
}>;


export type CleverSectionsQuery = (
  { __typename?: 'Query' }
  & { cleverSections?: Maybe<(
    { __typename?: 'CleverSectionsGetsPayload' }
    & { sections?: Maybe<Array<Maybe<(
      { __typename?: 'CleverSection' }
      & Pick<CleverSection, 'id' | 'name' | 'sisId' | 'created' | 'lastModified' | 'grade' | 'period' | 'sectionNumber' | 'subject' | 'teachersCount' | 'studentsCount'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'CleverIntegrationNotReadyYetProblem' }
      & Pick<CleverIntegrationNotReadyYetProblem, 'message'>
    ) | { __typename?: 'CleverSection' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type CleverSchoolsQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type CleverSchoolsQuery = (
  { __typename?: 'Query' }
  & { cleverSchools?: Maybe<(
    { __typename?: 'CleverSchoolsGetsPayload' }
    & { schools?: Maybe<Array<Maybe<(
      { __typename?: 'CleverSchool' }
      & Pick<CleverSchool, 'id' | 'name'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'CleverIntegrationNotReadyYetProblem' }
      & Pick<CleverIntegrationNotReadyYetProblem, 'message'>
    ) | { __typename?: 'CleverSchool' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetCoursesQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  byTeacher?: Maybe<Scalars['Boolean']>;
}>;


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { courses?: Maybe<(
    { __typename?: 'CoursesGetPayload' }
    & Pick<CoursesGetPayload, 'pageToken'>
    & { courses?: Maybe<Array<Maybe<(
      { __typename?: 'Course' }
      & Pick<Course, 'id' | 'name'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Course' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'IntegrationNotReadyYetProblem' }
      & Pick<IntegrationNotReadyYetProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type AddCourseMutationVariables = Exact<{
  input?: Maybe<CourseInput>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
}>;


export type AddCourseMutation = (
  { __typename?: 'Mutation' }
  & { addClassCourses?: Maybe<(
    { __typename?: 'CoursesSyncPayload' }
    & Pick<CoursesSyncPayload, 'lastSync'>
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | { __typename?: 'CollectionDoesNotExistProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SchoolDoesNotExistProblem' } | (
      { __typename?: 'SyncCoursesProblem' }
      & Pick<SyncCoursesProblem, 'message'>
    )> }
  )> }
);

export type GetCreditsQueryVariables = Exact<{
  districtId: Scalars['ID'];
}>;


export type GetCreditsQuery = (
  { __typename?: 'Query' }
  & { credits?: Maybe<(
    { __typename?: 'CreditGetPayload' }
    & { credits?: Maybe<Array<Maybe<(
      { __typename?: 'Credit' }
      & Pick<Credit, 'id' | 'name' | 'credits' | 'district' | 'referenceId'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Credit' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetTotalCreditsQueryVariables = Exact<{
  input: DistrictGetInput;
}>;


export type GetTotalCreditsQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'DistrictGetPayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetDistrictDataQueryVariables = Exact<{
  input: DistrictGetInput;
}>;


export type GetDistrictDataQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'DistrictGetPayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'contactName' | 'contactLastName' | 'contactEmail' | 'billingEmail' | 'region' | 'billingAddress' | 'totalCredits' | 'status'>
      & { schools?: Maybe<Array<Maybe<(
        { __typename?: 'SchoolShort' }
        & Pick<SchoolShort, 'schoolId' | 'name' | 'resourceType' | 'role'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetDistrictInfoQueryVariables = Exact<{
  input: DistrictGetInput;
}>;


export type GetDistrictInfoQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'DistrictGetPayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'contactName' | 'contactEmail' | 'externalId' | 'billingEmail' | 'region' | 'billingAddress' | 'totalCredits' | 'isSchool' | 'hasIntegration' | 'integrationProvider' | 'status' | 'studentsNotification' | 'teachersNotification'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type StudentNotificationQueryVariables = Exact<{
  input: DistrictGetInput;
}>;


export type StudentNotificationQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'DistrictGetPayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'studentsNotification' | 'teachersNotification'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type PatchDistrictMutationVariables = Exact<{
  input: PatchDistrictInput;
}>;


export type PatchDistrictMutation = (
  { __typename?: 'Mutation' }
  & { patchDistrict?: Maybe<(
    { __typename?: 'DistrictPatchedPayload' }
    & Pick<DistrictPatchedPayload, 'patched'>
    & { problem?: Maybe<{ __typename: 'AuthenticationRequiredProblem' } | { __typename: 'DistrictDoesNotExistProblem' } | { __typename: 'Problem' }> }
  )> }
);

export type GetBillingSubscriptionQueryVariables = Exact<{
  districtId: Scalars['String'];
  externalId: Scalars['Int'];
}>;


export type GetBillingSubscriptionQuery = (
  { __typename?: 'Query' }
  & { getBillingSubscription?: Maybe<(
    { __typename?: 'SubscriptionGetPayload' }
    & { subscription?: Maybe<(
      { __typename?: 'BillingSubscription' }
      & Pick<BillingSubscription, 'billingPortal' | 'billingAddress'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'BillingSubscription' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SubscriptionDoesNotExistProblem' }
      & Pick<SubscriptionDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type AllowedDomainsByDistrictIdQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type AllowedDomainsByDistrictIdQuery = (
  { __typename?: 'Query' }
  & { allowedDomainsByDistrict?: Maybe<(
    { __typename?: 'AllowedDomainsGetPayload' }
    & { domains?: Maybe<(
      { __typename?: 'Domains' }
      & Pick<Domains, 'count' | 'list'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Domains' } | { __typename?: 'Problem' }> }
  )> }
);

export type UpdateAllowedDomainsMutationVariables = Exact<{
  districtId: Scalars['String'];
  domains?: Maybe<AllowedDomainInput>;
}>;


export type UpdateAllowedDomainsMutation = (
  { __typename?: 'Mutation' }
  & { updateAllowedDomains?: Maybe<(
    { __typename?: 'AllowedDomainsUpdatePayload' }
    & Pick<AllowedDomainsUpdatePayload, 'updated'>
    & { problem?: Maybe<{ __typename?: 'AllowedDomainFormatErrorProblem' } | { __typename?: 'AllowedDomainUpdateProblem' } | { __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Domains' } | { __typename?: 'Problem' }> }
  )> }
);

export type UpdateDistrictMutationVariables = Exact<{
  input: DistrictUpdateInput;
}>;


export type UpdateDistrictMutation = (
  { __typename?: 'Mutation' }
  & { updateDistrict?: Maybe<(
    { __typename?: 'DistrictUpdatePayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'contactName' | 'contactEmail' | 'contactLastName' | 'billingEmail' | 'billingAddress' | 'totalCredits' | 'isSchool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type StudentClassroomGatewayQueryVariables = Exact<{
  userId: Scalars['String'];
  classId: Scalars['String'];
}>;


export type StudentClassroomGatewayQuery = (
  { __typename?: 'Query' }
  & { studentClassroomGateway?: Maybe<(
    { __typename?: 'StudentClassroomGatewayGetPayload' }
    & Pick<StudentClassroomGatewayGetPayload, 'gatewayUrl'>
    & { problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SessionDoesNotExistProblem' }
      & Pick<SessionDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Student' }> }
  )> }
);

export type GetGroupsByDistrictQueryVariables = Exact<{
  districtId: Scalars['ID'];
  pageSize?: Maybe<Scalars['String']>;
  pageToken?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;


export type GetGroupsByDistrictQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<(
    { __typename?: 'GGroupGetsPayload' }
    & Pick<GGroupGetsPayload, 'pageToken'>
    & { gGroup?: Maybe<Array<Maybe<(
      { __typename?: 'GGroup' }
      & Pick<GGroup, 'name' | 'description' | 'id'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'GGroup' } | (
      { __typename?: 'IntegrationNotReadyYetProblem' }
      & Pick<IntegrationNotReadyYetProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetImagesQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  hasLicense?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type GetImagesQuery = (
  { __typename?: 'Query' }
  & { images?: Maybe<(
    { __typename: 'ImagesGetPayload' }
    & { images?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'description' | 'icon' | 'includedApplications' | 'isPublic' | 'latestBuild'>
      & { tag?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'label' | 'color'>
      )>, licenses?: Maybe<Array<Maybe<(
        { __typename?: 'License' }
        & Pick<License, 'key' | 'required' | 'type' | 'mask' | 'regularExpression' | 'name' | 'applications' | 'description' | 'placeholder' | 'infoUrl' | 'title' | 'label'>
      )>>> }
    )>>>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'Classroom' } | (
      { __typename: 'ImageDoesNotExistProblem' }
      & Pick<ImageDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetImageByIdQueryVariables = Exact<{
  imageId?: Maybe<Scalars['String']>;
}>;


export type GetImageByIdQuery = (
  { __typename?: 'Query' }
  & { image?: Maybe<(
    { __typename?: 'ImageGetPayload' }
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'icon'>
      & { tag?: Maybe<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'label' | 'color'>
      )> }
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename: 'ImageDoesNotExistProblem' }
      & Pick<ImageDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetIntegrationWizardQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
}>;


export type GetIntegrationWizardQuery = (
  { __typename?: 'Query' }
  & { integration?: Maybe<(
    { __typename?: 'IntegrationGetPayload' }
    & { integration?: Maybe<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'signInPage' | 'signOutPage' | 'certificateUrl' | 'networkMask' | 'districtId' | 'sso'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Integration' } | (
      { __typename?: 'IntegrationCouldNotGetProblem' }
      & Pick<IntegrationCouldNotGetProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetIntegrationWizardV2QueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
}>;


export type GetIntegrationWizardV2Query = (
  { __typename?: 'Query' }
  & { integration?: Maybe<(
    { __typename?: 'IntegrationGetPayload' }
    & { integration?: Maybe<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'signInPage' | 'signOutPage' | 'certificateUrl' | 'networkMask' | 'districtId'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Integration' } | (
      { __typename?: 'IntegrationCouldNotGetProblem' }
      & Pick<IntegrationCouldNotGetProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type IntegrationsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type IntegrationsQuery = (
  { __typename?: 'Query' }
  & { integrations?: Maybe<(
    { __typename?: 'IntegrationsGetPayload' }
    & Pick<IntegrationsGetPayload, 'integrations'>
    & { problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetConfigurationQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type GetConfigurationQuery = (
  { __typename?: 'Query' }
  & { configurations?: Maybe<(
    { __typename?: 'ConfigurationsGetPayload' }
    & Pick<ConfigurationsGetPayload, 'configurations'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type CreateGoogleIntegrationMutationVariables = Exact<{
  input?: Maybe<GoogleIntegrationInput>;
}>;


export type CreateGoogleIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createGoogleIntegration?: Maybe<(
    { __typename?: 'IntegrationCreatePayload' }
    & { integration?: Maybe<(
      { __typename?: 'BaseIntegration' }
      & Pick<BaseIntegration, 'id' | 'resourceId' | 'resourceType' | 'resourceName' | 'name' | 'integrationType' | 'status' | 'errorMessage'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'IntegrationClientIdExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type CreateTenantIntegrationMutationVariables = Exact<{
  input?: Maybe<TenantIntegrationInput>;
}>;


export type CreateTenantIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createTenantIntegration?: Maybe<(
    { __typename?: 'IntegrationCreatePayload' }
    & { integration?: Maybe<(
      { __typename?: 'BaseIntegration' }
      & Pick<BaseIntegration, 'id' | 'resourceId' | 'resourceType' | 'resourceName' | 'name' | 'integrationType' | 'status' | 'errorMessage'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'IntegrationClientIdExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type CreateLtiIntegrationMutationVariables = Exact<{
  input?: Maybe<LtiIntegrationInput>;
}>;


export type CreateLtiIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { createLtiIntegration?: Maybe<(
    { __typename?: 'IntegrationCreatePayload' }
    & { integration?: Maybe<(
      { __typename?: 'BaseIntegration' }
      & Pick<BaseIntegration, 'id' | 'resourceId' | 'resourceType' | 'resourceName' | 'name' | 'integrationType' | 'status' | 'errorMessage'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'IntegrationClientIdExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type UpdateSsoMutationVariables = Exact<{
  input?: Maybe<SsoInput>;
}>;


export type UpdateSsoMutation = (
  { __typename?: 'Mutation' }
  & { updateSso?: Maybe<(
    { __typename?: 'SsoCreatePayload' }
    & Pick<SsoCreatePayload, 'updateSso'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type SsoQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
}>;


export type SsoQuery = (
  { __typename?: 'Query' }
  & { sso?: Maybe<(
    { __typename?: 'SsoGetPayload' }
    & Pick<SsoGetPayload, 'sso'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type GetInvitationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetInvitationQuery = (
  { __typename?: 'Query' }
  & { invitation?: Maybe<(
    { __typename?: 'InvitationGetPayload' }
    & { adminInvitation?: Maybe<(
      { __typename?: 'AdminInvitation' }
      & Pick<AdminInvitation, 'invitationId' | 'adminId' | 'organizationName' | 'organizationId' | 'token' | 'resources' | 'refererId'>
    )>, problem?: Maybe<{ __typename?: 'AdminInvitation' } | { __typename?: 'AuthenticationRequiredProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'TokenDoesNotExistProblem' }
      & Pick<TokenDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetLicenseDetailsQueryVariables = Exact<{
  districtId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetLicenseDetailsQuery = (
  { __typename?: 'Query' }
  & { licenseDetails?: Maybe<(
    { __typename: 'LicenseDetailsGetPayload' }
    & { licenseDetails?: Maybe<(
      { __typename: 'LicenseDetails' }
      & Pick<LicenseDetails, 'totalPurchased' | 'activated' | 'available' | 'deficit' | 'purchases' | 'startCurrentTerm' | 'endCurrentTerm'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'LicenseDetails' } | { __typename?: 'LicensesDoesNotExistProblem' } | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetDistrictLicensesQueryVariables = Exact<{
  districtId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
}>;


export type GetDistrictLicensesQuery = (
  { __typename?: 'Query' }
  & { districtLicenses?: Maybe<(
    { __typename: 'DistrictLicenseGetsPayload' }
    & { licenses?: Maybe<(
      { __typename: 'DistrictLicensesGetPayload' }
      & Pick<DistrictLicensesGetPayload, 'nextPage'>
      & { licenses?: Maybe<Array<Maybe<(
        { __typename?: 'LicenseDto' }
        & Pick<LicenseDto, 'buyDate' | 'startDate' | 'endDate' | 'license' | 'activatedLicenses' | 'availableLicenses'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'LicenseDto' } | { __typename: 'LicensesDoesNotExistProblem' } | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetLicensedPeriodsQueryVariables = Exact<{
  districtId: Scalars['ID'];
}>;


export type GetLicensedPeriodsQuery = (
  { __typename?: 'Query' }
  & { licensedPeriods?: Maybe<(
    { __typename: 'LicensedPeriodsGetsPayload' }
    & { licensedPeriods?: Maybe<Array<Maybe<(
      { __typename: 'LicensePeriod' }
      & Pick<LicensePeriod, 'districtId' | 'startPeriod' | 'endPeriod'>
    )>>>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'LicensePeriod' } | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetDistrictActivatedLicensesQueryVariables = Exact<{
  districtId: Scalars['ID'];
  districtName: Scalars['String'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  requestEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetDistrictActivatedLicensesQuery = (
  { __typename?: 'Query' }
  & { districtActivatedLicenses?: Maybe<(
    { __typename: 'DistrictActivatedLicensesGetPayload' }
    & { activatedBool?: Maybe<(
      { __typename?: 'ActivatedLicensesBoolean' }
      & Pick<ActivatedLicensesBoolean, 'activatedBool'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename: 'LicenseDto' } | (
      { __typename: 'LicensesDoesNotExistProblem' }
      & Pick<LicensesDoesNotExistProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ResourceLicensesQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type ResourceLicensesQuery = (
  { __typename?: 'Query' }
  & { resourceLicenses?: Maybe<(
    { __typename?: 'ResourceLicensesGetsPayload' }
    & Pick<ResourceLicensesGetsPayload, 'nextPage'>
    & { licenses?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceLicense' }
      & Pick<ResourceLicense, 'resourceId' | 'resourceName' | 'resourceType' | 'id' | 'imageId' | 'imageName'>
      & { license?: Maybe<(
        { __typename?: 'LicenseShort' }
        & Pick<LicenseShort, 'key' | 'value' | 'displayName'>
      )> }
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' } | { __typename?: 'ResourceLicense' }> }
  )> }
);

export type AddResourceLicenseMutationVariables = Exact<{
  input: ResourceLicenseInput;
}>;


export type AddResourceLicenseMutation = (
  { __typename?: 'Mutation' }
  & { addResourceLicense?: Maybe<(
    { __typename?: 'ResourceLicenseCreatePayload' }
    & { resourceLicense?: Maybe<(
      { __typename?: 'ResourceLicense' }
      & Pick<ResourceLicense, 'resourceId' | 'resourceName' | 'resourceType' | 'imageId' | 'imageName'>
      & { license?: Maybe<(
        { __typename?: 'LicenseShort' }
        & Pick<LicenseShort, 'key' | 'value' | 'displayName'>
      )> }
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'ResourceLicense' }> }
  )> }
);

export type DeleteResourceLicensesMutationVariables = Exact<{
  input: DeleteResourcedLicensesInput;
}>;


export type DeleteResourceLicensesMutation = (
  { __typename?: 'Mutation' }
  & { deleteResourceLicenses?: Maybe<(
    { __typename?: 'ResourceLicensesDeletePayload' }
    & { licensebool?: Maybe<(
      { __typename?: 'LicenceBooleanResponse' }
      & Pick<LicenceBooleanResponse, 'licensebool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'LicenceBooleanResponse' } | (
      { __typename?: 'LicensesDeleteProblem' }
      & Pick<LicensesDeleteProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ResourceLicenseValueQueryVariables = Exact<{
  id: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type ResourceLicenseValueQuery = (
  { __typename?: 'Query' }
  & { resourceLicenseValue?: Maybe<(
    { __typename?: 'ResourceLicenseValueGetPayload' }
    & Pick<ResourceLicenseValueGetPayload, 'value'>
    & { problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'ResourceLicense' }> }
  )> }
);

export type ManageLicenseMutationVariables = Exact<{
  input?: Maybe<ManageLicenseInput>;
}>;


export type ManageLicenseMutation = (
  { __typename?: 'Mutation' }
  & { manageLicense?: Maybe<(
    { __typename?: 'ManageLicensePayload' }
    & { classroom?: Maybe<(
      { __typename?: 'Classroom' }
      & Pick<Classroom, 'districtId' | 'schoolId' | 'id' | 'name' | 'description' | 'lastSync' | 'imageId' | 'remoteApp' | 'from' | 'hasLicense' | 'studentGroup' | 'studentGroupId' | 'instructorGroup' | 'instructorGroupId' | 'azureAdInstructorGroupId' | 'azureAdInstructorGroupName' | 'azureAdGroupId' | 'azureAdGroupName' | 'courseId' | 'courseName'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'icon' | 'description' | 'latestBuild' | 'includedApplications'>
        & { tag?: Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'color' | 'label'>
        )>, licenses?: Maybe<Array<Maybe<(
          { __typename?: 'License' }
          & Pick<License, 'key' | 'required' | 'type' | 'mask' | 'regularExpression' | 'name' | 'description' | 'placeholder' | 'infoUrl' | 'title' | 'label'>
        )>>> }
      )>, licenses?: Maybe<Array<Maybe<(
        { __typename?: 'LicenseShortOutput' }
        & Pick<LicenseShortOutput, 'from' | 'key' | 'value'>
      )>>>, applications?: Maybe<Array<Maybe<(
        { __typename?: 'ApplicationShort' }
        & Pick<ApplicationShort, 'id' | 'displayName' | 'hasLicense' | 'licenseKey'>
      )>>>, instructors?: Maybe<Array<Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name' | 'email'>
      )>>>, directAssignments?: Maybe<Array<Maybe<(
        { __typename?: 'DirectAssignment' }
        & Pick<DirectAssignment, 'identifier' | 'type'>
      )>>>, remoteMembers?: Maybe<Array<Maybe<(
        { __typename?: 'RemoteMember' }
        & Pick<RemoteMember, 'identifier' | 'name' | 'source' | 'membersType'>
      )>>> }
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Classroom' } | { __typename?: 'CollectionDoesNotExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type NetworkStatisticsQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
}>;


export type NetworkStatisticsQuery = (
  { __typename?: 'Query' }
  & { networkStatistics?: Maybe<(
    { __typename?: 'NetworkStatisticsPayload' }
    & { statistics?: Maybe<(
      { __typename?: 'Statistics' }
      & Pick<Statistics, 'healthy' | 'poor' | 'failed'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Problem' } | { __typename?: 'Statistics' }> }
  )> }
);

export type StatisticsDetailsQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  period: Scalars['Int'];
  status?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type StatisticsDetailsQuery = (
  { __typename?: 'Query' }
  & { statisticsDetails?: Maybe<(
    { __typename?: 'StatisticsDetailsGetsPayload' }
    & Pick<StatisticsDetailsGetsPayload, 'nextPage'>
    & { statisticsDetails?: Maybe<Array<Maybe<(
      { __typename?: 'StatisticsDetails' }
      & Pick<StatisticsDetails, 'createdAt' | 'userName' | 'ipAddress' | 'status' | 'trickleIce' | 'latency' | 'bandwidth' | 'bandwidthUp'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Problem' } | { __typename?: 'StatisticsDetails' }> }
  )> }
);

export type ConnectionInfoMutationVariables = Exact<{
  connectionInput: ConnectionInfoInput;
}>;


export type ConnectionInfoMutation = (
  { __typename?: 'Mutation' }
  & { connectionInfo?: Maybe<(
    { __typename?: 'ConnectionInfoPayload' }
    & { studentbool?: Maybe<(
      { __typename?: 'StudentBooleanResponse' }
      & Pick<StudentBooleanResponse, 'studentbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'IpConfigNotFoundProblem' }
      & Pick<IpConfigNotFoundProblem, 'message'>
    ) | { __typename?: 'Problem' } | { __typename?: 'Student' }> }
  )> }
);

export type NearestRegionQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type NearestRegionQuery = (
  { __typename?: 'Query' }
  & { nearestRegion?: Maybe<(
    { __typename?: 'RegionsGetsPayload' }
    & { regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'name' | 'networkDiagnosticUrl' | 'turnWebUrl'>
    )>>>, problem?: Maybe<{ __typename: 'AuthenticationRequiredProblem' } | { __typename: 'CannotGetRegionsProblem' } | { __typename: 'Problem' } | { __typename: 'Region' }> }
  )> }
);

export type StudentUsageReportMutationVariables = Exact<{
  input?: Maybe<StudentUsageInput>;
}>;


export type StudentUsageReportMutation = (
  { __typename?: 'Mutation' }
  & { studentUsageReport?: Maybe<(
    { __typename: 'StudentUsageReportPayload' }
    & Pick<StudentUsageReportPayload, 'nextPage'>
    & { studentUsage?: Maybe<Array<Maybe<(
      { __typename: 'StudentUsageReport' }
      & Pick<StudentUsageReport, 'username' | 'duration' | 'className' | 'totalDuration' | 'startDate' | 'endDate'>
    )>>>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename: 'StudentUsageReport' }> }
  )> }
);

export type FullStudentUsageReportMutationVariables = Exact<{
  input?: Maybe<StudentUsageInput>;
}>;


export type FullStudentUsageReportMutation = (
  { __typename?: 'Mutation' }
  & { fullStudentUsageReport?: Maybe<(
    { __typename?: 'FullStudentUsageReportPayload' }
    & { studentUsage?: Maybe<Array<Maybe<(
      { __typename?: 'FullStudentUsageReport' }
      & Pick<FullStudentUsageReport, 'username' | 'duration' | 'collectionName' | 'startDate' | 'endDate'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'StudentUsageReport' }> }
  )> }
);

export type StudentHistoryReportQueryVariables = Exact<{
  input?: Maybe<StudentHistoryInput>;
}>;


export type StudentHistoryReportQuery = (
  { __typename?: 'Query' }
  & { studentHistoryReport?: Maybe<(
    { __typename?: 'StudentHistoryReportGetsPayload' }
    & Pick<StudentHistoryReportGetsPayload, 'nextPage'>
    & { studentUsage?: Maybe<Array<Maybe<(
      { __typename?: 'StudentHistoryReport' }
      & Pick<StudentHistoryReport, 'username' | 'className' | 'duration' | 'totalDuration' | 'startDate' | 'endDate' | 'schoolName'>
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'StudentHistoryReport' }> }
  )> }
);

export type GetResourceHubsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourceHubsQuery = (
  { __typename?: 'Query' }
  & { resourceHubs?: Maybe<(
    { __typename: 'ResourceHubsGetPayload' }
    & { resourceHubs?: Maybe<Array<Maybe<(
      { __typename: 'ResourceHub' }
      & Pick<ResourceHub, 'id' | 'name' | 'description' | 'image' | 'icon'>
    )>>>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'ResourceHub' }> }
  )> }
);

export type StudentClassroomsQueryVariables = Exact<{
  userName: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type StudentClassroomsQuery = (
  { __typename?: 'Query' }
  & { studentClassrooms?: Maybe<(
    { __typename?: 'StudentClassroomsGetPayload' }
    & { collections?: Maybe<Array<Maybe<(
      { __typename?: 'StudentCollection' }
      & Pick<StudentCollection, 'userId' | 'districtId' | 'districtName' | 'schoolId' | 'schoolName' | 'classId' | 'className'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'CollectionsDoNotExistProblem' }
      & Pick<CollectionsDoNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'StudentCollection' }> }
  )> }
);

export type GetCollectionByResourceHubQueryVariables = Exact<{
  resourceHubId: Scalars['String'];
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
}>;


export type GetCollectionByResourceHubQuery = (
  { __typename?: 'Query' }
  & { collectionsByResourceHub?: Maybe<(
    { __typename?: 'ClassroomsOutputGetPayload' }
    & Pick<ClassroomsOutputGetPayload, 'nextPage'>
    & { classroom?: Maybe<Array<Maybe<(
      { __typename?: 'ClassroomResource' }
      & Pick<ClassroomResource, 'id' | 'name' | 'imageId' | 'description' | 'favorite'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'latestBuild'>
        & { licenses?: Maybe<Array<Maybe<(
          { __typename?: 'License' }
          & Pick<License, 'title'>
        )>>> }
      )>, applications?: Maybe<Array<Maybe<(
        { __typename?: 'ApplicationShort' }
        & Pick<ApplicationShort, 'displayName' | 'id' | 'icon'>
      )>>> }
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'ClassroomsDoNotExistProblem' }
      & Pick<ClassroomsDoNotExistProblem, 'message'>
    ) | (
      { __typename?: 'CollectionSchoolDoesNotExistProblem' }
      & Pick<CollectionSchoolDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type CreateResourceSessionMutationVariables = Exact<{
  input: ResourceSessionInput;
}>;


export type CreateResourceSessionMutation = (
  { __typename?: 'Mutation' }
  & { createResourceSession?: Maybe<(
    { __typename?: 'StudentSessionPayload' }
    & Pick<StudentSessionPayload, 'start'>
    & { problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassDoesNotExistProblem' } | (
      { __typename?: 'CouldNotCreateSessionProblem' }
      & Pick<CouldNotCreateSessionProblem, 'message'>
    ) | (
      { __typename?: 'PermissionDoesNotExistProblem' }
      & Pick<PermissionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotHaveEnoughCreditsProblem' }
      & Pick<SchoolDoesNotHaveEnoughCreditsProblem, 'message'>
    ) | { __typename?: 'Student' } | (
      { __typename?: 'UserAlreadyHasASessionProblem' }
      & Pick<UserAlreadyHasASessionProblem, 'message'>
    ) | (
      { __typename?: 'UserExceededQuotaPerWeekProblem' }
      & Pick<UserExceededQuotaPerWeekProblem, 'message'>
    ) | (
      { __typename?: 'UserInCollectionDoesNotExistProblem' }
      & Pick<UserInCollectionDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetRecentResourceHubsQueryVariables = Exact<{
  userEmail: Scalars['String'];
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
}>;


export type GetRecentResourceHubsQuery = (
  { __typename?: 'Query' }
  & { recentResourceHubs?: Maybe<(
    { __typename?: 'ClassroomsOutputGetPayload' }
    & Pick<ClassroomsOutputGetPayload, 'nextPage'>
    & { classroom?: Maybe<Array<Maybe<(
      { __typename?: 'ClassroomResource' }
      & Pick<ClassroomResource, 'id' | 'name' | 'imageId' | 'description' | 'favorite' | 'resourceHubIds'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'latestBuild'>
        & { licenses?: Maybe<Array<Maybe<(
          { __typename?: 'License' }
          & Pick<License, 'title'>
        )>>> }
      )>, applications?: Maybe<Array<Maybe<(
        { __typename?: 'ApplicationShort' }
        & Pick<ApplicationShort, 'displayName' | 'id' | 'icon'>
      )>>> }
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'ClassroomsDoNotExistProblem' }
      & Pick<ClassroomsDoNotExistProblem, 'message'>
    ) | (
      { __typename?: 'CollectionSchoolDoesNotExistProblem' }
      & Pick<CollectionSchoolDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type SetBookMarkMutationVariables = Exact<{
  input: SetBookmarkInput;
}>;


export type SetBookMarkMutation = (
  { __typename?: 'Mutation' }
  & { setBookmark?: Maybe<(
    { __typename?: 'SetBookmarkPayload' }
    & Pick<SetBookmarkPayload, 'setBookmark'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type RestartIStreamerMutationVariables = Exact<{
  input: RestartIStreamerInput;
}>;


export type RestartIStreamerMutation = (
  { __typename?: 'Mutation' }
  & { restartIStreamer?: Maybe<(
    { __typename: 'RestartIStreamerPayload' }
    & Pick<RestartIStreamerPayload, 'restart'>
    & { problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type StartSessionsScheduleQueryVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  classId: Scalars['String'];
}>;


export type StartSessionsScheduleQuery = (
  { __typename?: 'Query' }
  & { startSessionsSchedule?: Maybe<(
    { __typename?: 'StartSessionSchedulePayload' }
    & { schedules?: Maybe<Array<Maybe<(
      { __typename?: 'StartSessionsSchedule' }
      & Pick<StartSessionsSchedule, 'allStudents' | 'timeZone' | 'name' | 'id' | 'haveSessionDisabled'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'StartSessionUser' }
        & Pick<StartSessionUser, 'name' | 'email' | 'haveSessionDisabled'>
      )>>>, frequencies?: Maybe<Array<Maybe<(
        { __typename?: 'StartSessionFrequency' }
        & Pick<StartSessionFrequency, 'startDates' | 'frequency' | 'description' | 'endDates'>
      )>>> }
    )>>>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'CannotGetStartSessionScheduleProblem' } | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotExistProblem' } | { __typename?: 'StartSessionsSchedule' } | { __typename?: 'UserDoesNotExitsProblem' }> }
  )> }
);

export type AddScheduleMutationVariables = Exact<{
  input?: Maybe<ScheduleStartSessionsInput>;
}>;


export type AddScheduleMutation = (
  { __typename?: 'Mutation' }
  & { scheduleStartSessions?: Maybe<(
    { __typename?: 'ScheduleSessionsPayload' }
    & Pick<ScheduleSessionsPayload, 'schedulerId'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'CannotObtainConsumeProblem' } | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'ScheduleStartSessionErrorProblem' } | { __typename?: 'SchoolDoesNotExistProblem' } | { __typename?: 'SchoolDoesNotHaveEnoughCreditsProblem' } | { __typename?: 'StartSessionsSchedule' } | { __typename?: 'UserDoesNotExitsProblem' } | { __typename?: 'UserExceededQuotaPerWeekProblem' }> }
  )> }
);

export type DeleteScheduleMutationVariables = Exact<{
  scheduleId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteScheduleStartSession?: Maybe<(
    { __typename?: 'StartSessionScheduleDeletePayload' }
    & { scheduleBool?: Maybe<(
      { __typename?: 'StartSessionScheduleDeleteBoolean' }
      & Pick<StartSessionScheduleDeleteBoolean, 'scheduleBool'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' } | { __typename?: 'StartSessionScheduleDeleteProblem' } | { __typename?: 'StartSessionsSchedule' }> }
  )> }
);

export type EnableSchedulerSessionByClassMutationVariables = Exact<{
  classId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type EnableSchedulerSessionByClassMutation = (
  { __typename?: 'Mutation' }
  & { enableSchedulerSessionByClass?: Maybe<(
    { __typename?: 'EnableSchedulerSessionByClassPayload' }
    & Pick<EnableSchedulerSessionByClassPayload, 'studentsWithProblem'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type HaveSchedulerSessionDisabledQueryVariables = Exact<{
  classId?: Maybe<Scalars['String']>;
}>;


export type HaveSchedulerSessionDisabledQuery = (
  { __typename?: 'Query' }
  & { haveSchedulerSessionDisabled?: Maybe<(
    { __typename?: 'HaveSchedulerSessionDisabledPayload' }
    & Pick<HaveSchedulerSessionDisabledPayload, 'have'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type GetSchoolsByDistrictQueryVariables = Exact<{
  input: SchoolsInput;
}>;


export type GetSchoolsByDistrictQuery = (
  { __typename?: 'Query' }
  & { schools?: Maybe<(
    { __typename?: 'SchoolsGetPayload' }
    & { school?: Maybe<Array<Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name' | 'districtId' | 'blockTeacherAccess'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DeploymentDoesNotExistProblem' }
      & Pick<DeploymentDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'School' } | (
      { __typename?: 'SchoolsDoNotExistProblem' }
      & Pick<SchoolsDoNotExistProblem, 'message'>
    )> }
  )> }
);

export type GetSchoolLicencesByDistrictQueryVariables = Exact<{
  districtId: Scalars['String'];
}>;


export type GetSchoolLicencesByDistrictQuery = (
  { __typename?: 'Query' }
  & { schoolsLicenses?: Maybe<(
    { __typename?: 'SchoolsLicensesPayload' }
    & { licenses?: Maybe<(
      { __typename?: 'SchoolsLicenses' }
      & Pick<SchoolsLicenses, 'totalPurchased' | 'totalActivated' | 'totalAvailable' | 'startCurrentTerm' | 'endCurrentTerm'>
      & { schools?: Maybe<Array<Maybe<(
        { __typename?: 'SchoolLicense' }
        & Pick<SchoolLicense, 'schoolId' | 'schoolName' | 'activatedLicenses'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'LicensesDoesNotExistProblem' }
      & Pick<LicensesDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SchoolsLicenses' }> }
  )> }
);

export type GetSchoolsSelectQueryVariables = Exact<{
  input: SchoolsInput;
}>;


export type GetSchoolsSelectQuery = (
  { __typename?: 'Query' }
  & { schools?: Maybe<(
    { __typename?: 'SchoolsGetPayload' }
    & { school?: Maybe<Array<Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name' | 'districtId'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DeploymentDoesNotExistProblem' }
      & Pick<DeploymentDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'School' } | (
      { __typename?: 'SchoolsDoNotExistProblem' }
      & Pick<SchoolsDoNotExistProblem, 'message'>
    )> }
  )> }
);

export type AllowedDomainsBySchoolQueryVariables = Exact<{
  schoolId: Scalars['String'];
  districtId?: Maybe<Scalars['String']>;
}>;


export type AllowedDomainsBySchoolQuery = (
  { __typename?: 'Query' }
  & { allowedDomainsBySchool?: Maybe<(
    { __typename?: 'AllowedDomainsGetPayload' }
    & { domains?: Maybe<(
      { __typename?: 'Domains' }
      & Pick<Domains, 'count' | 'list'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Domains' } | { __typename?: 'Problem' }> }
  )> }
);

export type CreateSchoolMutationVariables = Exact<{
  input?: Maybe<SchoolInput>;
}>;


export type CreateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createSchool?: Maybe<(
    { __typename?: 'SchoolCreatePayload' }
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'CreateSchoolProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'RegionDoesNotExistProblem' } | { __typename?: 'School' } | { __typename?: 'SchoolAlreadyExistProblem' }> }
  )> }
);

export type CreateAllocationForSchoolMutationVariables = Exact<{
  schoolAllocation?: Maybe<SchoolAllocationInput>;
}>;


export type CreateAllocationForSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createAllocationForSchool?: Maybe<(
    { __typename?: 'SchoolCreatePayload' }
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'CreateSchoolProblem' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'RegionDoesNotExistProblem' } | { __typename?: 'School' } | { __typename?: 'SchoolAlreadyExistProblem' }> }
  )> }
);

export type GetSchoolDataQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetSchoolDataQuery = (
  { __typename?: 'Query' }
  & { school?: Maybe<(
    { __typename?: 'SchoolGetPayload' }
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id'>
      & { district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'id' | 'totalCredits'>
      )> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DeploymentDoesNotExistProblem' }
      & Pick<DeploymentDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'School' } | { __typename?: 'SchoolsDoNotExistProblem' }> }
  )> }
);

export type GetSchoolNameQueryVariables = Exact<{
  districtId: Scalars['String'];
  id: Scalars['String'];
}>;


export type GetSchoolNameQuery = (
  { __typename?: 'Query' }
  & { school?: Maybe<(
    { __typename?: 'SchoolGetPayload' }
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name' | 'createAt'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DeploymentDoesNotExistProblem' }
      & Pick<DeploymentDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'School' } | { __typename?: 'SchoolsDoNotExistProblem' }> }
  )> }
);

export type DeleteSchoolMutationVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type DeleteSchoolMutation = (
  { __typename?: 'Mutation' }
  & { deleteSchool?: Maybe<(
    { __typename?: 'SchoolDeletePayload' }
    & { schoolBool?: Maybe<(
      { __typename?: 'SchoolDeleteBoolean' }
      & Pick<SchoolDeleteBoolean, 'schoolBool'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'CollectionByDeploymentFailedProblem' } | { __typename?: 'DeleteSchoolProblem' } | { __typename?: 'PermissionDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'School' }> }
  )> }
);

export type UpdateAllowedDomainsForSchoolMutationVariables = Exact<{
  districtId: Scalars['String'];
  schoolId: Scalars['String'];
  domains?: Maybe<AllowedDomainInput>;
}>;


export type UpdateAllowedDomainsForSchoolMutation = (
  { __typename?: 'Mutation' }
  & { updateAllowedDomainsForSchool?: Maybe<(
    { __typename?: 'AllowedDomainsForSchoolUpdatePayload' }
    & Pick<AllowedDomainsForSchoolUpdatePayload, 'updated'>
    & { problem?: Maybe<{ __typename?: 'AllowedDomainFormatErrorProblem' } | { __typename?: 'AllowedDomainUpdateProblem' } | { __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Domains' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotExistProblem' }> }
  )> }
);

export type PopularClassesQueryVariables = Exact<{
  input: PopularClassesInput;
}>;


export type PopularClassesQuery = (
  { __typename?: 'Query' }
  & { popularClasses?: Maybe<(
    { __typename?: 'PopularClassesGetsPayload' }
    & { popularClasses?: Maybe<Array<Maybe<(
      { __typename?: 'PopularClasses' }
      & Pick<PopularClasses, 'time' | 'name' | 'id'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'PopularClasses' } | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type TopCollectionsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type TopCollectionsQuery = (
  { __typename?: 'Query' }
  & { topCollections?: Maybe<(
    { __typename?: 'UsageGetsPayload' }
    & { usage?: Maybe<Array<Maybe<(
      { __typename?: 'Usage' }
      & Pick<Usage, 'name' | 'creditUsage' | 'timeUsage'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Usage' }> }
  )> }
);

export type TopImagesQueryVariables = Exact<{
  input?: Maybe<TopImagesInput>;
}>;


export type TopImagesQuery = (
  { __typename?: 'Query' }
  & { topImages?: Maybe<(
    { __typename?: 'UsageGetsPayload' }
    & { usage?: Maybe<Array<Maybe<(
      { __typename?: 'Usage' }
      & Pick<Usage, 'name' | 'creditUsage' | 'timeUsage'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Usage' }> }
  )> }
);

export type MostActiveStudentsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type MostActiveStudentsQuery = (
  { __typename?: 'Query' }
  & { mostActiveStudents?: Maybe<(
    { __typename?: 'StudentUsageGetsPayload' }
    & { studentUsage?: Maybe<Array<Maybe<(
      { __typename?: 'StudentUsage' }
      & Pick<StudentUsage, 'name' | 'email' | 'timeUsage'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'StudentUsage' }> }
  )> }
);

export type GetBillingHistoryQueryVariables = Exact<{
  districtId: Scalars['String'];
  month: Scalars['String'];
  requestEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type GetBillingHistoryQuery = (
  { __typename?: 'Query' }
  & { billingHistory?: Maybe<(
    { __typename?: 'BillingHistoryConnection' }
    & Pick<BillingHistoryConnection, 'nextPage'>
    & { billingHistory?: Maybe<(
      { __typename?: 'BillingHistoryPayload' }
      & Pick<BillingHistoryPayload, 'totalCreditUsage' | 'totalTimeUsage'>
      & { billingHistory?: Maybe<Array<Maybe<(
        { __typename?: 'BillingHistory' }
        & Pick<BillingHistory, 'organizationName' | 'deploymentName' | 'collectionName' | 'userEmail' | 'studentName' | 'instanceName' | 'imageName' | 'machineSizeName' | 'timeUsage' | 'creditUsage' | 'sessionStartAt' | 'sessionEndAt'>
      )>>> }
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'BillingHistory' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'MonthsProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetActivatedLicensesQueryVariables = Exact<{
  input: ActivatedLicensesInput;
}>;


export type GetActivatedLicensesQuery = (
  { __typename?: 'Query' }
  & { activatedLicenses?: Maybe<(
    { __typename?: 'ActivatedLicensesGetPayload' }
    & { activatedLicenses?: Maybe<(
      { __typename?: 'ActivatedLicenseMembers' }
      & Pick<ActivatedLicenseMembers, 'activatedLicenses' | 'inactiveUsers' | 'neverLoggedIn'>
    )>, problem?: Maybe<{ __typename?: 'ActivatedLicenseMembers' } | (
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type ActiveStudentsHistoryQueryVariables = Exact<{
  input?: Maybe<StudentsCountInput>;
}>;


export type ActiveStudentsHistoryQuery = (
  { __typename?: 'Query' }
  & { activeStudentsHistory?: Maybe<(
    { __typename?: 'ActiveStudentsHistoryPayload' }
    & { studentsHistory?: Maybe<Array<Maybe<(
      { __typename?: 'StudentsHistory' }
      & Pick<StudentsHistory, 'studentCount' | 'date'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'StudentsHistory' }> }
  )> }
);

export type RecentUserActivityQueryVariables = Exact<{
  input: RecentUserActivityInput;
}>;


export type RecentUserActivityQuery = (
  { __typename?: 'Query' }
  & { recentUserActivity?: Maybe<(
    { __typename?: 'RecentUserActivityPayload' }
    & { recentUsers?: Maybe<Array<Maybe<(
      { __typename?: 'UserActivityType' }
      & Pick<UserActivityType, 'name' | 'value'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type SessionOriginQueryVariables = Exact<{
  input?: Maybe<SessionOriginInput>;
}>;


export type SessionOriginQuery = (
  { __typename?: 'Query' }
  & { sessionOrigin?: Maybe<(
    { __typename?: 'SessionOriginPayload' }
    & { sessionOrigin?: Maybe<(
      { __typename?: 'SessionOrigin' }
      & Pick<SessionOrigin, 'onCampus' | 'offCampus'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SessionOrigin' }> }
  )> }
);

export type CurrentSchoolSessionsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type CurrentSchoolSessionsQuery = (
  { __typename?: 'Query' }
  & { currentSchoolSessions?: Maybe<(
    { __typename?: 'CurrentSessionsGetsPayload' }
    & { sessions?: Maybe<Array<Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'userId' | 'email' | 'name' | 'collectionId' | 'collectionName' | 'status' | 'instanceName'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassDoesNotExistProblem' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Session' }> }
  )> }
);

export type CurrentClassroomSessionsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type CurrentClassroomSessionsQuery = (
  { __typename?: 'Query' }
  & { currentClassroomSessions?: Maybe<(
    { __typename?: 'CurrentSessionsGetsPayload' }
    & Pick<CurrentSessionsGetsPayload, 'nextPage'>
    & { sessions?: Maybe<Array<Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'userId' | 'email' | 'name' | 'collectionId' | 'collectionName' | 'status' | 'instanceName'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassDoesNotExistProblem' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Session' }> }
  )> }
);

export type ClassStudentsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['String']>;
  pageIndex?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type ClassStudentsQuery = (
  { __typename?: 'Query' }
  & { classStudents?: Maybe<(
    { __typename?: 'StudentListPayload' }
    & Pick<StudentListPayload, 'pageIndex' | 'nextPage' | 'isRosterSync'>
    & { students?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'email' | 'lastLogon'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'DistrictDoesNotExistProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SchoolDoesNotExistProblem' } | { __typename?: 'Student' } | (
      { __typename?: 'StudentCollectionProblem' }
      & Pick<StudentCollectionProblem, 'message'>
    ) | { __typename?: 'UserDoesNotExitsProblem' }> }
  )> }
);

export type StudentsNeverLoginQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
}>;


export type StudentsNeverLoginQuery = (
  { __typename?: 'Query' }
  & { studentsNeverLogin?: Maybe<(
    { __typename?: 'StudentsGetsPayload' }
    & { students?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'email'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Student' } | (
      { __typename?: 'StudentCollectionProblem' }
      & Pick<StudentCollectionProblem, 'message'>
    ) | (
      { __typename?: 'UserDoesNotExitsProblem' }
      & Pick<UserDoesNotExitsProblem, 'message'>
    )> }
  )> }
);

export type StudentsNeverLoginByCollectionsQueryVariables = Exact<{
  input: StudentsNeverLoginByCollectionsInput;
}>;


export type StudentsNeverLoginByCollectionsQuery = (
  { __typename?: 'Query' }
  & { studentsNeverLoginByCollections?: Maybe<(
    { __typename?: 'AllStudentsPayload' }
    & Pick<AllStudentsPayload, 'nextPage'>
    & { list?: Maybe<Array<Maybe<(
      { __typename?: 'StudentClass' }
      & Pick<StudentClass, 'id' | 'name' | 'email' | 'className'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'StudentClass' } | (
      { __typename?: 'UsersNotFoundProblem' }
      & Pick<UsersNotFoundProblem, 'message'>
    )> }
  )> }
);

export type ShutdownSessionMutationVariables = Exact<{
  input?: Maybe<ShutdownSessionInput>;
}>;


export type ShutdownSessionMutation = (
  { __typename?: 'Mutation' }
  & { shutdownSession?: Maybe<(
    { __typename?: 'ShutDownSessionPayload' }
    & { shutDown?: Maybe<(
      { __typename?: 'ShutDownSession' }
      & Pick<ShutDownSession, 'shutDown'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SessionDoesNotExistProblem' }
      & Pick<SessionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'SessionShutdownInstanceProblem' }
      & Pick<SessionShutdownInstanceProblem, 'message'>
    ) | { __typename?: 'ShutDownSession' }> }
  )> }
);

export type ShutdownMultipleSessionsMutationVariables = Exact<{
  input?: Maybe<ShutdownMultipleSessionsInput>;
}>;


export type ShutdownMultipleSessionsMutation = (
  { __typename?: 'Mutation' }
  & { shutdownMultipleSessions?: Maybe<(
    { __typename?: 'ShutDownSessionPayload' }
    & { shutDown?: Maybe<(
      { __typename?: 'ShutDownSession' }
      & Pick<ShutDownSession, 'shutDown'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SessionDoesNotExistProblem' }
      & Pick<SessionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'SessionShutdownInstanceProblem' }
      & Pick<SessionShutdownInstanceProblem, 'message'>
    ) | { __typename?: 'ShutDownSession' }> }
  )> }
);

export type AddClassStudentsMutationVariables = Exact<{
  students?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  studentsNotification?: Maybe<Scalars['Boolean']>;
}>;


export type AddClassStudentsMutation = (
  { __typename?: 'Mutation' }
  & { addClassStudents?: Maybe<(
    { __typename?: 'StudentsSyncPayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | { __typename?: 'CollectionDoesNotExistProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SchoolDoesNotExistProblem' } | (
      { __typename?: 'SyncStudentsProblem' }
      & Pick<SyncStudentsProblem, 'message'>
    )> }
  )> }
);

export type DeleteStudentMutationVariables = Exact<{
  input?: Maybe<DeleteUserInput>;
}>;


export type DeleteStudentMutation = (
  { __typename?: 'Mutation' }
  & { deleteStudent?: Maybe<(
    { __typename?: 'StudentDeletePayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'StudentDeleteProblem' }
      & Pick<StudentDeleteProblem, 'message'>
    )> }
  )> }
);

export type DeleteMultipleUsersMutationVariables = Exact<{
  input?: Maybe<DeleteMultipleUsersInput>;
}>;


export type DeleteMultipleUsersMutation = (
  { __typename?: 'Mutation' }
  & { deleteMultipleUsers?: Maybe<(
    { __typename?: 'UsersDeletePayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'UserDeleteProblem' }
      & Pick<UserDeleteProblem, 'message'>
    )> }
  )> }
);

export type StartStudentSessionsMutationVariables = Exact<{
  input?: Maybe<StartSessionsInput>;
}>;


export type StartStudentSessionsMutation = (
  { __typename?: 'Mutation' }
  & { startStudentSessions?: Maybe<(
    { __typename?: 'StudentSessionPayload' }
    & Pick<StudentSessionPayload, 'start'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'CouldNotCreateSessionProblem' } | { __typename?: 'PermissionDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotHaveEnoughCreditsProblem' } | { __typename?: 'Student' } | { __typename?: 'UserAlreadyHasASessionProblem' } | { __typename?: 'UserExceededQuotaPerWeekProblem' } | { __typename?: 'UserInCollectionDoesNotExistProblem' }> }
  )> }
);

export type CreateStudentSessionMutationVariables = Exact<{
  input: StudentSessionInput;
}>;


export type CreateStudentSessionMutation = (
  { __typename?: 'Mutation' }
  & { createStudentSession?: Maybe<(
    { __typename?: 'StudentSessionPayload' }
    & Pick<StudentSessionPayload, 'start'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'ClassDoesNotExistProblem' } | { __typename?: 'CouldNotCreateSessionProblem' } | { __typename?: 'PermissionDoesNotExistProblem' } | { __typename?: 'Problem' } | { __typename?: 'SchoolDoesNotHaveEnoughCreditsProblem' } | { __typename?: 'Student' } | { __typename?: 'UserAlreadyHasASessionProblem' } | { __typename?: 'UserExceededQuotaPerWeekProblem' } | { __typename?: 'UserInCollectionDoesNotExistProblem' }> }
  )> }
);

export type StudentClassroomStatusQueryVariables = Exact<{
  userId: Scalars['String'];
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
}>;


export type StudentClassroomStatusQuery = (
  { __typename?: 'Query' }
  & { studentClassroomStatus?: Maybe<(
    { __typename?: 'StudentClassroomStatusGetPayload' }
    & { collectionStatus?: Maybe<Array<Maybe<(
      { __typename?: 'CollectionStatus' }
      & Pick<CollectionStatus, 'classId' | 'status' | 'token' | 'userIp' | 'sessionId'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SessionDoesNotExistProblem' } | { __typename?: 'Student' }> }
  )> }
);

export type ChargifyRedirectSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChargifyRedirectSubscription = (
  { __typename?: 'Subscription' }
  & { chargifyRedirect?: Maybe<(
    { __typename?: 'RedirectResult' }
    & Pick<RedirectResult, 'externalId' | 'url'>
  )> }
);

export type SubscriptionStateQueryVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type SubscriptionStateQuery = (
  { __typename?: 'Query' }
  & { subscriptionState?: Maybe<(
    { __typename?: 'SubscriptionStatePayload' }
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionState' }
      & Pick<SubscriptionState, 'url' | 'ready'>
    )>, problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SubscriptionState' }> }
  )> }
);

export type SessionCreatedSubscriptionVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
}>;


export type SessionCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { sessionCreated?: Maybe<(
    { __typename?: 'SessionResult' }
    & Pick<SessionResult, 'id' | 'gateway' | 'email' | 'status' | 'collectionId' | 'token'>
  )> }
);

export type SessionFinishedSubscriptionVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type SessionFinishedSubscription = (
  { __typename?: 'Subscription' }
  & { sessionFinished?: Maybe<(
    { __typename?: 'SessionResult' }
    & Pick<SessionResult, 'email' | 'collectionId'>
  )> }
);

export type SessionNotStartSubscriptionVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type SessionNotStartSubscription = (
  { __typename?: 'Subscription' }
  & { sessionNotStart?: Maybe<(
    { __typename?: 'SessionNotStartResult' }
    & Pick<SessionNotStartResult, 'collectionId'>
  )> }
);

export type AllStudentsQueryVariables = Exact<{
  input: AllStudentsInput;
}>;


export type AllStudentsQuery = (
  { __typename?: 'Query' }
  & { allStudents?: Maybe<(
    { __typename?: 'AllStudentsPayload' }
    & Pick<AllStudentsPayload, 'nextPage'>
    & { list?: Maybe<Array<Maybe<(
      { __typename?: 'StudentClass' }
      & Pick<StudentClass, 'id' | 'name' | 'email' | 'className'>
    )>>>, problem?: Maybe<{ __typename: 'AuthenticationRequiredProblem' } | { __typename: 'Problem' } | { __typename: 'StudentClass' } | { __typename: 'UsersNotFoundProblem' }> }
  )> }
);

export type NorecentActivityByDaysQueryVariables = Exact<{
  input: NoRecentActivityInput;
}>;


export type NorecentActivityByDaysQuery = (
  { __typename?: 'Query' }
  & { noRecentActivityByDays?: Maybe<(
    { __typename?: 'AllStudentsPayload' }
    & Pick<AllStudentsPayload, 'nextPage'>
    & { list?: Maybe<Array<Maybe<(
      { __typename?: 'StudentClass' }
      & Pick<StudentClass, 'id' | 'name' | 'email' | 'className'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'StudentClass' } | (
      { __typename?: 'UsersNotFoundProblem' }
      & Pick<UsersNotFoundProblem, 'message'>
    )> }
  )> }
);

export type GetClassInstructorsQueryVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
}>;


export type GetClassInstructorsQuery = (
  { __typename?: 'Query' }
  & { classInstructors?: Maybe<(
    { __typename?: 'StudentsGetsPayload' }
    & { students?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'email' | 'lastLogon'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'ClassDoesNotExistProblem' }
      & Pick<ClassDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'SchoolDoesNotExistProblem' }
      & Pick<SchoolDoesNotExistProblem, 'message'>
    ) | { __typename?: 'Student' } | (
      { __typename?: 'StudentCollectionProblem' }
      & Pick<StudentCollectionProblem, 'message'>
    ) | (
      { __typename?: 'UserDoesNotExitsProblem' }
      & Pick<UserDoesNotExitsProblem, 'message'>
    )> }
  )> }
);

export type AddClassTeachersMutationVariables = Exact<{
  instructors?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  districtId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
}>;


export type AddClassTeachersMutation = (
  { __typename?: 'Mutation' }
  & { addClassTeachers?: Maybe<(
    { __typename?: 'InstructorsSyncPayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | { __typename?: 'CollectionDoesNotExistProblem' } | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'SchoolDoesNotExistProblem' } | (
      { __typename?: 'SyncInstructorsProblem' }
      & Pick<SyncInstructorsProblem, 'message'>
    )> }
  )> }
);

export type DeleteTeacherMutationVariables = Exact<{
  input?: Maybe<DeleteUserInput>;
}>;


export type DeleteTeacherMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeacher?: Maybe<(
    { __typename?: 'InstructorDeletePayload' }
    & { classbool?: Maybe<(
      { __typename?: 'ClassroomBooleanResponse' }
      & Pick<ClassroomBooleanResponse, 'classbool'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'ClassroomBooleanResponse' } | (
      { __typename?: 'CollectionDoesNotExistProblem' }
      & Pick<CollectionDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'InstructorDeleteProblem' }
      & Pick<InstructorDeleteProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | (
      { __typename?: 'UserNotFoundProblem' }
      & Pick<UserNotFoundProblem, 'message'>
    )> }
  )> }
);

export type ClassDailyUsageQueryVariables = Exact<{
  input: StudentsCountInput;
}>;


export type ClassDailyUsageQuery = (
  { __typename?: 'Query' }
  & { classDailyUsage?: Maybe<(
    { __typename?: 'ClassDailyUsageGetsPayload' }
    & { dailyUsage?: Maybe<Array<Maybe<(
      { __typename?: 'ClassDailyUsage' }
      & Pick<ClassDailyUsage, 'date'>
      & { values?: Maybe<Array<Maybe<(
        { __typename?: 'StudentsHistory' }
        & Pick<StudentsHistory, 'collectionId' | 'collectionName' | 'studentCount'>
      )>>> }
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'StudentsHistory' }> }
  )> }
);

export type GetTiersbyImageQueryVariables = Exact<{
  imageId: Scalars['String'];
}>;


export type GetTiersbyImageQuery = (
  { __typename?: 'Query' }
  & { tiers?: Maybe<(
    { __typename?: 'TierGetPayload' }
    & { tiers?: Maybe<Array<Maybe<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name' | 'ram' | 'cpu' | 'machineType' | 'rate' | 'active' | 'required' | 'gpu' | 'allowed'>
    )>>>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'Classroom' } | (
      { __typename?: 'ImageDoesNotExistProblem' }
      & Pick<ImageDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type GetTierByIdQueryVariables = Exact<{
  tierId: Scalars['String'];
}>;


export type GetTierByIdQuery = (
  { __typename?: 'Query' }
  & { tier?: Maybe<(
    { __typename?: 'TierPayload' }
    & { tier?: Maybe<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name' | 'rate'>
    )>, problem?: Maybe<(
      { __typename: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | (
      { __typename: 'Problem' }
      & Pick<Problem, 'message'>
    ) | { __typename?: 'Tier' } | (
      { __typename: 'TierDoesNotExistProblem' }
      & Pick<TierDoesNotExistProblem, 'message'>
    )> }
  )> }
);

export type BlockTeacherAccessDistrictQueryVariables = Exact<{
  input: DistrictGetInput;
}>;


export type BlockTeacherAccessDistrictQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'DistrictGetPayload' }
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'blockTeacherAccess'>
    )>, problem?: Maybe<(
      { __typename?: 'AuthenticationRequiredProblem' }
      & Pick<AuthenticationRequiredProblem, 'message'>
    ) | { __typename?: 'District' } | (
      { __typename?: 'DistrictDoesNotExistProblem' }
      & Pick<DistrictDoesNotExistProblem, 'message'>
    ) | (
      { __typename?: 'Problem' }
      & Pick<Problem, 'message'>
    )> }
  )> }
);

export type BlockTeacherSettingDistrictMutationVariables = Exact<{
  districtId?: Maybe<Scalars['String']>;
  block: Scalars['Boolean'];
}>;


export type BlockTeacherSettingDistrictMutation = (
  { __typename?: 'Mutation' }
  & { blockTeacherSettingDistrict?: Maybe<(
    { __typename?: 'BlockTeacherSettingDistrictPayload' }
    & Pick<BlockTeacherSettingDistrictPayload, 'updated'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'District' } | { __typename?: 'DistrictDoesNotExistProblem' } | { __typename?: 'Problem' }> }
  )> }
);

export type BlockTeacherSettingSchoolMutationVariables = Exact<{
  districtId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  block: Scalars['Boolean'];
}>;


export type BlockTeacherSettingSchoolMutation = (
  { __typename?: 'Mutation' }
  & { blockTeacherSettingSchool?: Maybe<(
    { __typename?: 'BlockTeacherSettingShoolPayload' }
    & Pick<BlockTeacherSettingShoolPayload, 'updated'>
    & { problem?: Maybe<{ __typename?: 'AuthenticationRequiredProblem' } | { __typename?: 'Problem' } | { __typename?: 'School' } | { __typename?: 'SchoolDoesNotExistProblem' }> }
  )> }
);

export const GetAdminsDocument = gql`
    query getAdmins($districtId: ID!, $schoolId: ID, $classId: ID) {
  admins(districtId: $districtId, schoolId: $schoolId, classId: $classId) {
    admin {
      id
      name
      role
      email
      resourceType
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminsGQL extends Apollo.Query<GetAdminsQuery, GetAdminsQueryVariables> {
    document = GetAdminsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PermissionsByResourcePathDocument = gql`
    query permissionsByResourcePath($user: String!, $resourcePath: [String]) {
  permissionsByResourcePath(user: $user, resourcePath: $resourcePath) {
    permissions {
      id
      all
      name
      role
      resourceType
      resourceId
    }
    problem {
      ... on PermissionsByResourceGetProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
        ... on PermissionDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PermissionsByResourcePathGQL extends Apollo.Query<PermissionsByResourcePathQuery, PermissionsByResourcePathQueryVariables> {
    document = PermissionsByResourcePathDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEarlyAccessDocument = gql`
    query getEarlyAccess($userEmail: String!, $entityId: String) {
  earlyAccess(userEmail: $userEmail, entityId: $entityId) {
    earlyAccess {
      name
      scope
      style
    }
    problem {
      ... on EarlyAccessGetProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEarlyAccessGQL extends Apollo.Query<GetEarlyAccessQuery, GetEarlyAccessQueryVariables> {
    document = GetEarlyAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentDocument = gql`
    query getCurrent {
  current {
    admin {
      id
      name
      email
      resourceType
      role
      hmac
      districts {
        districtId
        name
        role
        schools {
          schoolId
          name
          resourceType
          role
        }
      }
      refererId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on AdminDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentGQL extends Apollo.Query<GetCurrentQuery, GetCurrentQueryVariables> {
    document = GetCurrentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAdminDocument = gql`
    mutation createAdmin($input: AdminCreateInputV2!) {
  createAdmin(input: $input) {
    admin {
      id
      name
      email
      role
      resourceType
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on InvalidEmailProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdminGQL extends Apollo.Mutation<CreateAdminMutation, CreateAdminMutationVariables> {
    document = CreateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdminDocument = gql`
    mutation deleteAdmin($districtId: ID!, $adminId: ID!) {
  deleteAdmin(districtId: $districtId, adminId: $adminId) {
    adminBool {
      adminBool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdminGQL extends Apollo.Mutation<DeleteAdminMutation, DeleteAdminMutationVariables> {
    document = DeleteAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdminDocument = gql`
    mutation updateAdmin {
  updateAdmin {
    adminBool
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdminGQL extends Apollo.Mutation<UpdateAdminMutation, UpdateAdminMutationVariables> {
    document = UpdateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdminFromSchoolDocument = gql`
    mutation deleteAdminFromSchool($districtId: ID!, $schoolId: ID!, $adminId: ID!) {
  deleteAdminFromSchool(
    districtId: $districtId
    schoolId: $schoolId
    adminId: $adminId
  ) {
    schoolBool {
      schoolBool
    }
    problem {
      ... on SchoolDeleteProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdminFromSchoolGQL extends Apollo.Mutation<DeleteAdminFromSchoolMutation, DeleteAdminFromSchoolMutationVariables> {
    document = DeleteAdminFromSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApplicationsDocument = gql`
    query getApplications($districtId: String, $schoolId: String, $imageId: String, $excludedApp: Boolean) {
  applications(
    districtId: $districtId
    schoolId: $schoolId
    imageId: $imageId
    excludedApp: $excludedApp
  ) {
    applications {
      id
      name
      hasLicense
      displayName
      type
      includedApplications
      isBaseApplication
      friendlyName
      icon
      orderApp
      license {
        key
        required
        type
        mask
        regularExpression
        name
        description
        placeholder
        infoUrl
        title
        label
      }
      images
      order
      path
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApplicationsGQL extends Apollo.Query<GetApplicationsQuery, GetApplicationsQueryVariables> {
    document = GetApplicationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationsUsageReportDocument = gql`
    query applicationsUsageReport($input: ApplicationsUsageInput) {
  applicationsUsageReport(input: $input) {
    applicationsUsage {
      username
      applicationName
      duration
      totalDuration
      startDate
      endDate
      studentName
      mostRecentLaunch
    }
    problem {
      ... on ApplicationUsageReportProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationsUsageReportGQL extends Apollo.Query<ApplicationsUsageReportQuery, ApplicationsUsageReportQueryVariables> {
    document = ApplicationsUsageReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClassAppsDocument = gql`
    mutation updateClassApps($input: ClassUpdateAppsInput) {
  updateClassApps(input: $input) {
    classroom {
      id
      applications {
        id
        displayName
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClassAppsGQL extends Apollo.Mutation<UpdateClassAppsMutation, UpdateClassAppsMutationVariables> {
    document = UpdateClassAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAzureGroupsDocument = gql`
    query getAzureGroups($districtId: ID!, $pageSize: String, $pageToken: String, $query: String) {
  mGroups(
    districtId: $districtId
    pageSize: $pageSize
    pageToken: $pageToken
    query: $query
  ) {
    mGroup {
      name
      description
      id
    }
    pageToken
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on MIntegrationNotReadyYetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAzureGroupsGQL extends Apollo.Query<GetAzureGroupsQuery, GetAzureGroupsQueryVariables> {
    document = GetAzureGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassLinkClassesDocument = gql`
    query classLinkClasses($districtId: ID!, $pageIndex: String, $pageSize: String, $filter: String, $order: String) {
  classLinkClasses(
    districtId: $districtId
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
    order: $order
  ) {
    classes {
      sourcedId
      status
      dateLastModified
      metadata {
        courseName
        schoolName
        teacherName
        numberOfEnrollments
      }
      title
      classType
      location
      grades
      periods
      __typename
    }
    nextPage
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on ClIntegrationNotReadyYetProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClassLinkClassesGQL extends Apollo.Query<ClassLinkClassesQuery, ClassLinkClassesQueryVariables> {
    document = ClassLinkClassesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassRoomsNameDocument = gql`
    query getClassRoomsName($input: ClassroomsInput!) {
  classrooms(input: $input) {
    classroom {
      id
      name
      from
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on CollectionSchoolDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassRoomsNameGQL extends Apollo.Query<GetClassRoomsNameQuery, GetClassRoomsNameQueryVariables> {
    document = GetClassRoomsNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassRoomsDocument = gql`
    query getClassRooms($input: ClassroomsInput!) {
  classrooms(input: $input) {
    classroom {
      id
      districtId
      name
      description
      schoolId
      quota
      tierId
      imageId
      remoteApp
      from
      hasLicense
      licenses {
        from
        key
        value
      }
    }
    nextPage
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on CollectionSchoolDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassRoomsGQL extends Apollo.Query<GetClassRoomsQuery, GetClassRoomsQueryVariables> {
    document = GetClassRoomsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassRoomAssigmentDocument = gql`
    query getClassRoomAssigment($districtId: String!, $schoolId: String!, $classId: String!) {
  classroom(districtId: $districtId, schoolId: $schoolId, classId: $classId) {
    classroom {
      districtId
      schoolId
      id
      studentGroup
      studentGroupId
      instructorGroup
      instructorGroupId
      courseId
      courseName
      azureAdInstructorGroupId
      azureAdInstructorGroupName
      azureAdGroupId
      azureAdGroupName
      directAssignments {
        identifier
        type
      }
      remoteMembers {
        identifier
        name
        source
        membersType
      }
      syncStatus
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassRoomAssigmentGQL extends Apollo.Query<GetClassRoomAssigmentQuery, GetClassRoomAssigmentQueryVariables> {
    document = GetClassRoomAssigmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassroomStatusDocument = gql`
    query getClassroomStatus($input: ClassroomsInput!) {
  classrooms(input: $input) {
    classroom {
      id
      startInstances
    }
    problem {
      ... on Problem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionSchoolDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassroomStatusGQL extends Apollo.Query<GetClassroomStatusQuery, GetClassroomStatusQueryVariables> {
    document = GetClassroomStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassRoomDataDocument = gql`
    query getClassRoomData($districtId: String!, $schoolId: String!, $classId: String!) {
  classroom(districtId: $districtId, schoolId: $schoolId, classId: $classId) {
    classroom {
      districtId
      schoolId
      id
      name
      description
      lastSync
      imageId
      remoteApp
      from
      image {
        id
        name
        icon
        description
        latestBuild
        includedApplications
        tag {
          color
          label
        }
        licenses {
          key
          required
          type
          mask
          regularExpression
          name
          description
          placeholder
          infoUrl
          title
          label
        }
      }
      licenses {
        from
        key
        value
      }
      applications {
        id
        displayName
        hasLicense
        licenseKey
      }
      hasLicense
      studentGroup
      studentGroupId
      instructorGroup
      instructorGroupId
      azureAdInstructorGroupId
      azureAdInstructorGroupName
      azureAdGroupId
      azureAdGroupName
      courseId
      courseName
      instructors {
        id
        name
        email
      }
      directAssignments {
        identifier
        type
      }
      remoteMembers {
        identifier
        name
        source
        membersType
      }
      syncStatus
      cleverSchoolId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassRoomDataGQL extends Apollo.Query<GetClassRoomDataQuery, GetClassRoomDataQueryVariables> {
    document = GetClassRoomDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassRoomQuotaDataDocument = gql`
    query getClassRoomQuotaData($districtId: String!, $schoolId: String!, $classId: String!) {
  classroom(districtId: $districtId, schoolId: $schoolId, classId: $classId) {
    classroom {
      districtId
      schoolId
      id
      quota
      exceptions
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassRoomQuotaDataGQL extends Apollo.Query<GetClassRoomQuotaDataQuery, GetClassRoomQuotaDataQueryVariables> {
    document = GetClassRoomQuotaDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClassroomDocument = gql`
    mutation createClassroom($input: ClassroomInput) {
  createClassroom(input: $input) {
    classroom {
      id
      name
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DeploymentDoesNotExistProblem {
        message
      }
      ... on IntegrationNotReadyYetProblem {
        message
      }
      ... on CollectionAlreadyExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClassroomGQL extends Apollo.Mutation<CreateClassroomMutation, CreateClassroomMutationVariables> {
    document = CreateClassroomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClassroomDocument = gql`
    mutation updateClassroom($classId: String, $input: ClassroomUpdateInput) {
  updateClassroom(classId: $classId, input: $input) {
    classroom {
      id
      name
      quota
    }
    problem {
      ... on ClassroomUpdateProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on CollectionDoesNotExistProblem {
          message
        }
        ... on RepeatStudentsExcludedProblem {
          message
        }
        ... on Problem {
          message
        }
        ... on QuotaCanNotBeNegativeProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClassroomGQL extends Apollo.Mutation<UpdateClassroomMutation, UpdateClassroomMutationVariables> {
    document = UpdateClassroomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteClassroomDocument = gql`
    mutation deleteClassroom($districtId: String, $schoolId: String, $classId: String, $agree: Boolean) {
  deleteClassroom(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    agree: $agree
  ) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteClassroomGQL extends Apollo.Mutation<DeleteClassroomMutation, DeleteClassroomMutationVariables> {
    document = DeleteClassroomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassUsageDocument = gql`
    query classUsage($districtId: String!, $schoolId: String!, $classId: String!, $startDate: String!, $endDate: String) {
  classUsage(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    startDate: $startDate
    endDate: $endDate
  ) {
    classUsage {
      name
      timeUsage
      creditUsage
      startDate
      endDate
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClassUsageGQL extends Apollo.Query<ClassUsageQuery, ClassUsageQueryVariables> {
    document = ClassUsageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncClassDocument = gql`
    mutation syncClass($classId: String, $schoolId: String, $districtId: String) {
  syncClass(classId: $classId, schoolId: $schoolId, districtId: $districtId) {
    classbool {
      classbool
    }
    lastSync
    problem {
      ... on ClassroomSyncProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
        ... on CollectionDoesNotExistProblem {
          message
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncClassGQL extends Apollo.Mutation<SyncClassMutation, SyncClassMutationVariables> {
    document = SyncClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassroomMembersDocument = gql`
    query getClassroomMembers($classId: String!, $districtId: String, $schoolId: String) {
  classroomMembers(
    classId: $classId
    districtId: $districtId
    schoolId: $schoolId
  ) {
    members {
      instructors
      students
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on CollectionDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassroomMembersGQL extends Apollo.Query<GetClassroomMembersQuery, GetClassroomMembersQueryVariables> {
    document = GetClassroomMembersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassroomsLicensesCountDocument = gql`
    query classroomsLicensesCount($input: ClassroomsCountInput!) {
  classroomsLicensesCount(input: $input) {
    classroomsCount {
      activatedLicenses
      classroomsCount
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on DistrictDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClassroomsLicensesCountGQL extends Apollo.Query<ClassroomsLicensesCountQuery, ClassroomsLicensesCountQueryVariables> {
    document = ClassroomsLicensesCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectActiveSessionDocument = gql`
    mutation connectActiveSession($input: ConnectSessionInput!) {
  connectActiveSession(input: $input) {
    sessionShared {
      peerId
      customToken
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on SessionAlreadyHasAnAdminProblem {
        message
        __typename
      }
      ... on SessionNotReadyYetProblem {
        message
        __typename
      }
      ... on SessionDoesNotExistProblem {
        message
        __typename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectActiveSessionGQL extends Apollo.Mutation<ConnectActiveSessionMutation, ConnectActiveSessionMutationVariables> {
    document = ConnectActiveSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentClassroomsSessionsDocument = gql`
    query currentClassroomsSessions($input: CurrentSessionInput) {
  currentClassroomsSessions(input: $input) {
    nextPage
    sessions {
      id
      userId
      email
      name
      instanceName
      sessionStartAt
      deploymentId
      districtId
      collectionId
      collectionName
      status
      trialSessionTime
    }
    problem {
      ... on CurrentSessionsGetsProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
        ... on SchoolDoesNotExistProblem {
          message
          __typename
        }
        ... on DistrictDoesNotExistProblem {
          message
          __typename
        }
        ... on ClassDoesNotExistProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentClassroomsSessionsGQL extends Apollo.Query<CurrentClassroomsSessionsQuery, CurrentClassroomsSessionsQueryVariables> {
    document = CurrentClassroomsSessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PathClassDocument = gql`
    mutation pathClass($input: PatchClassroomInput) {
  patchClassroom(input: $input) {
    update
    problem {
      ... on PathClassroomProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PathClassGQL extends Apollo.Mutation<PathClassMutation, PathClassMutationVariables> {
    document = PathClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectSessionDocument = gql`
    query connectSession($peerId: String, $classId: String!, $studentEmail: String!) {
  connectSession(peerId: $peerId, classId: $classId, studentEmail: $studentEmail) {
    session {
      gatewayUrl
      peerId
      serverToken
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on SessionPeerIdDoesNotExistProblem {
        message
        __typename
      }
      ... on SessionDoesNotExistProblem {
        message
        __typename
      }
      ... on PermissionDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectSessionGQL extends Apollo.Query<ConnectSessionQuery, ConnectSessionQueryVariables> {
    document = ConnectSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateResourceOrderDocument = gql`
    mutation createResourceOrder($input: ManageResourceOrderInput) {
  manageResourceOrder(input: $input) {
    resourceOrder {
      resourceIds
      resourceType
      userEmail
      deploymentId
      organizationId
    }
    problem {
      ... on ManageResourceOrderProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateResourceOrderGQL extends Apollo.Mutation<CreateResourceOrderMutation, CreateResourceOrderMutationVariables> {
    document = CreateResourceOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResourceOrdersDocument = gql`
    query resourceOrders($input: ResourceOrdersInput!) {
  resourceOrders(input: $input) {
    order {
      resourceIds
      resourceType
      userEmail
      deploymentId
      organizationId
    }
    problem {
      ... on ResourceOrdersProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResourceOrdersGQL extends Apollo.Query<ResourceOrdersQuery, ResourceOrdersQueryVariables> {
    document = ResourceOrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CleverSectionsDocument = gql`
    query cleverSections($districtId: String!, $cleverSchoolId: String!) {
  cleverSections(districtId: $districtId, cleverSchoolId: $cleverSchoolId) {
    sections {
      id
      name
      sisId
      created
      lastModified
      grade
      period
      sectionNumber
      subject
      teachersCount
      studentsCount
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CleverIntegrationNotReadyYetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CleverSectionsGQL extends Apollo.Query<CleverSectionsQuery, CleverSectionsQueryVariables> {
    document = CleverSectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CleverSchoolsDocument = gql`
    query cleverSchools($districtId: String!) {
  cleverSchools(districtId: $districtId) {
    schools {
      id
      name
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CleverIntegrationNotReadyYetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CleverSchoolsGQL extends Apollo.Query<CleverSchoolsQuery, CleverSchoolsQueryVariables> {
    document = CleverSchoolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCoursesDocument = gql`
    query getCourses($districtId: String, $pageSize: String, $pageToken: String, $filter: String, $byTeacher: Boolean) {
  courses(
    districtId: $districtId
    pageSize: $pageSize
    pageToken: $pageToken
    filter: $filter
    byTeacher: $byTeacher
  ) {
    courses {
      id
      name
    }
    pageToken
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on IntegrationNotReadyYetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCoursesGQL extends Apollo.Query<GetCoursesQuery, GetCoursesQueryVariables> {
    document = GetCoursesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCourseDocument = gql`
    mutation addCourse($input: CourseInput, $districtId: String, $schoolId: String, $classId: String, $districtName: String, $schoolName: String) {
  addClassCourses(
    input: $input
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    districtName: $districtName
    schoolName: $schoolName
  ) {
    classbool {
      classbool
    }
    lastSync
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SyncCoursesProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCourseGQL extends Apollo.Mutation<AddCourseMutation, AddCourseMutationVariables> {
    document = AddCourseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCreditsDocument = gql`
    query getCredits($districtId: ID!) {
  credits(districtId: $districtId) {
    credits {
      id
      name
      credits
      district
      referenceId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCreditsGQL extends Apollo.Query<GetCreditsQuery, GetCreditsQueryVariables> {
    document = GetCreditsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTotalCreditsDocument = gql`
    query getTotalCredits($input: DistrictGetInput!) {
  district(input: $input) {
    district {
      id
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTotalCreditsGQL extends Apollo.Query<GetTotalCreditsQuery, GetTotalCreditsQueryVariables> {
    document = GetTotalCreditsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDistrictDataDocument = gql`
    query getDistrictData($input: DistrictGetInput!) {
  district(input: $input) {
    district {
      id
      name
      contactName
      contactLastName
      contactEmail
      billingEmail
      region
      billingAddress
      totalCredits
      status
      schools {
        schoolId
        name
        resourceType
        role
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDistrictDataGQL extends Apollo.Query<GetDistrictDataQuery, GetDistrictDataQueryVariables> {
    document = GetDistrictDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDistrictInfoDocument = gql`
    query getDistrictInfo($input: DistrictGetInput!) {
  district(input: $input) {
    district {
      id
      name
      contactName
      contactEmail
      externalId
      billingEmail
      region
      billingAddress
      totalCredits
      isSchool
      hasIntegration
      integrationProvider
      status
      studentsNotification
      teachersNotification
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDistrictInfoGQL extends Apollo.Query<GetDistrictInfoQuery, GetDistrictInfoQueryVariables> {
    document = GetDistrictInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentNotificationDocument = gql`
    query studentNotification($input: DistrictGetInput!) {
  district(input: $input) {
    district {
      id
      name
      studentsNotification
      teachersNotification
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentNotificationGQL extends Apollo.Query<StudentNotificationQuery, StudentNotificationQueryVariables> {
    document = StudentNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatchDistrictDocument = gql`
    mutation patchDistrict($input: PatchDistrictInput!) {
  patchDistrict(input: $input) {
    patched
    problem {
      __typename
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PatchDistrictGQL extends Apollo.Mutation<PatchDistrictMutation, PatchDistrictMutationVariables> {
    document = PatchDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBillingSubscriptionDocument = gql`
    query getBillingSubscription($districtId: String!, $externalId: Int!) {
  getBillingSubscription(districtId: $districtId, externalId: $externalId) {
    subscription {
      billingPortal
      billingAddress
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SubscriptionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBillingSubscriptionGQL extends Apollo.Query<GetBillingSubscriptionQuery, GetBillingSubscriptionQueryVariables> {
    document = GetBillingSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllowedDomainsByDistrictIdDocument = gql`
    query allowedDomainsByDistrictId($districtId: String!) {
  allowedDomainsByDistrict(districtId: $districtId) {
    domains {
      count
      list
    }
    problem {
      ... on AllowedDomainsProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllowedDomainsByDistrictIdGQL extends Apollo.Query<AllowedDomainsByDistrictIdQuery, AllowedDomainsByDistrictIdQueryVariables> {
    document = AllowedDomainsByDistrictIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAllowedDomainsDocument = gql`
    mutation updateAllowedDomains($districtId: String!, $domains: AllowedDomainInput) {
  updateAllowedDomains(districtId: $districtId, domains: $domains) {
    updated
    problem {
      ... on UpdateAllowedDomainsProblem {
        ... on Problem {
          message
        }
        ... on AllowedDomainFormatErrorProblem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAllowedDomainsGQL extends Apollo.Mutation<UpdateAllowedDomainsMutation, UpdateAllowedDomainsMutationVariables> {
    document = UpdateAllowedDomainsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDistrictDocument = gql`
    mutation updateDistrict($input: DistrictUpdateInput!) {
  updateDistrict(input: $input) {
    district {
      id
      name
      contactName
      contactEmail
      contactLastName
      billingEmail
      billingAddress
      totalCredits
      isSchool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDistrictGQL extends Apollo.Mutation<UpdateDistrictMutation, UpdateDistrictMutationVariables> {
    document = UpdateDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentClassroomGatewayDocument = gql`
    query studentClassroomGateway($userId: String!, $classId: String!) {
  studentClassroomGateway(userId: $userId, classId: $classId) {
    gatewayUrl
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SessionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentClassroomGatewayGQL extends Apollo.Query<StudentClassroomGatewayQuery, StudentClassroomGatewayQueryVariables> {
    document = StudentClassroomGatewayDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGroupsByDistrictDocument = gql`
    query getGroupsByDistrict($districtId: ID!, $pageSize: String, $pageToken: String, $query: String) {
  groups(
    districtId: $districtId
    pageSize: $pageSize
    pageToken: $pageToken
    query: $query
  ) {
    gGroup {
      name
      description
      id
    }
    pageToken
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on IntegrationNotReadyYetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupsByDistrictGQL extends Apollo.Query<GetGroupsByDistrictQuery, GetGroupsByDistrictQueryVariables> {
    document = GetGroupsByDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImagesDocument = gql`
    query getImages($districtId: String, $hasLicense: Boolean, $schoolId: String) {
  images(districtId: $districtId, hasLicense: $hasLicense, schoolId: $schoolId) {
    images {
      id
      name
      description
      icon
      includedApplications
      tag {
        label
        color
      }
      isPublic
      licenses {
        key
        required
        type
        mask
        regularExpression
        name
        applications
        description
        placeholder
        infoUrl
        title
        label
      }
      latestBuild
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on ImageDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImagesGQL extends Apollo.Query<GetImagesQuery, GetImagesQueryVariables> {
    document = GetImagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImageByIdDocument = gql`
    query getImageById($imageId: String) {
  image(imageId: $imageId) {
    image {
      id
      name
      icon
      tag {
        label
        color
      }
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on ImageDoesNotExistProblem {
        message
        __typename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImageByIdGQL extends Apollo.Query<GetImageByIdQuery, GetImageByIdQueryVariables> {
    document = GetImageByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIntegrationWizardDocument = gql`
    query getIntegrationWizard($districtId: String) {
  integration(districtId: $districtId) {
    integration {
      signInPage
      signOutPage
      certificateUrl
      networkMask
      districtId
      sso
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on IntegrationCouldNotGetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIntegrationWizardGQL extends Apollo.Query<GetIntegrationWizardQuery, GetIntegrationWizardQueryVariables> {
    document = GetIntegrationWizardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIntegrationWizardV2Document = gql`
    query getIntegrationWizardV2($districtId: String) {
  integration(districtId: $districtId) {
    integration {
      signInPage
      signOutPage
      certificateUrl
      networkMask
      districtId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on IntegrationCouldNotGetProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIntegrationWizardV2GQL extends Apollo.Query<GetIntegrationWizardV2Query, GetIntegrationWizardV2QueryVariables> {
    document = GetIntegrationWizardV2Document;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IntegrationsDocument = gql`
    query integrations($districtId: String, $filter: String) {
  integrations(districtId: $districtId, filter: $filter) {
    integrations
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IntegrationsGQL extends Apollo.Query<IntegrationsQuery, IntegrationsQueryVariables> {
    document = IntegrationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConfigurationDocument = gql`
    query getConfiguration($districtId: String, $schoolId: String) {
  configurations(districtId: $districtId, schoolId: $schoolId) {
    configurations
    problem {
      ... on ConfigurationGetProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConfigurationGQL extends Apollo.Query<GetConfigurationQuery, GetConfigurationQueryVariables> {
    document = GetConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGoogleIntegrationDocument = gql`
    mutation createGoogleIntegration($input: GoogleIntegrationInput) {
  createGoogleIntegration(input: $input) {
    integration {
      id
      resourceId
      resourceType
      resourceName
      name
      integrationType
      status
      errorMessage
    }
    problem {
      ... on IntegrationCreateProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGoogleIntegrationGQL extends Apollo.Mutation<CreateGoogleIntegrationMutation, CreateGoogleIntegrationMutationVariables> {
    document = CreateGoogleIntegrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTenantIntegrationDocument = gql`
    mutation createTenantIntegration($input: TenantIntegrationInput) {
  createTenantIntegration(input: $input) {
    integration {
      id
      resourceId
      resourceType
      resourceName
      name
      integrationType
      status
      errorMessage
    }
    problem {
      ... on IntegrationCreateProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTenantIntegrationGQL extends Apollo.Mutation<CreateTenantIntegrationMutation, CreateTenantIntegrationMutationVariables> {
    document = CreateTenantIntegrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLtiIntegrationDocument = gql`
    mutation createLtiIntegration($input: LtiIntegrationInput) {
  createLtiIntegration(input: $input) {
    integration {
      id
      resourceId
      resourceType
      resourceName
      name
      integrationType
      status
      errorMessage
    }
    problem {
      ... on IntegrationCreateProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
        ... on IntegrationClientIdExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLtiIntegrationGQL extends Apollo.Mutation<CreateLtiIntegrationMutation, CreateLtiIntegrationMutationVariables> {
    document = CreateLtiIntegrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSsoDocument = gql`
    mutation updateSso($input: SsoInput) {
  updateSso(input: $input) {
    updateSso
    problem {
      ... on SsoCreateProblem {
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on Problem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSsoGQL extends Apollo.Mutation<UpdateSsoMutation, UpdateSsoMutationVariables> {
    document = UpdateSsoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SsoDocument = gql`
    query sso($districtId: String!, $schoolId: String) {
  sso(districtId: $districtId, schoolId: $schoolId) {
    sso
    problem {
      ... on SsoGetProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SsoGQL extends Apollo.Query<SsoQuery, SsoQueryVariables> {
    document = SsoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvitationDocument = gql`
    query getInvitation($token: String!) {
  invitation(token: $token) {
    adminInvitation {
      invitationId
      adminId
      organizationName
      organizationId
      token
      resources
      refererId
    }
    problem {
      ... on Problem {
        message
      }
      ... on TokenDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvitationGQL extends Apollo.Query<GetInvitationQuery, GetInvitationQueryVariables> {
    document = GetInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLicenseDetailsDocument = gql`
    query getLicenseDetails($districtId: ID!, $startDate: String!, $endDate: String) {
  licenseDetails(
    districtId: $districtId
    startDate: $startDate
    endDate: $endDate
  ) {
    licenseDetails {
      totalPurchased
      activated
      available
      deficit
      purchases
      startCurrentTerm
      endCurrentTerm
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicenseDetailsGQL extends Apollo.Query<GetLicenseDetailsQuery, GetLicenseDetailsQueryVariables> {
    document = GetLicenseDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDistrictLicensesDocument = gql`
    query getDistrictLicenses($districtId: ID!, $startDate: String!, $endDate: String, $pageIndex: String, $pageSize: String) {
  districtLicenses(
    districtId: $districtId
    startDate: $startDate
    endDate: $endDate
    pageIndex: $pageIndex
    pageSize: $pageSize
  ) {
    licenses {
      nextPage
      licenses {
        buyDate
        startDate
        endDate
        license
        activatedLicenses
        availableLicenses
      }
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDistrictLicensesGQL extends Apollo.Query<GetDistrictLicensesQuery, GetDistrictLicensesQueryVariables> {
    document = GetDistrictLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLicensedPeriodsDocument = gql`
    query getLicensedPeriods($districtId: ID!) {
  licensedPeriods(districtId: $districtId) {
    licensedPeriods {
      districtId
      startPeriod
      endPeriod
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicensedPeriodsGQL extends Apollo.Query<GetLicensedPeriodsQuery, GetLicensedPeriodsQueryVariables> {
    document = GetLicensedPeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDistrictActivatedLicensesDocument = gql`
    query getDistrictActivatedLicenses($districtId: ID!, $districtName: String!, $startDate: String!, $endDate: String, $pageIndex: String, $pageSize: String, $requestEmails: [String]) {
  districtActivatedLicenses(
    districtId: $districtId
    districtName: $districtName
    startDate: $startDate
    endDate: $endDate
    pageIndex: $pageIndex
    pageSize: $pageSize
    requestEmails: $requestEmails
  ) {
    activatedBool {
      activatedBool
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on LicensesDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDistrictActivatedLicensesGQL extends Apollo.Query<GetDistrictActivatedLicensesQuery, GetDistrictActivatedLicensesQueryVariables> {
    document = GetDistrictActivatedLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResourceLicensesDocument = gql`
    query resourceLicenses($districtId: String, $schoolId: String, $classId: String, $resourceId: String!, $resourceType: String!, $pageIndex: String, $pageSize: String, $filter: String, $order: String) {
  resourceLicenses(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    resourceId: $resourceId
    resourceType: $resourceType
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
    order: $order
  ) {
    licenses {
      resourceId
      resourceName
      resourceType
      id
      imageId
      imageName
      license {
        key
        value
        displayName
      }
    }
    nextPage
    problem {
      ... on ResourceLicensesGetProblem {
        ... on Problem {
          message
          __typename
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResourceLicensesGQL extends Apollo.Query<ResourceLicensesQuery, ResourceLicensesQueryVariables> {
    document = ResourceLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddResourceLicenseDocument = gql`
    mutation addResourceLicense($input: ResourceLicenseInput!) {
  addResourceLicense(input: $input) {
    resourceLicense {
      resourceId
      resourceName
      resourceType
      imageId
      imageName
      license {
        key
        value
        displayName
      }
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddResourceLicenseGQL extends Apollo.Mutation<AddResourceLicenseMutation, AddResourceLicenseMutationVariables> {
    document = AddResourceLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteResourceLicensesDocument = gql`
    mutation deleteResourceLicenses($input: DeleteResourcedLicensesInput!) {
  deleteResourceLicenses(input: $input) {
    licensebool {
      licensebool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on LicensesDeleteProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteResourceLicensesGQL extends Apollo.Mutation<DeleteResourceLicensesMutation, DeleteResourceLicensesMutationVariables> {
    document = DeleteResourceLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResourceLicenseValueDocument = gql`
    query resourceLicenseValue($id: String!, $districtId: String, $schoolId: String) {
  resourceLicenseValue(id: $id, districtId: $districtId, schoolId: $schoolId) {
    value
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResourceLicenseValueGQL extends Apollo.Query<ResourceLicenseValueQuery, ResourceLicenseValueQueryVariables> {
    document = ResourceLicenseValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageLicenseDocument = gql`
    mutation manageLicense($input: ManageLicenseInput) {
  manageLicense(input: $input) {
    classroom {
      districtId
      schoolId
      id
      name
      description
      lastSync
      imageId
      remoteApp
      from
      image {
        id
        name
        icon
        description
        latestBuild
        includedApplications
        tag {
          color
          label
        }
        licenses {
          key
          required
          type
          mask
          regularExpression
          name
          description
          placeholder
          infoUrl
          title
          label
        }
      }
      licenses {
        from
        key
        value
      }
      applications {
        id
        displayName
        hasLicense
        licenseKey
      }
      hasLicense
      studentGroup
      studentGroupId
      instructorGroup
      instructorGroupId
      azureAdInstructorGroupId
      azureAdInstructorGroupName
      azureAdGroupId
      azureAdGroupName
      courseId
      courseName
      instructors {
        id
        name
        email
      }
      directAssignments {
        identifier
        type
      }
      remoteMembers {
        identifier
        name
        source
        membersType
      }
    }
    problem {
      ... on ManageLicenseProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on CollectionDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageLicenseGQL extends Apollo.Mutation<ManageLicenseMutation, ManageLicenseMutationVariables> {
    document = ManageLicenseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NetworkStatisticsDocument = gql`
    query networkStatistics($districtId: String!, $schoolId: String) {
  networkStatistics(districtId: $districtId, schoolId: $schoolId) {
    statistics {
      healthy
      poor
      failed
    }
    problem {
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NetworkStatisticsGQL extends Apollo.Query<NetworkStatisticsQuery, NetworkStatisticsQueryVariables> {
    document = NetworkStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticsDetailsDocument = gql`
    query statisticsDetails($districtId: String!, $schoolId: String, $period: Int!, $status: [String], $pageIndex: String, $pageSize: String, $order: String, $filter: String) {
  statisticsDetails(
    districtId: $districtId
    schoolId: $schoolId
    period: $period
    status: $status
    pageIndex: $pageIndex
    pageSize: $pageSize
    order: $order
    filter: $filter
  ) {
    nextPage
    statisticsDetails {
      createdAt
      userName
      ipAddress
      status
      trickleIce
      latency
      bandwidth
      bandwidthUp
    }
    problem {
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticsDetailsGQL extends Apollo.Query<StatisticsDetailsQuery, StatisticsDetailsQueryVariables> {
    document = StatisticsDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectionInfoDocument = gql`
    mutation connectionInfo($connectionInput: ConnectionInfoInput!) {
  connectionInfo(input: $connectionInput) {
    studentbool {
      studentbool
    }
    problem {
      ... on IpConfigNotFoundProblem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectionInfoGQL extends Apollo.Mutation<ConnectionInfoMutation, ConnectionInfoMutationVariables> {
    document = ConnectionInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NearestRegionDocument = gql`
    query nearestRegion($districtId: String!) {
  nearestRegion(districtId: $districtId) {
    regions {
      name
      networkDiagnosticUrl
      turnWebUrl
    }
    problem {
      __typename
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NearestRegionGQL extends Apollo.Query<NearestRegionQuery, NearestRegionQueryVariables> {
    document = NearestRegionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentUsageReportDocument = gql`
    mutation studentUsageReport($input: StudentUsageInput) {
  studentUsageReport(input: $input) {
    nextPage
    studentUsage {
      username
      duration
      className
      totalDuration
      startDate
      endDate
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on SchoolDoesNotExistProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentUsageReportGQL extends Apollo.Mutation<StudentUsageReportMutation, StudentUsageReportMutationVariables> {
    document = StudentUsageReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FullStudentUsageReportDocument = gql`
    mutation fullStudentUsageReport($input: StudentUsageInput) {
  fullStudentUsageReport(input: $input) {
    studentUsage {
      username
      duration
      collectionName
      startDate
      endDate
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FullStudentUsageReportGQL extends Apollo.Mutation<FullStudentUsageReportMutation, FullStudentUsageReportMutationVariables> {
    document = FullStudentUsageReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentHistoryReportDocument = gql`
    query studentHistoryReport($input: StudentHistoryInput) {
  studentHistoryReport(input: $input) {
    studentUsage {
      username
      className
      duration
      totalDuration
      startDate
      endDate
      schoolName
    }
    nextPage
    problem {
      ... on StudentHistoryReportProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentHistoryReportGQL extends Apollo.Query<StudentHistoryReportQuery, StudentHistoryReportQueryVariables> {
    document = StudentHistoryReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetResourceHubsDocument = gql`
    query getResourceHubs {
  resourceHubs {
    resourceHubs {
      id
      name
      description
      image
      icon
      __typename
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetResourceHubsGQL extends Apollo.Query<GetResourceHubsQuery, GetResourceHubsQueryVariables> {
    document = GetResourceHubsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentClassroomsDocument = gql`
    query studentClassrooms($userName: String!, $districtId: String, $schoolId: String) {
  studentClassrooms(
    userName: $userName
    districtId: $districtId
    schoolId: $schoolId
  ) {
    collections {
      userId
      districtId
      districtName
      schoolId
      schoolName
      classId
      className
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionsDoNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentClassroomsGQL extends Apollo.Query<StudentClassroomsQuery, StudentClassroomsQueryVariables> {
    document = StudentClassroomsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCollectionByResourceHubDocument = gql`
    query getCollectionByResourceHub($resourceHubId: String!, $pageIndex: String, $pageSize: String) {
  collectionsByResourceHub(
    resourceHubId: $resourceHubId
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    nextPage
    classroom {
      id
      name
      imageId
      image {
        latestBuild
        licenses {
          title
        }
      }
      description
      applications {
        displayName
        id
        icon
      }
      favorite
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionSchoolDoesNotExistProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassroomsDoNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCollectionByResourceHubGQL extends Apollo.Query<GetCollectionByResourceHubQuery, GetCollectionByResourceHubQueryVariables> {
    document = GetCollectionByResourceHubDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateResourceSessionDocument = gql`
    mutation createResourceSession($input: ResourceSessionInput!) {
  createResourceSession(input: $input) {
    start
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on UserAlreadyHasASessionProblem {
        message
      }
      ... on CouldNotCreateSessionProblem {
        message
      }
      ... on SchoolDoesNotHaveEnoughCreditsProblem {
        message
      }
      ... on UserExceededQuotaPerWeekProblem {
        message
      }
      ... on UserInCollectionDoesNotExistProblem {
        message
      }
      ... on PermissionDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateResourceSessionGQL extends Apollo.Mutation<CreateResourceSessionMutation, CreateResourceSessionMutationVariables> {
    document = CreateResourceSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRecentResourceHubsDocument = gql`
    query getRecentResourceHubs($userEmail: String!, $pageIndex: String, $pageSize: String) {
  recentResourceHubs(
    userEmail: $userEmail
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    nextPage
    classroom {
      id
      name
      imageId
      description
      image {
        latestBuild
        licenses {
          title
        }
      }
      favorite
      resourceHubIds
      applications {
        displayName
        id
        icon
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionSchoolDoesNotExistProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on ClassroomsDoNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRecentResourceHubsGQL extends Apollo.Query<GetRecentResourceHubsQuery, GetRecentResourceHubsQueryVariables> {
    document = GetRecentResourceHubsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetBookMarkDocument = gql`
    mutation setBookMark($input: SetBookmarkInput!) {
  setBookmark(input: $input) {
    setBookmark
    problem {
      ... on SetBookmarkProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetBookMarkGQL extends Apollo.Mutation<SetBookMarkMutation, SetBookMarkMutationVariables> {
    document = SetBookMarkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestartIStreamerDocument = gql`
    mutation restartIStreamer($input: RestartIStreamerInput!) {
  restartIStreamer(input: $input) {
    restart
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestartIStreamerGQL extends Apollo.Mutation<RestartIStreamerMutation, RestartIStreamerMutationVariables> {
    document = RestartIStreamerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartSessionsScheduleDocument = gql`
    query startSessionsSchedule($districtId: String!, $schoolId: String!, $classId: String!) {
  startSessionsSchedule(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
  ) {
    schedules {
      users {
        name
        email
        haveSessionDisabled
      }
      allStudents
      timeZone
      name
      id
      frequencies {
        startDates
        frequency
        description
        endDates
      }
      haveSessionDisabled
    }
    problem {
      ... on StartSessionsScheduleProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
        ... on SchoolDoesNotExistProblem {
          message
        }
        ... on UserDoesNotExitsProblem {
          message
        }
        ... on ClassDoesNotExistProblem {
          message
        }
        ... on CannotGetStartSessionScheduleProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartSessionsScheduleGQL extends Apollo.Query<StartSessionsScheduleQuery, StartSessionsScheduleQueryVariables> {
    document = StartSessionsScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddScheduleDocument = gql`
    mutation addSchedule($input: ScheduleStartSessionsInput) {
  scheduleStartSessions(input: $input) {
    schedulerId
    problem {
      ... on StartSessionsProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
        ... on SchoolDoesNotExistProblem {
          message
        }
        ... on UserDoesNotExitsProblem {
          message
        }
        ... on ClassDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddScheduleGQL extends Apollo.Mutation<AddScheduleMutation, AddScheduleMutationVariables> {
    document = AddScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteScheduleDocument = gql`
    mutation deleteSchedule($scheduleId: String!, $districtId: String, $schoolId: String, $classId: String) {
  deleteScheduleStartSession(
    scheduleId: $scheduleId
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
  ) {
    scheduleBool {
      scheduleBool
    }
    problem {
      ... on StartSessionSchedulesDeleteProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on StartSessionScheduleDeleteProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteScheduleGQL extends Apollo.Mutation<DeleteScheduleMutation, DeleteScheduleMutationVariables> {
    document = DeleteScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnableSchedulerSessionByClassDocument = gql`
    mutation enableSchedulerSessionByClass($classId: String, $users: [String]) {
  enableSchedulerSessionByClass(classId: $classId, users: $users) {
    studentsWithProblem
    problem {
      ... on GenericProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnableSchedulerSessionByClassGQL extends Apollo.Mutation<EnableSchedulerSessionByClassMutation, EnableSchedulerSessionByClassMutationVariables> {
    document = EnableSchedulerSessionByClassDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HaveSchedulerSessionDisabledDocument = gql`
    query haveSchedulerSessionDisabled($classId: String) {
  haveSchedulerSessionDisabled(classId: $classId) {
    have
    problem {
      ... on GenericProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HaveSchedulerSessionDisabledGQL extends Apollo.Query<HaveSchedulerSessionDisabledQuery, HaveSchedulerSessionDisabledQueryVariables> {
    document = HaveSchedulerSessionDisabledDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolsByDistrictDocument = gql`
    query getSchoolsByDistrict($input: SchoolsInput!) {
  schools(input: $input) {
    school {
      id
      name
      districtId
      blockTeacherAccess
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on DeploymentDoesNotExistProblem {
        message
      }
      ... on SchoolsDoNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolsByDistrictGQL extends Apollo.Query<GetSchoolsByDistrictQuery, GetSchoolsByDistrictQueryVariables> {
    document = GetSchoolsByDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolLicencesByDistrictDocument = gql`
    query getSchoolLicencesByDistrict($districtId: String!) {
  schoolsLicenses(districtId: $districtId) {
    licenses {
      totalPurchased
      totalActivated
      totalAvailable
      startCurrentTerm
      endCurrentTerm
      schools {
        schoolId
        schoolName
        activatedLicenses
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on LicensesDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolLicencesByDistrictGQL extends Apollo.Query<GetSchoolLicencesByDistrictQuery, GetSchoolLicencesByDistrictQueryVariables> {
    document = GetSchoolLicencesByDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolsSelectDocument = gql`
    query getSchoolsSelect($input: SchoolsInput!) {
  schools(input: $input) {
    school {
      id
      name
      districtId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on DeploymentDoesNotExistProblem {
        message
      }
      ... on SchoolsDoNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolsSelectGQL extends Apollo.Query<GetSchoolsSelectQuery, GetSchoolsSelectQueryVariables> {
    document = GetSchoolsSelectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllowedDomainsBySchoolDocument = gql`
    query allowedDomainsBySchool($schoolId: String!, $districtId: String) {
  allowedDomainsBySchool(schoolId: $schoolId, districtId: $districtId) {
    domains {
      count
      list
    }
    problem {
      ... on AllowedDomainsProblem {
        ... on Problem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllowedDomainsBySchoolGQL extends Apollo.Query<AllowedDomainsBySchoolQuery, AllowedDomainsBySchoolQueryVariables> {
    document = AllowedDomainsBySchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSchoolDocument = gql`
    mutation createSchool($input: SchoolInput) {
  createSchool(input: $input) {
    school {
      id
      name
    }
    problem {
      ... on SchoolCreateProblem {
        ... on Problem {
          message
        }
        ... on RegionDoesNotExistProblem {
          message
        }
        ... on CreateSchoolProblem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
        ... on SchoolAlreadyExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSchoolGQL extends Apollo.Mutation<CreateSchoolMutation, CreateSchoolMutationVariables> {
    document = CreateSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAllocationForSchoolDocument = gql`
    mutation createAllocationForSchool($schoolAllocation: SchoolAllocationInput) {
  createAllocationForSchool(schoolAllocation: $schoolAllocation) {
    school {
      id
      name
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAllocationForSchoolGQL extends Apollo.Mutation<CreateAllocationForSchoolMutation, CreateAllocationForSchoolMutationVariables> {
    document = CreateAllocationForSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolDataDocument = gql`
    query getSchoolData($districtId: String!, $id: String!) {
  school(districtId: $districtId, id: $id) {
    school {
      id
      district {
        id
        totalCredits
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on DeploymentDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolDataGQL extends Apollo.Query<GetSchoolDataQuery, GetSchoolDataQueryVariables> {
    document = GetSchoolDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSchoolNameDocument = gql`
    query getSchoolName($districtId: String!, $id: String!) {
  school(districtId: $districtId, id: $id) {
    school {
      id
      name
      createAt
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
      ... on DeploymentDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSchoolNameGQL extends Apollo.Query<GetSchoolNameQuery, GetSchoolNameQueryVariables> {
    document = GetSchoolNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSchoolDocument = gql`
    mutation deleteSchool($districtId: String, $schoolId: String) {
  deleteSchool(districtId: $districtId, schoolId: $schoolId) {
    schoolBool {
      schoolBool
    }
    problem {
      ... on SchoolDeleteProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on PermissionDoesNotExistProblem {
          message
        }
        ... on DeleteSchoolProblem {
          message
        }
        ... on CollectionByDeploymentFailedProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSchoolGQL extends Apollo.Mutation<DeleteSchoolMutation, DeleteSchoolMutationVariables> {
    document = DeleteSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAllowedDomainsForSchoolDocument = gql`
    mutation updateAllowedDomainsForSchool($districtId: String!, $schoolId: String!, $domains: AllowedDomainInput) {
  updateAllowedDomainsForSchool(
    districtId: $districtId
    schoolId: $schoolId
    domains: $domains
  ) {
    updated
    problem {
      ... on UpdateAllowedDomainsForSchoolProblem {
        ... on Problem {
          message
        }
        ... on AllowedDomainFormatErrorProblem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on SchoolDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAllowedDomainsForSchoolGQL extends Apollo.Mutation<UpdateAllowedDomainsForSchoolMutation, UpdateAllowedDomainsForSchoolMutationVariables> {
    document = UpdateAllowedDomainsForSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PopularClassesDocument = gql`
    query popularClasses($input: PopularClassesInput!) {
  popularClasses(input: $input) {
    popularClasses {
      time
      name
      id
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PopularClassesGQL extends Apollo.Query<PopularClassesQuery, PopularClassesQueryVariables> {
    document = PopularClassesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TopCollectionsDocument = gql`
    query topCollections($districtId: String, $schoolId: String, $classIds: [String]) {
  topCollections(
    districtId: $districtId
    schoolId: $schoolId
    classIds: $classIds
  ) {
    usage {
      name
      creditUsage
      timeUsage
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TopCollectionsGQL extends Apollo.Query<TopCollectionsQuery, TopCollectionsQueryVariables> {
    document = TopCollectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TopImagesDocument = gql`
    query topImages($input: TopImagesInput) {
  topImages(input: $input) {
    usage {
      name
      creditUsage
      timeUsage
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TopImagesGQL extends Apollo.Query<TopImagesQuery, TopImagesQueryVariables> {
    document = TopImagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MostActiveStudentsDocument = gql`
    query mostActiveStudents($districtId: String, $schoolId: String) {
  mostActiveStudents(districtId: $districtId, schoolId: $schoolId) {
    studentUsage {
      name
      email
      timeUsage
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MostActiveStudentsGQL extends Apollo.Query<MostActiveStudentsQuery, MostActiveStudentsQueryVariables> {
    document = MostActiveStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBillingHistoryDocument = gql`
    query getBillingHistory($districtId: String!, $month: String!, $requestEmails: [String], $pageIndex: String, $pageSize: String, $filter: String, $order: String) {
  billingHistory(
    districtId: $districtId
    month: $month
    requestEmails: $requestEmails
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
    order: $order
  ) {
    nextPage
    billingHistory {
      totalCreditUsage
      totalTimeUsage
      billingHistory {
        organizationName
        deploymentName
        collectionName
        userEmail
        studentName
        instanceName
        imageName
        machineSizeName
        timeUsage
        creditUsage
        sessionStartAt
        sessionEndAt
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBillingHistoryGQL extends Apollo.Query<GetBillingHistoryQuery, GetBillingHistoryQueryVariables> {
    document = GetBillingHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActivatedLicensesDocument = gql`
    query getActivatedLicenses($input: ActivatedLicensesInput!) {
  activatedLicenses(input: $input) {
    activatedLicenses {
      activatedLicenses
      inactiveUsers
      neverLoggedIn
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActivatedLicensesGQL extends Apollo.Query<GetActivatedLicensesQuery, GetActivatedLicensesQueryVariables> {
    document = GetActivatedLicensesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActiveStudentsHistoryDocument = gql`
    query activeStudentsHistory($input: StudentsCountInput) {
  activeStudentsHistory(input: $input) {
    studentsHistory {
      studentCount
      date
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActiveStudentsHistoryGQL extends Apollo.Query<ActiveStudentsHistoryQuery, ActiveStudentsHistoryQueryVariables> {
    document = ActiveStudentsHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecentUserActivityDocument = gql`
    query RecentUserActivity($input: RecentUserActivityInput!) {
  recentUserActivity(input: $input) {
    recentUsers {
      name
      value
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecentUserActivityGQL extends Apollo.Query<RecentUserActivityQuery, RecentUserActivityQueryVariables> {
    document = RecentUserActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SessionOriginDocument = gql`
    query sessionOrigin($input: SessionOriginInput) {
  sessionOrigin(input: $input) {
    sessionOrigin {
      onCampus
      offCampus
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SessionOriginGQL extends Apollo.Query<SessionOriginQuery, SessionOriginQueryVariables> {
    document = SessionOriginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentSchoolSessionsDocument = gql`
    query currentSchoolSessions($districtId: String, $schoolId: String, $pageIndex: String, $pageSize: String, $filter: String, $order: String) {
  currentSchoolSessions(
    districtId: $districtId
    schoolId: $schoolId
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
    order: $order
  ) {
    sessions {
      userId
      email
      name
      collectionId
      collectionName
      status
      instanceName
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentSchoolSessionsGQL extends Apollo.Query<CurrentSchoolSessionsQuery, CurrentSchoolSessionsQueryVariables> {
    document = CurrentSchoolSessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentClassroomSessionsDocument = gql`
    query currentClassroomSessions($districtId: String, $schoolId: String, $classId: String, $pageIndex: String, $pageSize: String, $filter: String, $order: String) {
  currentClassroomSessions(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
    order: $order
  ) {
    sessions {
      userId
      email
      name
      collectionId
      collectionName
      status
      instanceName
    }
    nextPage
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentClassroomSessionsGQL extends Apollo.Query<CurrentClassroomSessionsQuery, CurrentClassroomSessionsQueryVariables> {
    document = CurrentClassroomSessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassStudentsDocument = gql`
    query classStudents($districtId: String, $schoolId: String, $classId: String, $pageSize: String, $pageIndex: String, $filter: String, $order: String) {
  classStudents(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    pageSize: $pageSize
    pageIndex: $pageIndex
    filter: $filter
    order: $order
  ) {
    students {
      id
      name
      email
      lastLogon
    }
    pageIndex
    nextPage
    isRosterSync
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on StudentCollectionProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClassStudentsGQL extends Apollo.Query<ClassStudentsQuery, ClassStudentsQueryVariables> {
    document = ClassStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentsNeverLoginDocument = gql`
    query studentsNeverLogin($districtId: String, $schoolId: String) {
  studentsNeverLogin(districtId: $districtId, schoolId: $schoolId) {
    students {
      id
      name
      email
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on StudentCollectionProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on UserDoesNotExitsProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentsNeverLoginGQL extends Apollo.Query<StudentsNeverLoginQuery, StudentsNeverLoginQueryVariables> {
    document = StudentsNeverLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentsNeverLoginByCollectionsDocument = gql`
    query studentsNeverLoginByCollections($input: studentsNeverLoginByCollectionsInput!) {
  studentsNeverLoginByCollections(input: $input) {
    nextPage
    list {
      id
      name
      email
      className
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on UsersNotFoundProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentsNeverLoginByCollectionsGQL extends Apollo.Query<StudentsNeverLoginByCollectionsQuery, StudentsNeverLoginByCollectionsQueryVariables> {
    document = StudentsNeverLoginByCollectionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShutdownSessionDocument = gql`
    mutation shutdownSession($input: ShutdownSessionInput) {
  shutdownSession(input: $input) {
    shutDown {
      shutDown
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SessionDoesNotExistProblem {
        message
      }
      ... on SessionShutdownInstanceProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShutdownSessionGQL extends Apollo.Mutation<ShutdownSessionMutation, ShutdownSessionMutationVariables> {
    document = ShutdownSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShutdownMultipleSessionsDocument = gql`
    mutation shutdownMultipleSessions($input: ShutdownMultipleSessionsInput) {
  shutdownMultipleSessions(input: $input) {
    shutDown {
      shutDown
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SessionDoesNotExistProblem {
        message
      }
      ... on SessionShutdownInstanceProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShutdownMultipleSessionsGQL extends Apollo.Mutation<ShutdownMultipleSessionsMutation, ShutdownMultipleSessionsMutationVariables> {
    document = ShutdownMultipleSessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddClassStudentsDocument = gql`
    mutation addClassStudents($students: [String], $districtId: String, $schoolId: String, $classId: String, $districtName: String, $schoolName: String, $studentsNotification: Boolean) {
  addClassStudents(
    students: $students
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    districtName: $districtName
    schoolName: $schoolName
    studentsNotification: $studentsNotification
  ) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SyncStudentsProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddClassStudentsGQL extends Apollo.Mutation<AddClassStudentsMutation, AddClassStudentsMutationVariables> {
    document = AddClassStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteStudentDocument = gql`
    mutation deleteStudent($input: DeleteUserInput) {
  deleteStudent(input: $input) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on StudentDeleteProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStudentGQL extends Apollo.Mutation<DeleteStudentMutation, DeleteStudentMutationVariables> {
    document = DeleteStudentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMultipleUsersDocument = gql`
    mutation deleteMultipleUsers($input: DeleteMultipleUsersInput) {
  deleteMultipleUsers(input: $input) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on UserDeleteProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMultipleUsersGQL extends Apollo.Mutation<DeleteMultipleUsersMutation, DeleteMultipleUsersMutationVariables> {
    document = DeleteMultipleUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartStudentSessionsDocument = gql`
    mutation startStudentSessions($input: StartSessionsInput) {
  startStudentSessions(input: $input) {
    start
    problem {
      ... on StudentCreateProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on UserAlreadyHasASessionProblem {
          message
          __typename
        }
        ... on CouldNotCreateSessionProblem {
          message
          __typename
        }
        ... on UserExceededQuotaPerWeekProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartStudentSessionsGQL extends Apollo.Mutation<StartStudentSessionsMutation, StartStudentSessionsMutationVariables> {
    document = StartStudentSessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStudentSessionDocument = gql`
    mutation createStudentSession($input: StudentSessionInput!) {
  createStudentSession(input: $input) {
    start
    problem {
      ... on StudentCreateProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
          __typename
        }
        ... on UserAlreadyHasASessionProblem {
          message
          __typename
        }
        ... on CouldNotCreateSessionProblem {
          message
          __typename
        }
        ... on SchoolDoesNotHaveEnoughCreditsProblem {
          message
          __typename
        }
        ... on SchoolDoesNotHaveEnoughCreditsProblem {
          message
          __typename
        }
        ... on UserExceededQuotaPerWeekProblem {
          message
          __typename
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStudentSessionGQL extends Apollo.Mutation<CreateStudentSessionMutation, CreateStudentSessionMutationVariables> {
    document = CreateStudentSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentClassroomStatusDocument = gql`
    query studentClassroomStatus($userId: String!, $districtId: String!, $schoolId: String) {
  studentClassroomStatus(
    userId: $userId
    districtId: $districtId
    schoolId: $schoolId
  ) {
    collectionStatus {
      classId
      status
      token
      userIp
      sessionId
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentClassroomStatusGQL extends Apollo.Query<StudentClassroomStatusQuery, StudentClassroomStatusQueryVariables> {
    document = StudentClassroomStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChargifyRedirectDocument = gql`
    subscription chargifyRedirect {
  chargifyRedirect {
    externalId
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChargifyRedirectGQL extends Apollo.Subscription<ChargifyRedirectSubscription, ChargifyRedirectSubscriptionVariables> {
    document = ChargifyRedirectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionStateDocument = gql`
    query subscriptionState($externalId: String!) {
  subscriptionState(externalId: $externalId) {
    subscription {
      url
      ready
    }
    problem {
      ... on Problem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionStateGQL extends Apollo.Query<SubscriptionStateQuery, SubscriptionStateQueryVariables> {
    document = SubscriptionStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SessionCreatedDocument = gql`
    subscription sessionCreated($email: String, $token: String) {
  sessionCreated(email: $email, token: $token) {
    id
    gateway
    email
    status
    collectionId
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SessionCreatedGQL extends Apollo.Subscription<SessionCreatedSubscription, SessionCreatedSubscriptionVariables> {
    document = SessionCreatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SessionFinishedDocument = gql`
    subscription sessionFinished($email: String) {
  sessionFinished(email: $email) {
    email
    collectionId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SessionFinishedGQL extends Apollo.Subscription<SessionFinishedSubscription, SessionFinishedSubscriptionVariables> {
    document = SessionFinishedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SessionNotStartDocument = gql`
    subscription sessionNotStart($email: String) {
  sessionNotStart(email: $email) {
    collectionId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SessionNotStartGQL extends Apollo.Subscription<SessionNotStartSubscription, SessionNotStartSubscriptionVariables> {
    document = SessionNotStartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllStudentsDocument = gql`
    query allStudents($input: AllStudentsInput!) {
  allStudents(input: $input) {
    nextPage
    list {
      id
      name
      email
      className
    }
    problem {
      __typename
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllStudentsGQL extends Apollo.Query<AllStudentsQuery, AllStudentsQueryVariables> {
    document = AllStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NorecentActivityByDaysDocument = gql`
    query norecentActivityByDays($input: noRecentActivityInput!) {
  noRecentActivityByDays(input: $input) {
    nextPage
    list {
      id
      name
      email
      className
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on UsersNotFoundProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NorecentActivityByDaysGQL extends Apollo.Query<NorecentActivityByDaysQuery, NorecentActivityByDaysQueryVariables> {
    document = NorecentActivityByDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetClassInstructorsDocument = gql`
    query getClassInstructors($districtId: String, $schoolId: String, $classId: String) {
  classInstructors(
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
  ) {
    students {
      id
      name
      email
      lastLogon
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on StudentCollectionProblem {
        message
      }
      ... on SchoolDoesNotExistProblem {
        message
      }
      ... on UserDoesNotExitsProblem {
        message
      }
      ... on ClassDoesNotExistProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClassInstructorsGQL extends Apollo.Query<GetClassInstructorsQuery, GetClassInstructorsQueryVariables> {
    document = GetClassInstructorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddClassTeachersDocument = gql`
    mutation addClassTeachers($instructors: [String], $districtId: String, $schoolId: String, $classId: String, $districtName: String, $schoolName: String) {
  addClassTeachers(
    instructors: $instructors
    districtId: $districtId
    schoolId: $schoolId
    classId: $classId
    districtName: $districtName
    schoolName: $schoolName
  ) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on SyncInstructorsProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddClassTeachersGQL extends Apollo.Mutation<AddClassTeachersMutation, AddClassTeachersMutationVariables> {
    document = AddClassTeachersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTeacherDocument = gql`
    mutation deleteTeacher($input: DeleteUserInput) {
  deleteTeacher(input: $input) {
    classbool {
      classbool
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on CollectionDoesNotExistProblem {
        message
      }
      ... on UserNotFoundProblem {
        message
      }
      ... on InstructorDeleteProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTeacherGQL extends Apollo.Mutation<DeleteTeacherMutation, DeleteTeacherMutationVariables> {
    document = DeleteTeacherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClassDailyUsageDocument = gql`
    query classDailyUsage($input: StudentsCountInput!) {
  classDailyUsage(input: $input) {
    dailyUsage {
      date
      values {
        collectionId
        collectionName
        studentCount
      }
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClassDailyUsageGQL extends Apollo.Query<ClassDailyUsageQuery, ClassDailyUsageQueryVariables> {
    document = ClassDailyUsageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTiersbyImageDocument = gql`
    query getTiersbyImage($imageId: String!) {
  tiers(imageId: $imageId) {
    tiers {
      id
      name
      ram
      cpu
      machineType
      rate
      active
      required
      gpu
      allowed
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on ImageDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTiersbyImageGQL extends Apollo.Query<GetTiersbyImageQuery, GetTiersbyImageQueryVariables> {
    document = GetTiersbyImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTierByIdDocument = gql`
    query getTierById($tierId: String!) {
  tier(tierId: $tierId) {
    tier {
      id
      name
      rate
    }
    problem {
      ... on Problem {
        message
        __typename
      }
      ... on AuthenticationRequiredProblem {
        message
        __typename
      }
      ... on TierDoesNotExistProblem {
        message
        __typename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTierByIdGQL extends Apollo.Query<GetTierByIdQuery, GetTierByIdQueryVariables> {
    document = GetTierByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockTeacherAccessDistrictDocument = gql`
    query blockTeacherAccessDistrict($input: DistrictGetInput!) {
  district(input: $input) {
    district {
      id
      name
      blockTeacherAccess
    }
    problem {
      ... on Problem {
        message
      }
      ... on AuthenticationRequiredProblem {
        message
      }
      ... on DistrictDoesNotExistProblem {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockTeacherAccessDistrictGQL extends Apollo.Query<BlockTeacherAccessDistrictQuery, BlockTeacherAccessDistrictQueryVariables> {
    document = BlockTeacherAccessDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockTeacherSettingDistrictDocument = gql`
    mutation blockTeacherSettingDistrict($districtId: String, $block: Boolean!) {
  blockTeacherSettingDistrict(districtId: $districtId, block: $block) {
    updated
    problem {
      ... on DistrictUpdateProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on DistrictDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockTeacherSettingDistrictGQL extends Apollo.Mutation<BlockTeacherSettingDistrictMutation, BlockTeacherSettingDistrictMutationVariables> {
    document = BlockTeacherSettingDistrictDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BlockTeacherSettingSchoolDocument = gql`
    mutation blockTeacherSettingSchool($districtId: String!, $schoolId: String, $block: Boolean!) {
  blockTeacherSettingSchool(
    districtId: $districtId
    schoolId: $schoolId
    block: $block
  ) {
    updated
    problem {
      ... on SchoolUpdateProblem {
        ... on Problem {
          message
        }
        ... on AuthenticationRequiredProblem {
          message
        }
        ... on SchoolDoesNotExistProblem {
          message
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockTeacherSettingSchoolGQL extends Apollo.Mutation<BlockTeacherSettingSchoolMutation, BlockTeacherSettingSchoolMutationVariables> {
    document = BlockTeacherSettingSchoolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }