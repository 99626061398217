import { Injectable, Injector } from '@angular/core';
import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetGroupsByDistrictGQL } from '../../graphql';
import { AbstractServerSideService } from './abstract/abstract-server-side.service';

const queryPropFilter = 'name';

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends AbstractServerSideService {
  // override
  elements!: Array<any>;
  pageTokenAll!: string;
  pageTokenSearch!: string;
  isLoadAllData!: boolean;
  identity = 'name';
  noDataText = 'No Workspace Group';

  // query default variables
  pageToken = '';
  pageSize = '20';
  query!: string;

  // injection
  groupByDistrictGQL!: GetGroupsByDistrictGQL;
  apollo!: Apollo;

  // apollo object query
  groupsQuery!: QueryRef<any>;

  // observable
  private querySubscription!: Subscription;

  constructor(private injector: Injector) {
    super();
    this.groupByDistrictGQL = this.injector.get(GetGroupsByDistrictGQL);
    this.apollo = this.injector.get(Apollo);
    this.elements = [];
  }

  getQuery(variables: {
    districtId: string;
    isSearching: boolean;
    query: string;
  }): QueryRef<any> {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    this.query = variables.query;
    const isSearching = variables.isSearching;
    this.isLoadAllData = false;

    // active searching
    if (isSearching) {
      this.pageTokenSearch = '';
      this.pageToken = '';
    } else {
      this.pageToken = this.query ? this.pageTokenSearch : this.pageTokenAll;
    }

    this.groupsQuery = this.apollo.watchQuery<any>({
      query: this.groupByDistrictGQL.document,
      variables: {
        districtId: variables.districtId,
        pageSize: this.pageSize,
        pageToken: this.pageToken,
        query: this.query ? queryPropFilter + ':' + this.query + '*' : ''
      },
      fetchPolicy: 'network-only'
    });

    this.querySubscription = this.groupsQuery.valueChanges
      .pipe(
        map(({ data, loading }) => {
          this.pageToken = data.groups.pageToken;
          this.elements = data.groups.gGroup;
          this.updateDataToken(this.pageToken);
        })
      )
      .subscribe();
    return this.groupsQuery;
  }

  unsuscribeQuery(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  private updateDataToken(pageTk: string): void {
    if (!this.query) {
      this.pageTokenAll = pageTk;
    } else {
      this.pageTokenSearch = pageTk;
    }
    this.isLoadAllData = pageTk ? false : true;
  }
}
