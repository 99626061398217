import { Component, OnInit, Input } from '@angular/core';
import {Location} from '@angular/common';
import { Resources } from '../../enums';
import { Router } from '@angular/router';
import { AuthService, ThememodeService } from '../../services';
import { DistrictShort } from '../../graphql';
import { DashboardActionService } from 'src/app/dashboard/dashboard-action.service';
import { URL_ASSETS } from '../../const';
import { resourcesImages } from '../../const/images-resources';
import { OopsService } from './oops.service';

@Component({
  selector: 'app-oops',
  templateUrl: './oops.component.html',
  styleUrls: ['./oops.component.scss']
})
export class OopsComponent implements OnInit {
  @Input()
  textDescription!: string;

  district!:DistrictShort;
  schoolId!: string;
  resources!: Array<string>;
  imageClass!: boolean;
  resourceNoExist!: Resources;

  schoolResource = Resources.School;
  districtResource = Resources.District;
  classResource = Resources.Classroom;
  url: any;
  isLegacyVersion!: boolean;
  isLight!: boolean;

  constructor(private location: Location, private router: Router,
              private authService: AuthService, private oopsService: OopsService,
               private dashboardActionService: DashboardActionService, private thememodeService: ThememodeService ) 
    {
        this.resources = [
          URL_ASSETS + resourcesImages['404-ligth'],
          URL_ASSETS + resourcesImages['404-dark']                 
        ];
        this.isLight = this.thememodeService.getColorScheme() !== 'dark';
   }

  ngOnInit(): void {    
    const resourceSt =  sessionStorage.getItem('resourceNotExist');
    if(resourceSt){
      this.resourceNoExist = resourceSt as Resources;
    } else {
       this.authService.signOut();
    }
    if(this.resourceNoExist === Resources.District) {
      this.authService.setLastDistrict('');
      this.oopsService.setDisableSchoolNav(true);
    } else {
      this.oopsService.setDisableSchoolNav(false);
    }
    this.district = this.authService.getLastDistrict() as DistrictShort;
    this.schoolId = this.authService.getLastSchool() as string;
    this.imageClass = true;

    if(!this.textDescription) {
      this.textDescription = 'The resource you requested could not be found.';
    }
    this.dashboardActionService.setSchoolName('');
  }

  reloadDashboard(): Promise<boolean> | void {
    this.isLegacyVersion = sessionStorage.getItem('isLegacyVersion')=== 'true';
    sessionStorage.removeItem('resourceNotExist');
    if(this.isLegacyVersion){
      window.location.href=`${window.location.origin}/legacy`;
    } else {
      window.location.href=`${window.location.origin}`;
    }
  }

}
